import React, { useEffect, useState } from 'react';
import {
  BarChart,
  BookmarkBorder,
  CancelPresentation,
  CheckCircle,
  LibraryBooks,
  ModeEdit,
  Star
} from '@mui/icons-material';
import {
  AppAlert,
  AppButton,
  AppText,
  AppTitle,
  MySurveysModal,
  SurveyTemplatesModal,
  UpdateSurveyModal
} from 'components';
import { questionTypes } from 'constants/survey';
import { arrangeStoreDetails } from 'utils/store';
import { calculateQuestionRatePoint } from 'utils/survey';

const SurveyContentTab = props => {
  const [store, setStore] = useState(props.store);
  const [selectedQuestion, setSelectedQuestion] = useState(
    store?.visibleSurvey?.questions[0]
  );
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';

  //modals
  const [isMySurveysModalOpen, setIsMySurveysModalOpen] = useState(false);
  const [isUpdateSurveyModalOpen, setIsUpdateSurveyModalOpen] = useState(false);
  const [isSurveyTemplatesModalOpen, setIsSurveyTemplatesModalOpen] =
    useState(false);
  const [alertInfo, setAlertInfo] = useState({ isActive: false });

  useEffect(() => {
    setSelectedQuestion(store?.visibleSurvey?.questions[0]);
  }, [store.visibleSurvey]);

  const isMostChoose = () => {
    const chooseCounts = [];

    for (let i = 0; i < selectedQuestion.multipleChoices.length; i++) {
      const choose = selectedQuestion.multipleChoices[i];
      if (choose.chooseCount > 0) {
        chooseCounts.push(choose.chooseCount);
      }
    }

    return Math.max(...chooseCounts);
  };

  const onCloseMySurveysModal = (
    data,
    isDeleted = false,
    isAlertActive = true
  ) => {
    if (data?._id) {
      const arrangedStore = arrangeStoreDetails(data);
      setStore(arrangedStore);
      props.setStore(arrangedStore);
      setSelectedQuestion(data.visibleSurvey.questions[0]);
      if (isAlertActive) {
        setAlertInfo({
          isActive: true,
          type: 'success',
          title: isDeleted ? 'Silindi' : 'Değiştirildi',
          subtitle: isDeleted
            ? 'Anket başarıyla silindi'
            : 'Yayındaki anketi başarıyla değiştirildi'
        });
        setTimeout(() => {
          setAlertInfo({ isActive: false });
        }, 1500);
      }
    }
    setIsMySurveysModalOpen(false);
  };

  const onCloseUpdateSurveyModal = data => {
    if (data?._id) {
      const arrangedStore = arrangeStoreDetails(data);
      setStore(arrangedStore);
      props.setStore(arrangedStore);
      setSelectedQuestion(data.visibleSurvey.questions[0]);
      setAlertInfo({
        isActive: true,
        type: 'success',
        title: 'Güncellendi',
        subtitle: 'Anket başarıyla güncellendi'
      });
      setTimeout(() => {
        setAlertInfo({ isActive: false });
      }, 1500);
    }
    setIsUpdateSurveyModalOpen(false);
  };

  return (
    <div className="bg-white pb-6 rounded-md shadow-md w-full flex flex-col ">
      <div className="flex flex-row w-full xl:px-6 p-3 justify-between border-b">
        <AppButton
          className="!bg-amber-100 !text-amber-500 focus:!ring-amber-500 hover:!bg-amber-200"
          isFullWidth={false}
          onClick={() => setIsMySurveysModalOpen(true)}
        >
          Anketlerim
          <BookmarkBorder className="ml-1" fontSize="small" />
        </AppButton>
        <AppButton
          className="!bg-emerald-100 !text-emerald-500 focus:!ring-emerald-500 hover:!bg-emerald-200"
          isFullWidth={false}
          onClick={() => setIsSurveyTemplatesModalOpen(true)}
        >
          Örnek Anketler
          <LibraryBooks className="ml-1" fontSize="small" />
        </AppButton>
      </div>
      <div className="flex flex-col relative">
        <AppText
          text="Yayındaki Anket Bilgileri"
          className="mt-3 xl:text-lg text-base text-gray-900 font-medium text-center"
        />
        {store?.visibleSurveyId && (
          <>
            <AppText
              text={`Anket İsmi: ${store?.visibleSurvey?.surveyName}`}
              className="pt-1 text-gray-900 font-medium text-sm xl:text-base ml-3 xl:ml-6"
            />
            <div className="flex flex-row pt-1">
              <div className="flex flex-col w-full xl:w-1/2 xl:px-6 px-3 xl:border-r border-gray-200">
                <AppTitle
                  className="font-medium text-sm xl:text-base text-gray-900 pb-1 border-b border-gray-200"
                  title="Sorular"
                />
                {store?.visibleSurvey?.questions.map((item, index) => (
                  <span
                    onClick={() => setSelectedQuestion(item)}
                    className={`cursor-pointer flex flex-row p-2 mt-4 border border-gray-200 text-gray-500 rounded-md w-auto ${
                      selectedQuestion.question === item.question &&
                      '!border-gray-800 !text-gray-800'
                    }`}
                    key={item._id}
                  >
                    <AppText
                      text={`${index + 1}.`}
                      className="text-sm xl:text-base"
                    />
                    <AppText
                      className="ml-2 text-sm xl:text-base"
                      text={item.question}
                    />
                  </span>
                ))}
                <AppButton
                  onClick={() => setIsUpdateSurveyModalOpen(true)}
                  className="mt-6"
                >
                  Anketi Düzenle
                  <ModeEdit className="ml-2" fontSize="small" />
                </AppButton>
              </div>
              <div className="xl:w-1/2 w-full flex flex-col xl:px-6 px-3">
                <AppTitle
                  className="font-medium text-sm xl:text-base text-gray-900 pb-1 border-b border-gray-200"
                  title="Soru Detayı"
                />
                <ul className="w-full mt-4">
                  <li className="w-full flex flex-col xl:flex-row xl:justify-between border-b py-2 xl:items-center">
                    <AppText
                      className="text-gray-800 text-sm font-medium"
                      text="Cevap zorunlu mu ?"
                    />
                    {selectedQuestion?.isRequired ? (
                      <CheckCircle
                        fontSize="small"
                        className="text-green-500 mt-1 xl:mt-0"
                      />
                    ) : (
                      <CancelPresentation
                        fontSize="small"
                        className="text-red-500 mt-1 xl:mt-0"
                      />
                    )}
                  </li>
                  <li className="w-full flex flex-col xl:flex-row xl:justify-between border-b py-2 xl:items-center">
                    <AppText
                      className="text-gray-800 text-sm font-medium"
                      text="Cevap modeli ?"
                    />
                    <AppText
                      className="text-sm  text-gray-500 mt-1 xl:mt-0"
                      text={
                        questionTypes.find(
                          type => type.value === selectedQuestion?.questionType
                        )?.label
                      }
                    />
                  </li>
                  {selectedQuestion?.questionType === 'multipleChoices' && (
                    <>
                      {selectedQuestion?.multipleChoices.map(
                        (choice, index) => (
                          <li
                            className="w-full py-2 border-b xl:justify-between flex xl:items-center xl:flex-row flex-col"
                            key={choice._id}
                          >
                            <span>
                              <span
                                className={`font-medium text-sm ${
                                  isMostChoose() === choice.chooseCount
                                    ? 'text-amber-400'
                                    : 'text-gray-900'
                                }`}
                              >
                                {`${alphabet[index]}-)`}
                              </span>
                              <span
                                className={`text-sm ml-[2px] ${
                                  isMostChoose() === choice.chooseCount
                                    ? 'text-amber-400'
                                    : 'text-gray-900'
                                }`}
                              >
                                {choice.choice}
                              </span>
                              {isMostChoose() === choice.chooseCount && (
                                <Star
                                  fontSize="small"
                                  className="ml-2 text-amber-400 "
                                />
                              )}
                            </span>
                            <span className="flex flex-row mt-1 xl:mt-0 xl:justify-start xl:items-start">
                              <BarChart
                                className={`${
                                  isMostChoose() === choice.chooseCount
                                    ? 'text-amber-400'
                                    : 'text-blue-700'
                                }`}
                                fontSize="small"
                              />
                              <AppText
                                className={`ml-1 xl:ml-2 text-sm inline-block font-medium text-center w-5 ${
                                  isMostChoose() === choice.chooseCount
                                    ? 'text-amber-400'
                                    : 'text-gray-900'
                                }`}
                                text={choice.chooseCount}
                              />
                            </span>
                          </li>
                        )
                      )}
                    </>
                  )}
                  {selectedQuestion?.questionType === 'rate' && (
                    <>
                      <li className="w-full flex flex-col xl:flex-row xl:justify-between border-b py-2 xl:items-center">
                        <AppText
                          className="text-gray-800 text-sm font-medium"
                          text="Toplam oylama sayısı"
                        />
                        <AppText
                          className="text-sm  text-gray-500"
                          text={
                            selectedQuestion.rateAnswerStatistics?.rateCount
                          }
                        />
                      </li>
                      <li className="w-full flex flex-col xl:flex-row xl:justify-between border-b py-2 xl:items-center">
                        <AppText
                          className="text-gray-800 text-sm font-medium"
                          text="Oylama yüzdesi"
                        />
                        <AppText
                          className="text-sm  text-gray-500"
                          text={calculateQuestionRatePoint(
                            selectedQuestion.rateAnswerStatistics?.ratePoint,
                            selectedQuestion.rateAnswerStatistics?.rateCount
                          )}
                        />
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </>
        )}
        {alertInfo.isActive && (
          <AppAlert
            type={alertInfo.type}
            title={alertInfo.title}
            subtitle={alertInfo.subtitle}
          />
        )}
      </div>
      {isMySurveysModalOpen && (
        <MySurveysModal
          store={store}
          isOpen={isMySurveysModalOpen}
          onClose={onCloseMySurveysModal}
        />
      )}
      {isUpdateSurveyModalOpen && (
        <UpdateSurveyModal
          store={store}
          survey={store.visibleSurvey}
          onClose={onCloseUpdateSurveyModal}
          isOpen={isUpdateSurveyModalOpen}
        />
      )}
      {isSurveyTemplatesModalOpen && (
        <SurveyTemplatesModal
          store={store}
          onClose={() => setIsSurveyTemplatesModalOpen(false)}
          isOpen={isSurveyTemplatesModalOpen}
        />
      )}
    </div>
  );
};

export default SurveyContentTab;
