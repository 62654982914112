import { AppButton, AppModal } from 'components';
import React, { useEffect, useState } from 'react';
import api from 'services/api';
import { convertMenuTemplateData } from 'utils/menu';

const MenuCategoryTemplatesModal = ({ onClose, isOpen, onSelect }) => {
  const [categoryData, setCategoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      await api.global
        .list('menuCategoryTemplate', 0)
        .then(async res => {
          const convertedCategoryData = convertMenuTemplateData(res.data);
          setCategoryData(convertedCategoryData);
        })
        .catch(error => console.log('error', error));

      setIsLoading(false);
    };
    getData();
  }, []);

  return (
    <AppModal
      open={isOpen}
      title="Kategori Fotoğrafı Seç"
      handleClose={() => onClose()}
    >
      {isLoading ? (
        <></>
      ) : (
        <ul>
          {categoryData.map((category, index) => (
            <li
              key={index}
              className={`${index > 0 && 'pt-2 mt-4 border-t border-gray-300'}`}
            >
              <span className="text-gray-900 font-semibold text-lg block text-center">
                {category.categoryName}
              </span>
              <div className="grid grid-cols-1 mt-4 gap-4">
                {category.photos.map((item, i) => (
                  <div key={i}>
                    <img
                      src={item.photo}
                      className="rounded-md object-contain"
                    />
                    <AppButton
                      onClick={() => onSelect(item.photo)}
                      className="!bg-transparent border border-gray-300  !text-gray-900 focus:!ring-gray-500 hover:!bg-gray-200 mt-2"
                    >
                      Seç
                    </AppButton>
                  </div>
                ))}
              </div>
            </li>
          ))}
        </ul>
      )}
    </AppModal>
  );
};

export default MenuCategoryTemplatesModal;
