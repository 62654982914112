import {
  CheckCircle,
  DoneAll,
  ErrorOutline,
  HighlightOff
} from '@mui/icons-material';
import React from 'react';
import { motion } from 'framer-motion';

const Alert = ({ title, subtitle, type = 'success' }) => {
  const alertTypes = [
    {
      type: 'success',
      backgroundColor: 'bg-emerald-100',
      textColor: 'text-emerald-500',
      Icon: <CheckCircle fontSize="small" className="ml-1" />
    },
    {
      type: 'error',
      backgroundColor: 'bg-red-100',
      textColor: 'text-red-500',
      Icon: <HighlightOff fontSize="small" className="ml-1" />
    },
    {
      type: 'warning',
      backgroundColor: 'bg-amber-100',
      textColor: 'text-amber-500',
      Icon: <ErrorOutline fontSize="small" className="ml-1" />
    }
  ];
  const currentType = alertTypes.find(item => item.type === type);

  return (
    <motion.div
      initial={{ scale: 0.8 }}
      animate={{ scale: 1 }}
      transition={{
        type: 'spring'
      }}
      className={`${currentType.backgroundColor} flex max-w-[550px] flex-col justify-center items-center xl:py-4 xl:px-6  px-4 p-2 rounded-md absolute right-0 top-0 z-50`}
    >
      <span
        className={`${currentType.textColor} text-sm font-medium tracking-tight`}
      >
        {title} {currentType.Icon}
      </span>
      <span className={`${currentType.textColor} text-xs mt-1`}>
        {subtitle}
      </span>
    </motion.div>
  );
};

export default Alert;
