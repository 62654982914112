import { AppButton, CampaignListCard, ScreenLoading } from 'components';
import { useAuth } from 'context/AuthContext';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';

const CampaignList = () => {
  const { user } = useAuth();
  const isCampaignAvailable = user.isCampaignEnable;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [campaignList, setCampaignList] = useState([]);
  const isStoreAdded = user.addedStores.length > 0;

  useEffect(() => {
    const getData = async () => {
      if (isCampaignAvailable) {
        setIsLoading(true);
        try {
          const response = await api.campaign.getAllMyCampaigns();
          setCampaignList(response.data.customerCampaigns);
        } catch (error) {
          console.log('campaign get error', error);
        }
        setIsLoading(false);
      }
    };
    getData();
  }, []);

  if (!isCampaignAvailable) {
    return (
      <div>
        <h2 className="text-gray-500 text-sm">
          Burada işletmeleriniz için kampanyalar oluşturabilirsiniz. Bu
          kampanyalara müşterileriniz, işletmenize ait ankete yeterli sayıda
          katılarak kampanyaya katılabilir ve sizin belirlediğiniz ödülü
          kazanabilir.
        </h2>
        <div className="bg-white p-4 w-full border rounded-md mt-4 justify-center flex">
          <p className="font-medium text-gray-500 text-sm w-2/3 text-center">
            Repidas ile anlaşmanızda kampanya kullanımı dahil değildir.
            Kampanyaları kullanmak istiyorsanız
            <a
              className="text-[#FFC907] underline"
              href="mailto:contact@repidas.com "
            >
              {' '}
              contact@repidas.com{' '}
            </a>
            mail adresinden bizimle iletişime geçebilirsiniz.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      {isLoading ? (
        <ScreenLoading />
      ) : (
        <div>
          <h2 className="text-gray-500 text-sm">
            Burada işletmeleriniz için kampanyalar oluşturabilirsiniz. Bu
            kampanyalara müşterileriniz, işletmenize ait ankete yeterli sayıda
            katılarak kampanyaya katılabilir ve sizin belirlediğiniz ödülü
            kazanabilir.
          </h2>

          {isStoreAdded && (
            <div className="mt-4 flex justify-end">
              <AppButton
                className="shadow-lg !text-white hover:!bg-opacity-90 hover:!bg-[#FFC907]  focus:!ring-[#FFC907]  !bg-[#FFC907]"
                onClick={() => navigate('/campaign/add')}
                isFullWidth={false}
              >
                Kampanya oluştur
              </AppButton>
            </div>
          )}

          {campaignList.length > 0 ? (
            <div className="gap-10 mt-4 grid grid-cols-1">
              {campaignList.map(campaign => (
                <CampaignListCard key={campaign._id} campaign={campaign} />
              ))}
            </div>
          ) : (
            <div className="bg-white py-6 px-8 w-full border rounded-md mt-4 justify-center flex flex-col items-center">
              {isStoreAdded ? (
                <p className="font-medium text-gray-500 text-sm">
                  Henüz bir kampanya oluşturmadınız
                </p>
              ) : (
                <>
                  <p className="font-medium text-gray-500 text-sm">
                    Kampanya oluşturabilmek için hemen bir işletme ekleyin!
                  </p>
                  <AppButton
                    onClick={() =>
                      navigate('/store', { state: { isAddModalOpen: true } })
                    }
                    isFullWidth={false}
                    className="shadow-lg !text-white hover:!bg-opacity-90 hover:!bg-[#FFC907]  focus:!ring-[#FFC907]  !bg-[#FFC907] mt-2"
                  >
                    Hemen bir işletme ekle!
                  </AppButton>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CampaignList;
