import React from 'react';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { AppText } from 'components';

const AppPagination = ({ totalPage, onChange, page }) => {
  totalPage = totalPage === 0 ? 1 : totalPage;
  return (
    <div className="mt-4 p-2 items-center border rounded-md border-gray-200 relative float-right shadow-sm bg-white flex-row flex justify-end min-w-fit w-fit right-0">
      <ChevronLeft
        className={`mr-2 ${
          page > 0 ? 'text-gray-900 cursor-pointer' : 'text-gray-400'
        }`}
        fontSize="small"
        onClick={() => {
          if (page > 0) {
            onChange(page - 1);
          }
        }}
      />
      {[...Array(totalPage)].map((item, index) => (
        <AppText
          className={`text-sm ${
            page === index ? 'bg-blue-500 text-white' : 'text-gray-400'
          } text-blue-500 font-medium px-3 py-2 rounded-md cursor-pointer ${
            index > 0 && 'ml-1'
          }`}
          key={index}
          text={index + 1}
          onClick={() => onChange(index)}
        />
      ))}
      <ChevronRight
        className={`ml-2 ${
          page === totalPage - 1
            ? 'text-gray-400'
            : 'text-gray-900 cursor-pointer'
        }`}
        fontSize="small"
        onClick={() => {
          if (page !== totalPage - 1) {
            onChange(page + 1);
          }
        }}
      />
    </div>
  );
};

export default AppPagination;
