export const citiesTr = [
  {
    label: 'Adana',
    value: 'Adana',
    districts: [
      {
        label: 'Aladağ',
        value: 'Aladağ'
      },
      {
        label: 'Ceyhan',
        value: 'Ceyhan'
      },
      {
        label: 'Çukurova',
        value: 'Çukurova'
      },
      {
        label: 'Feke',
        value: 'Feke'
      },
      {
        label: 'İmamoğlu',
        value: 'İmamoğlu'
      },
      {
        label: 'Karaisalı',
        value: 'Karaisalı'
      },
      {
        label: 'Karataş',
        value: 'Karataş'
      },
      {
        label: 'Kozan',
        value: 'Kozan'
      },
      {
        label: 'Pozantı',
        value: 'Pozantı'
      },
      {
        label: 'Saimbeyli',
        value: 'Saimbeyli'
      },
      {
        label: 'Sarıçam',
        value: 'Sarıçam'
      },
      {
        label: 'Seyhan',
        value: 'Seyhan'
      },
      {
        label: 'Tufanbeyli',
        value: 'Tufanbeyli'
      },
      {
        label: 'Yumurtalık',
        value: 'Yumurtalık'
      },
      {
        label: 'Yüreğir',
        value: 'Yüreğir'
      }
    ]
  },
  {
    label: 'Adıyaman',
    value: 'Adıyaman',
    districts: [
      {
        label: 'Besni',
        value: 'Besni'
      },
      {
        label: 'Çelikhan',
        value: 'Çelikhan'
      },
      {
        label: 'Gerger',
        value: 'Gerger'
      },
      {
        label: 'Gölbaşı',
        value: 'Gölbaşı'
      },
      {
        label: 'Kahta',
        value: 'Kahta'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Samsat',
        value: 'Samsat'
      },
      {
        label: 'Sincik',
        value: 'Sincik'
      },
      {
        label: 'Tut',
        value: 'Tut'
      }
    ]
  },
  {
    label: 'Afyonkarahisar',
    value: 'Afyonkarahisar',
    districts: [
      {
        label: 'Başmakçı',
        value: 'Başmakçı'
      },
      {
        label: 'Bayat',
        value: 'Bayat'
      },
      {
        label: 'Bolvadin',
        value: 'Bolvadin'
      },
      {
        label: 'Çay',
        value: 'Çay'
      },
      {
        label: 'Çobanlar',
        value: 'Çobanlar'
      },
      {
        label: 'Dazkırı',
        value: 'Dazkırı'
      },
      {
        label: 'Dinar',
        value: 'Dinar'
      },
      {
        label: 'Emirdağ',
        value: 'Emirdağ'
      },
      {
        label: 'Evclabeler',
        value: 'Evclabeler'
      },
      {
        label: 'Hocalar',
        value: 'Hocalar'
      },
      {
        label: 'İhsaniye',
        value: 'İhsaniye'
      },
      {
        label: 'İscehisar',
        value: 'İscehisar'
      },
      {
        label: 'Kızılören',
        value: 'Kızılören'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Sandıklı',
        value: 'Sandıklı'
      },
      {
        label: 'Sinanpaşa',
        value: 'Sinanpaşa'
      },
      {
        label: 'Sultandağı',
        value: 'Sultandağı'
      },
      {
        label: 'Şuhut',
        value: 'Şuhut'
      }
    ]
  },
  {
    label: 'Ağrı',
    value: 'Ağrı',
    districts: [
      {
        label: 'Diyadin',
        value: 'Diyadin'
      },
      {
        label: 'Doğubayazıt',
        value: 'Doğubayazıt'
      },
      {
        label: 'Eleşkirt',
        value: 'Eleşkirt'
      },
      {
        label: 'Hamur',
        value: 'Hamur'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Patnos',
        value: 'Patnos'
      },
      {
        label: 'Taşlıçay',
        value: 'Taşlıçay'
      },
      {
        label: 'Tutak',
        value: 'Tutak'
      }
    ]
  },
  {
    label: 'Amasya',
    value: 'Amasya',
    districts: [
      {
        label: 'Göynücek',
        value: 'Göynücek'
      },
      {
        label: 'Gümüşhacıköy',
        value: 'Gümüşhacıköy'
      },
      {
        label: 'Hamamözü',
        value: 'Hamamözü'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Merzifon',
        value: 'Merzifon'
      },
      {
        label: 'Suluova',
        value: 'Suluova'
      },
      {
        label: 'Taşova',
        value: 'Taşova'
      }
    ]
  },
  {
    label: 'Ankara',
    value: 'Ankara',
    districts: [
      {
        label: 'Altındağ',
        value: 'Altındağ'
      },
      {
        label: 'Ayaş',
        value: 'Ayaş'
      },
      {
        label: 'Bala',
        value: 'Bala'
      },
      {
        label: 'Beypazarı',
        value: 'Beypazarı'
      },
      {
        label: 'Çamlıdere',
        value: 'Çamlıdere'
      },
      {
        label: 'Çankaya',
        value: 'Çankaya'
      },
      {
        label: 'Çubuk',
        value: 'Çubuk'
      },
      {
        label: 'Elmadağ',
        value: 'Elmadağ'
      },
      {
        label: 'Güdül',
        value: 'Güdül'
      },
      {
        label: 'Haymana',
        value: 'Haymana'
      },
      {
        label: 'Kalecik',
        value: 'Kalecik'
      },
      {
        label: 'Kızılcahamam',
        value: 'Kızılcahamam'
      },
      {
        label: 'Nallıhan',
        value: 'Nallıhan'
      },
      {
        label: 'Polatlı',
        value: 'Polatlı'
      },
      {
        label: 'Şereflikoçhisar',
        value: 'Şereflikoçhisar'
      },
      {
        label: 'Yenimahalle',
        value: 'Yenimahalle'
      },
      {
        label: 'Gölbaşı',
        value: 'Gölbaşı'
      },
      {
        label: 'Keçiören',
        value: 'Keçiören'
      },
      {
        label: 'Mamak',
        value: 'Mamak'
      },
      {
        label: 'Sincan',
        value: 'Sincan'
      },
      {
        label: 'Kazan',
        value: 'Kazan'
      },
      {
        label: 'Akyurt',
        value: 'Akyurt'
      },
      {
        label: 'Etimesgut',
        value: 'Etimesgut'
      },
      {
        label: 'Evren',
        value: 'Evren'
      },
      {
        label: 'Pursaklar',
        value: 'Pursaklar'
      }
    ]
  },
  {
    label: 'Antalya',
    value: 'Antalya',
    districts: [
      {
        label: 'Akseki',
        value: 'Akseki'
      },
      {
        label: 'Alanya',
        value: 'Alanya'
      },
      {
        label: 'Elmalı',
        value: 'Elmalı'
      },
      {
        label: 'Finike',
        value: 'Finike'
      },
      {
        label: 'Gazipaşa',
        value: 'Gazipaşa'
      },
      {
        label: 'Gündoğmuş',
        value: 'Gündoğmuş'
      },
      {
        label: 'Kaş',
        value: 'Kaş'
      },
      {
        label: 'Korkuteli',
        value: 'Korkuteli'
      },
      {
        label: 'Kumluca',
        value: 'Kumluca'
      },
      {
        label: 'Manavgat',
        value: 'Manavgat'
      },
      {
        label: 'Serik',
        value: 'Serik'
      },
      {
        label: 'Demre',
        value: 'Demre'
      },
      {
        label: 'İbradı',
        value: 'İbradı'
      },
      {
        label: 'Kemer',
        value: 'Kemer'
      },
      {
        label: 'Aksu',
        value: 'Aksu'
      },
      {
        label: 'Döşemealtı',
        value: 'Döşemealtı'
      },
      {
        label: 'Kepez',
        value: 'Kepez'
      },
      {
        label: 'Konyaaltı',
        value: 'Konyaaltı'
      },
      {
        label: 'Muratpaşa',
        value: 'Muratpaşa'
      }
    ]
  },
  {
    label: 'Artvin',
    value: 'Artvin',
    districts: [
      {
        label: 'Ardanuç',
        value: 'Ardanuç'
      },
      {
        label: 'Arhavi',
        value: 'Arhavi'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Borçka',
        value: 'Borçka'
      },
      {
        label: 'Hopa',
        value: 'Hopa'
      },
      {
        label: 'Şavşat',
        value: 'Şavşat'
      },
      {
        label: 'Yusufeli',
        value: 'Yusufeli'
      },
      {
        label: 'Murgul',
        value: 'Murgul'
      }
    ]
  },
  {
    label: 'Aydın',
    value: 'Aydın',
    districts: [
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Bozdoğan',
        value: 'Bozdoğan'
      },
      {
        label: 'Efeler',
        value: 'Efeler'
      },
      {
        label: 'Çine',
        value: 'Çine'
      },
      {
        label: 'Germencik',
        value: 'Germencik'
      },
      {
        label: 'Karacasu',
        value: 'Karacasu'
      },
      {
        label: 'Koçarlı',
        value: 'Koçarlı'
      },
      {
        label: 'Kuşadası',
        value: 'Kuşadası'
      },
      {
        label: 'Kuyucak',
        value: 'Kuyucak'
      },
      {
        label: 'Nazlabelli',
        value: 'Nazlabelli'
      },
      {
        label: 'Söke',
        value: 'Söke'
      },
      {
        label: 'Sultanhisar',
        value: 'Sultanhisar'
      },
      {
        label: 'Yenipazar',
        value: 'Yenipazar'
      },
      {
        label: 'Buharkent',
        value: 'Buharkent'
      },
      {
        label: 'İncirliova',
        value: 'İncirliova'
      },
      {
        label: 'Karpuzlu',
        value: 'Karpuzlu'
      },
      {
        label: 'Köşk',
        value: 'Köşk'
      },
      {
        label: 'Didim',
        value: 'Didim'
      }
    ]
  },
  {
    label: 'Balıkesir',
    value: 'Balıkesir',
    districts: [
      {
        label: 'Altıeylül',
        value: 'Altıeylül'
      },
      {
        label: 'Ayvalık',
        value: 'Ayvalık'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Balya',
        value: 'Balya'
      },
      {
        label: 'Bandırma',
        value: 'Bandırma'
      },
      {
        label: 'Bigadiç',
        value: 'Bigadiç'
      },
      {
        label: 'Burhaniye',
        value: 'Burhaniye'
      },
      {
        label: 'Dursunbey',
        value: 'Dursunbey'
      },
      {
        label: 'Edremit',
        value: 'Edremit'
      },
      {
        label: 'Erdek',
        value: 'Erdek'
      },
      {
        label: 'Gönen',
        value: 'Gönen'
      },
      {
        label: 'Havran',
        value: 'Havran'
      },
      {
        label: 'İvrindi',
        value: 'İvrindi'
      },
      {
        label: 'Karesi',
        value: 'Karesi'
      },
      {
        label: 'Kepsut',
        value: 'Kepsut'
      },
      {
        label: 'Manyas',
        value: 'Manyas'
      },
      {
        label: 'Savaştepe',
        value: 'Savaştepe'
      },
      {
        label: 'Sındırgı',
        value: 'Sındırgı'
      },
      {
        label: 'Gömeç',
        value: 'Gömeç'
      },
      {
        label: 'Susurluk',
        value: 'Susurluk'
      },
      {
        label: 'Marmara',
        value: 'Marmara'
      }
    ]
  },
  {
    label: 'Blabelecik',
    value: 'Blabelecik',
    districts: [
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Bozüyük',
        value: 'Bozüyük'
      },
      {
        label: 'Gölpazarı',
        value: 'Gölpazarı'
      },
      {
        label: 'Osmaneli',
        value: 'Osmaneli'
      },
      {
        label: 'Pazaryeri',
        value: 'Pazaryeri'
      },
      {
        label: 'Söğüt',
        value: 'Söğüt'
      },
      {
        label: 'Yenipazar',
        value: 'Yenipazar'
      },
      {
        label: 'İnhisar',
        value: 'İnhisar'
      }
    ]
  },
  {
    label: 'Bingöl',
    value: 'Bingöl',
    districts: [
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Genç',
        value: 'Genç'
      },
      {
        label: 'Karlıova',
        value: 'Karlıova'
      },
      {
        label: 'Kiğı',
        value: 'Kiğı'
      },
      {
        label: 'Solhan',
        value: 'Solhan'
      },
      {
        label: 'Adaklı',
        value: 'Adaklı'
      },
      {
        label: 'Yayladere',
        value: 'Yayladere'
      },
      {
        label: 'Yedisu',
        value: 'Yedisu'
      }
    ]
  },
  {
    label: 'Bitlis',
    value: 'Bitlis',
    districts: [
      {
        label: 'Adlabelcevaz',
        value: 'Adlabelcevaz'
      },
      {
        label: 'Ahlat',
        value: 'Ahlat'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Hizan',
        value: 'Hizan'
      },
      {
        label: 'Mutki',
        value: 'Mutki'
      },
      {
        label: 'Tatvan',
        value: 'Tatvan'
      },
      {
        label: 'Güroymak',
        value: 'Güroymak'
      }
    ]
  },
  {
    label: 'Bolu',
    value: 'Bolu',
    districts: [
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Gerede',
        value: 'Gerede'
      },
      {
        label: 'Göynük',
        value: 'Göynük'
      },
      {
        label: 'Kıbrıscık',
        value: 'Kıbrıscık'
      },
      {
        label: 'Mengen',
        value: 'Mengen'
      },
      {
        label: 'Mudurnu',
        value: 'Mudurnu'
      },
      {
        label: 'Seben',
        value: 'Seben'
      },
      {
        label: 'Dörtdivan',
        value: 'Dörtdivan'
      },
      {
        label: 'Yeniçağa',
        value: 'Yeniçağa'
      }
    ]
  },
  {
    label: 'Burdur',
    value: 'Burdur',
    districts: [
      {
        label: 'Ağlasun',
        value: 'Ağlasun'
      },
      {
        label: 'Bucak',
        value: 'Bucak'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Gölhisar',
        value: 'Gölhisar'
      },
      {
        label: 'Tefenni',
        value: 'Tefenni'
      },
      {
        label: 'Yeşlabelova',
        value: 'Yeşlabelova'
      },
      {
        label: 'Karamanlı',
        value: 'Karamanlı'
      },
      {
        label: 'Kemer',
        value: 'Kemer'
      },
      {
        label: 'Altınyayla',
        value: 'Altınyayla'
      },
      {
        label: 'Çavdır',
        value: 'Çavdır'
      },
      {
        label: 'Çeltikçi',
        value: 'Çeltikçi'
      }
    ]
  },
  {
    label: 'Bursa',
    value: 'Bursa',
    districts: [
      {
        label: 'Gemlik',
        value: 'Gemlik'
      },
      {
        label: 'İnegöl',
        value: 'İnegöl'
      },
      {
        label: 'İznik',
        value: 'İznik'
      },
      {
        label: 'Karacabey',
        value: 'Karacabey'
      },
      {
        label: 'Keles',
        value: 'Keles'
      },
      {
        label: 'Mudanya',
        value: 'Mudanya'
      },
      {
        label: 'Mustafakemalpaşa',
        value: 'Mustafakemalpaşa'
      },
      {
        label: 'Orhaneli',
        value: 'Orhaneli'
      },
      {
        label: 'Orhangazi',
        value: 'Orhangazi'
      },
      {
        label: 'Yenişehir',
        value: 'Yenişehir'
      },
      {
        label: 'Büyükorhan',
        value: 'Büyükorhan'
      },
      {
        label: 'Harmancık',
        value: 'Harmancık'
      },
      {
        label: 'Nlabelüfer',
        value: 'Nlabelüfer'
      },
      {
        label: 'Osmangazi',
        value: 'Osmangazi'
      },
      {
        label: 'Yıldırım',
        value: 'Yıldırım'
      },
      {
        label: 'Gürsu',
        value: 'Gürsu'
      },
      {
        label: 'Kestel',
        value: 'Kestel'
      }
    ]
  },
  {
    label: 'Çanakkale',
    value: 'Çanakkale',
    districts: [
      {
        label: 'Ayvacık',
        value: 'Ayvacık'
      },
      {
        label: 'Bayramiç',
        value: 'Bayramiç'
      },
      {
        label: 'Biga',
        value: 'Biga'
      },
      {
        label: 'Bozcaada',
        value: 'Bozcaada'
      },
      {
        label: 'Çan',
        value: 'Çan'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Eceabat',
        value: 'Eceabat'
      },
      {
        label: 'Ezine',
        value: 'Ezine'
      },
      {
        label: 'Gelibolu',
        value: 'Gelibolu'
      },
      {
        label: 'Gökçeada',
        value: 'Gökçeada'
      },
      {
        label: 'Lapseki',
        value: 'Lapseki'
      },
      {
        label: 'Yenice',
        value: 'Yenice'
      }
    ]
  },
  {
    label: 'Çankırı',
    value: 'Çankırı',
    districts: [
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Çerkeş',
        value: 'Çerkeş'
      },
      {
        label: 'Eldivan',
        value: 'Eldivan'
      },
      {
        label: 'labelgaz',
        value: 'labelgaz'
      },
      {
        label: 'Kurşunlu',
        value: 'Kurşunlu'
      },
      {
        label: 'Orta',
        value: 'Orta'
      },
      {
        label: 'Şabanözü',
        value: 'Şabanözü'
      },
      {
        label: 'Yapraklı',
        value: 'Yapraklı'
      },
      {
        label: 'Atkaracalar',
        value: 'Atkaracalar'
      },
      {
        label: 'Kızılırmak',
        value: 'Kızılırmak'
      },
      {
        label: 'Bayramören',
        value: 'Bayramören'
      },
      {
        label: 'Korgun',
        value: 'Korgun'
      }
    ]
  },
  {
    label: 'Çorum',
    value: 'Çorum',
    districts: [
      {
        label: 'Alaca',
        value: 'Alaca'
      },
      {
        label: 'Bayat',
        value: 'Bayat'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'İsklabelip',
        value: 'İsklabelip'
      },
      {
        label: 'Kargı',
        value: 'Kargı'
      },
      {
        label: 'Mecitözü',
        value: 'Mecitözü'
      },
      {
        label: 'Ortaköy',
        value: 'Ortaköy'
      },
      {
        label: 'Osmancık',
        value: 'Osmancık'
      },
      {
        label: 'Sungurlu',
        value: 'Sungurlu'
      },
      {
        label: 'Boğazkale',
        value: 'Boğazkale'
      },
      {
        label: 'Uğurludağ',
        value: 'Uğurludağ'
      },
      {
        label: 'Dodurga',
        value: 'Dodurga'
      },
      {
        label: 'Laçin',
        value: 'Laçin'
      },
      {
        label: 'Oğuzlar',
        value: 'Oğuzlar'
      }
    ]
  },
  {
    label: 'Denizli',
    value: 'Denizli',
    districts: [
      {
        label: 'Acıpayam',
        value: 'Acıpayam'
      },
      {
        label: 'Buldan',
        value: 'Buldan'
      },
      {
        label: 'Çal',
        value: 'Çal'
      },
      {
        label: 'Çameli',
        value: 'Çameli'
      },
      {
        label: 'Çardak',
        value: 'Çardak'
      },
      {
        label: 'Çivrlabel',
        value: 'Çivrlabel'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Merkezefendi',
        value: 'Merkezefendi'
      },
      {
        label: 'Pamukkale',
        value: 'Pamukkale'
      },
      {
        label: 'Güney',
        value: 'Güney'
      },
      {
        label: 'Kale',
        value: 'Kale'
      },
      {
        label: 'Sarayköy',
        value: 'Sarayköy'
      },
      {
        label: 'Tavas',
        value: 'Tavas'
      },
      {
        label: 'Babadağ',
        value: 'Babadağ'
      },
      {
        label: 'Beklabelli',
        value: 'Beklabelli'
      },
      {
        label: 'Honaz',
        value: 'Honaz'
      },
      {
        label: 'Serinhisar',
        value: 'Serinhisar'
      },
      {
        label: 'Baklan',
        value: 'Baklan'
      },
      {
        label: 'Beyağaç',
        value: 'Beyağaç'
      },
      {
        label: 'Bozkurt',
        value: 'Bozkurt'
      }
    ]
  },
  {
    label: 'Diyarbakır',
    value: 'Diyarbakır',
    districts: [
      {
        label: 'Kocaköy',
        value: 'Kocaköy'
      },
      {
        label: 'Çermik',
        value: 'Çermik'
      },
      {
        label: 'Çınar',
        value: 'Çınar'
      },
      {
        label: 'Çüngüş',
        value: 'Çüngüş'
      },
      {
        label: 'Dicle',
        value: 'Dicle'
      },
      {
        label: 'Ergani',
        value: 'Ergani'
      },
      {
        label: 'Hani',
        value: 'Hani'
      },
      {
        label: 'Hazro',
        value: 'Hazro'
      },
      {
        label: 'Kulp',
        value: 'Kulp'
      },
      {
        label: 'Lice',
        value: 'Lice'
      },
      {
        label: 'Slabelvan',
        value: 'Slabelvan'
      },
      {
        label: 'Eğlabel',
        value: 'Eğlabel'
      },
      {
        label: 'Bağlar',
        value: 'Bağlar'
      },
      {
        label: 'Kayapınar',
        value: 'Kayapınar'
      },
      {
        label: 'Sur',
        value: 'Sur'
      },
      {
        label: 'Yenişehir',
        value: 'Yenişehir'
      },
      {
        label: 'Bismlabel',
        value: 'Bismlabel'
      }
    ]
  },
  {
    label: 'Edirne',
    value: 'Edirne',
    districts: [
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Enez',
        value: 'Enez'
      },
      {
        label: 'Havsa',
        value: 'Havsa'
      },
      {
        label: 'İpsala',
        value: 'İpsala'
      },
      {
        label: 'Keşan',
        value: 'Keşan'
      },
      {
        label: 'Lalapaşa',
        value: 'Lalapaşa'
      },
      {
        label: 'Meriç',
        value: 'Meriç'
      },
      {
        label: 'Uzunköprü',
        value: 'Uzunköprü'
      },
      {
        label: 'Süloğlu',
        value: 'Süloğlu'
      }
    ]
  },
  {
    label: 'Elazığ',
    value: 'Elazığ',
    districts: [
      {
        label: 'Ağın',
        value: 'Ağın'
      },
      {
        label: 'Basklabel',
        value: 'Basklabel'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Karakoçan',
        value: 'Karakoçan'
      },
      {
        label: 'Keban',
        value: 'Keban'
      },
      {
        label: 'Maden',
        value: 'Maden'
      },
      {
        label: 'Palu',
        value: 'Palu'
      },
      {
        label: 'Sivrice',
        value: 'Sivrice'
      },
      {
        label: 'Arıcak',
        value: 'Arıcak'
      },
      {
        label: 'Kovancılar',
        value: 'Kovancılar'
      },
      {
        label: 'Alacakaya',
        value: 'Alacakaya'
      }
    ]
  },
  {
    label: 'Erzincan',
    value: 'Erzincan',
    districts: [
      {
        label: 'Çayırlı',
        value: 'Çayırlı'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'İliç',
        value: 'İliç'
      },
      {
        label: 'Kemah',
        value: 'Kemah'
      },
      {
        label: 'Kemaliye',
        value: 'Kemaliye'
      },
      {
        label: 'Refahiye',
        value: 'Refahiye'
      },
      {
        label: 'Tercan',
        value: 'Tercan'
      },
      {
        label: 'Üzümlü',
        value: 'Üzümlü'
      },
      {
        label: 'Otlukbeli',
        value: 'Otlukbeli'
      }
    ]
  },
  {
    label: 'Erzurum',
    value: 'Erzurum',
    districts: [
      {
        label: 'Aşkale',
        value: 'Aşkale'
      },
      {
        label: 'Çat',
        value: 'Çat'
      },
      {
        label: 'Hınıs',
        value: 'Hınıs'
      },
      {
        label: 'Horasan',
        value: 'Horasan'
      },
      {
        label: 'İspir',
        value: 'İspir'
      },
      {
        label: 'Karayazı',
        value: 'Karayazı'
      },
      {
        label: 'Narman',
        value: 'Narman'
      },
      {
        label: 'Oltu',
        value: 'Oltu'
      },
      {
        label: 'Olur',
        value: 'Olur'
      },
      {
        label: 'Pasinler',
        value: 'Pasinler'
      },
      {
        label: 'Şenkaya',
        value: 'Şenkaya'
      },
      {
        label: 'Tekman',
        value: 'Tekman'
      },
      {
        label: 'Tortum',
        value: 'Tortum'
      },
      {
        label: 'Karaçoban',
        value: 'Karaçoban'
      },
      {
        label: 'Uzundere',
        value: 'Uzundere'
      },
      {
        label: 'Pazaryolu',
        value: 'Pazaryolu'
      },
      {
        label: 'Köprüköy',
        value: 'Köprüköy'
      },
      {
        label: 'Palandöken',
        value: 'Palandöken'
      },
      {
        label: 'Yakutiye',
        value: 'Yakutiye'
      },
      {
        label: 'Aziziye',
        value: 'Aziziye'
      }
    ]
  },
  {
    label: 'Eskişehir',
    value: 'Eskişehir',
    districts: [
      {
        label: 'Çifteler',
        value: 'Çifteler'
      },
      {
        label: 'Mahmudiye',
        value: 'Mahmudiye'
      },
      {
        label: 'Mihalıççık',
        value: 'Mihalıççık'
      },
      {
        label: 'Sarıcakaya',
        value: 'Sarıcakaya'
      },
      {
        label: 'Seyitgazi',
        value: 'Seyitgazi'
      },
      {
        label: 'Sivrihisar',
        value: 'Sivrihisar'
      },
      {
        label: 'Alpu',
        value: 'Alpu'
      },
      {
        label: 'Beylikova',
        value: 'Beylikova'
      },
      {
        label: 'İnönü',
        value: 'İnönü'
      },
      {
        label: 'Günyüzü',
        value: 'Günyüzü'
      },
      {
        label: 'Han',
        value: 'Han'
      },
      {
        label: 'Mihalgazi',
        value: 'Mihalgazi'
      },
      {
        label: 'Odunpazarı',
        value: 'Odunpazarı'
      },
      {
        label: 'Tepebaşı',
        value: 'Tepebaşı'
      }
    ]
  },
  {
    label: 'Gaziantep',
    value: 'Gaziantep',
    districts: [
      {
        label: 'Araban',
        value: 'Araban'
      },
      {
        label: 'İslahiye',
        value: 'İslahiye'
      },
      {
        label: 'Nizip',
        value: 'Nizip'
      },
      {
        label: 'Oğuzeli',
        value: 'Oğuzeli'
      },
      {
        label: 'Yavuzeli',
        value: 'Yavuzeli'
      },
      {
        label: 'Şahinbey',
        value: 'Şahinbey'
      },
      {
        label: 'Şehitkamlabel',
        value: 'Şehitkamlabel'
      },
      {
        label: 'Karkamış',
        value: 'Karkamış'
      },
      {
        label: 'Nurdağı',
        value: 'Nurdağı'
      }
    ]
  },
  {
    label: 'Giresun',
    value: 'Giresun',
    districts: [
      {
        label: 'Alucra',
        value: 'Alucra'
      },
      {
        label: 'Bulancak',
        value: 'Bulancak'
      },
      {
        label: 'Dereli',
        value: 'Dereli'
      },
      {
        label: 'Espiye',
        value: 'Espiye'
      },
      {
        label: 'Eyneslabel',
        value: 'Eyneslabel'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Görele',
        value: 'Görele'
      },
      {
        label: 'Keşap',
        value: 'Keşap'
      },
      {
        label: 'Şebinkarahisar',
        value: 'Şebinkarahisar'
      },
      {
        label: 'Tirebolu',
        value: 'Tirebolu'
      },
      {
        label: 'Piraziz',
        value: 'Piraziz'
      },
      {
        label: 'Yağlıdere',
        value: 'Yağlıdere'
      },
      {
        label: 'Çamoluk',
        value: 'Çamoluk'
      },
      {
        label: 'Çanakçı',
        value: 'Çanakçı'
      },
      {
        label: 'Doğankent',
        value: 'Doğankent'
      },
      {
        label: 'Güce',
        value: 'Güce'
      }
    ]
  },
  {
    label: 'Gümüşhane',
    value: 'Gümüşhane',
    districts: [
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Kelkit',
        value: 'Kelkit'
      },
      {
        label: 'Şiran',
        value: 'Şiran'
      },
      {
        label: 'Torul',
        value: 'Torul'
      },
      {
        label: 'Köse',
        value: 'Köse'
      },
      {
        label: 'Kürtün',
        value: 'Kürtün'
      }
    ]
  },
  {
    label: 'Hakkari',
    value: 'Hakkari',
    districts: [
      {
        label: 'Çukurca',
        value: 'Çukurca'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Şemdinli',
        value: 'Şemdinli'
      },
      {
        label: 'Yüksekova',
        value: 'Yüksekova'
      }
    ]
  },
  {
    label: 'Hatay',
    value: 'Hatay',
    districts: [
      {
        label: 'Altınözü',
        value: 'Altınözü'
      },
      {
        label: 'Arsuz',
        value: 'Arsuz'
      },
      {
        label: 'Defne',
        value: 'Defne'
      },
      {
        label: 'Dörtyol',
        value: 'Dörtyol'
      },
      {
        label: 'Hassa',
        value: 'Hassa'
      },
      {
        label: 'Antakya',
        value: 'Antakya'
      },
      {
        label: 'İskenderun',
        value: 'İskenderun'
      },
      {
        label: 'Kırıkhan',
        value: 'Kırıkhan'
      },
      {
        label: 'Payas',
        value: 'Payas'
      },
      {
        label: 'Reyhanlı',
        value: 'Reyhanlı'
      },
      {
        label: 'Samandağ',
        value: 'Samandağ'
      },
      {
        label: 'Yayladağı',
        value: 'Yayladağı'
      },
      {
        label: 'Erzin',
        value: 'Erzin'
      },
      {
        label: 'Belen',
        value: 'Belen'
      },
      {
        label: 'Kumlu',
        value: 'Kumlu'
      }
    ]
  },
  {
    label: 'Isparta',
    value: 'Isparta',
    districts: [
      {
        label: 'Atabey',
        value: 'Atabey'
      },
      {
        label: 'Eğirdir',
        value: 'Eğirdir'
      },
      {
        label: 'Gelendost',
        value: 'Gelendost'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Keçiborlu',
        value: 'Keçiborlu'
      },
      {
        label: 'Senirkent',
        value: 'Senirkent'
      },
      {
        label: 'Sütçüler',
        value: 'Sütçüler'
      },
      {
        label: 'Şarkikaraağaç',
        value: 'Şarkikaraağaç'
      },
      {
        label: 'Uluborlu',
        value: 'Uluborlu'
      },
      {
        label: 'Yalvaç',
        value: 'Yalvaç'
      },
      {
        label: 'Aksu',
        value: 'Aksu'
      },
      {
        label: 'Gönen',
        value: 'Gönen'
      },
      {
        label: 'Yenişarbademli',
        value: 'Yenişarbademli'
      }
    ]
  },
  {
    label: 'Mersin',
    value: 'Mersin',
    districts: [
      {
        label: 'Anamur',
        value: 'Anamur'
      },
      {
        label: 'Erdemli',
        value: 'Erdemli'
      },
      {
        label: 'Gülnar',
        value: 'Gülnar'
      },
      {
        label: 'Mut',
        value: 'Mut'
      },
      {
        label: 'Slabelifke',
        value: 'Slabelifke'
      },
      {
        label: 'Tarsus',
        value: 'Tarsus'
      },
      {
        label: 'Aydıncık',
        value: 'Aydıncık'
      },
      {
        label: 'Bozyazı',
        value: 'Bozyazı'
      },
      {
        label: 'Çamlıyayla',
        value: 'Çamlıyayla'
      },
      {
        label: 'Akdeniz',
        value: 'Akdeniz'
      },
      {
        label: 'Mezitli',
        value: 'Mezitli'
      },
      {
        label: 'Toroslar',
        value: 'Toroslar'
      },
      {
        label: 'Yenişehir',
        value: 'Yenişehir'
      }
    ]
  },
  {
    label: 'İstanbul',
    value: 'İstanbul',
    districts: [
      {
        label: 'Adalar',
        value: 'Adalar'
      },
      {
        label: 'Bakırköy',
        value: 'Bakırköy'
      },
      {
        label: 'Beşiktaş',
        value: 'Beşiktaş'
      },
      {
        label: 'Beykoz',
        value: 'Beykoz'
      },
      {
        label: 'Beyoğlu',
        value: 'Beyoğlu'
      },
      {
        label: 'Çatalca',
        value: 'Çatalca'
      },
      {
        label: 'Eyüp',
        value: 'Eyüp'
      },
      {
        label: 'Fatih',
        value: 'Fatih'
      },
      {
        label: 'Gaziosmanpaşa',
        value: 'Gaziosmanpaşa'
      },
      {
        label: 'Kadıköy',
        value: 'Kadıköy'
      },
      {
        label: 'Kartal',
        value: 'Kartal'
      },
      {
        label: 'Sarıyer',
        value: 'Sarıyer'
      },
      {
        label: 'Slabelivri',
        value: 'Slabelivri'
      },
      {
        label: 'Şlabele',
        value: 'Şlabele'
      },
      {
        label: 'Şişli',
        value: 'Şişli'
      },
      {
        label: 'Üsküdar',
        value: 'Üsküdar'
      },
      {
        label: 'Zeytinburnu',
        value: 'Zeytinburnu'
      },
      {
        label: 'Büyükçekmece',
        value: 'Büyükçekmece'
      },
      {
        label: 'Kağıthane',
        value: 'Kağıthane'
      },
      {
        label: 'Küçükçekmece',
        value: 'Küçükçekmece'
      },
      {
        label: 'Pendik',
        value: 'Pendik'
      },
      {
        label: 'Ümraniye',
        value: 'Ümraniye'
      },
      {
        label: 'Bayrampaşa',
        value: 'Bayrampaşa'
      },
      {
        label: 'Avcılar',
        value: 'Avcılar'
      },
      {
        label: 'Bağcılar',
        value: 'Bağcılar'
      },
      {
        label: 'Bahçelievler',
        value: 'Bahçelievler'
      },
      {
        label: 'Güngören',
        value: 'Güngören'
      },
      {
        label: 'Maltepe',
        value: 'Maltepe'
      },
      {
        label: 'Sultanbeyli',
        value: 'Sultanbeyli'
      },
      {
        label: 'Tuzla',
        value: 'Tuzla'
      },
      {
        label: 'Esenler',
        value: 'Esenler'
      },
      {
        label: 'Arnavutköy',
        value: 'Arnavutköy'
      },
      {
        label: 'Ataşehir',
        value: 'Ataşehir'
      },
      {
        label: 'Başakşehir',
        value: 'Başakşehir'
      },
      {
        label: 'Beylikdüzü',
        value: 'Beylikdüzü'
      },
      {
        label: 'Çekmeköy',
        value: 'Çekmeköy'
      },
      {
        label: 'Esenyurt',
        value: 'Esenyurt'
      },
      {
        label: 'Sancaktepe',
        value: 'Sancaktepe'
      },
      {
        label: 'Sultangazi',
        value: 'Sultangazi'
      }
    ]
  },
  {
    label: 'İzmir',
    value: 'İzmir',
    districts: [
      {
        label: 'Aliağa',
        value: 'Aliağa'
      },
      {
        label: 'Bayındır',
        value: 'Bayındır'
      },
      {
        label: 'Bergama',
        value: 'Bergama'
      },
      {
        label: 'Bornova',
        value: 'Bornova'
      },
      {
        label: 'Çeşme',
        value: 'Çeşme'
      },
      {
        label: 'Diklabeli',
        value: 'Diklabeli'
      },
      {
        label: 'Foça',
        value: 'Foça'
      },
      {
        label: 'Karaburun',
        value: 'Karaburun'
      },
      {
        label: 'Karşıyaka',
        value: 'Karşıyaka'
      },
      {
        label: 'Kemalpaşa',
        value: 'Kemalpaşa'
      },
      {
        label: 'Kınık',
        value: 'Kınık'
      },
      {
        label: 'Kiraz',
        value: 'Kiraz'
      },
      {
        label: 'Menemen',
        value: 'Menemen'
      },
      {
        label: 'Ödemiş',
        value: 'Ödemiş'
      },
      {
        label: 'Seferihisar',
        value: 'Seferihisar'
      },
      {
        label: 'Selçuk',
        value: 'Selçuk'
      },
      {
        label: 'Tire',
        value: 'Tire'
      },
      {
        label: 'Torbalı',
        value: 'Torbalı'
      },
      {
        label: 'Urla',
        value: 'Urla'
      },
      {
        label: 'Beydağ',
        value: 'Beydağ'
      },
      {
        label: 'Buca',
        value: 'Buca'
      },
      {
        label: 'Konak',
        value: 'Konak'
      },
      {
        label: 'Menderes',
        value: 'Menderes'
      },
      {
        label: 'Balçova',
        value: 'Balçova'
      },
      {
        label: 'Çiğli',
        value: 'Çiğli'
      },
      {
        label: 'Gaziemir',
        value: 'Gaziemir'
      },
      {
        label: 'Narlıdere',
        value: 'Narlıdere'
      },
      {
        label: 'Güzelbahçe',
        value: 'Güzelbahçe'
      },
      {
        label: 'Bayraklı',
        value: 'Bayraklı'
      },
      {
        label: 'Karabağlar',
        value: 'Karabağlar'
      }
    ]
  },
  {
    label: 'Kars',
    value: 'Kars',
    districts: [
      {
        label: 'Arpaçay',
        value: 'Arpaçay'
      },
      {
        label: 'Digor',
        value: 'Digor'
      },
      {
        label: 'Kağızman',
        value: 'Kağızman'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Sarıkamış',
        value: 'Sarıkamış'
      },
      {
        label: 'Selim',
        value: 'Selim'
      },
      {
        label: 'Susuz',
        value: 'Susuz'
      },
      {
        label: 'Akyaka',
        value: 'Akyaka'
      }
    ]
  },
  {
    label: 'Kastamonu',
    value: 'Kastamonu',
    districts: [
      {
        label: 'Abana',
        value: 'Abana'
      },
      {
        label: 'Araç',
        value: 'Araç'
      },
      {
        label: 'Azdavay',
        value: 'Azdavay'
      },
      {
        label: 'Bozkurt',
        value: 'Bozkurt'
      },
      {
        label: 'Cide',
        value: 'Cide'
      },
      {
        label: 'Çatalzeytin',
        value: 'Çatalzeytin'
      },
      {
        label: 'Daday',
        value: 'Daday'
      },
      {
        label: 'Devrekani',
        value: 'Devrekani'
      },
      {
        label: 'İnebolu',
        value: 'İnebolu'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Küre',
        value: 'Küre'
      },
      {
        label: 'Taşköprü',
        value: 'Taşköprü'
      },
      {
        label: 'Tosya',
        value: 'Tosya'
      },
      {
        label: 'İhsangazi',
        value: 'İhsangazi'
      },
      {
        label: 'Pınarbaşı',
        value: 'Pınarbaşı'
      },
      {
        label: 'Şenpazar',
        value: 'Şenpazar'
      },
      {
        label: 'Ağlı',
        value: 'Ağlı'
      },
      {
        label: 'Doğanyurt',
        value: 'Doğanyurt'
      },
      {
        label: 'Hanönü',
        value: 'Hanönü'
      },
      {
        label: 'Seydlabeler',
        value: 'Seydlabeler'
      }
    ]
  },
  {
    label: 'Kayseri',
    value: 'Kayseri',
    districts: [
      {
        label: 'Bünyan',
        value: 'Bünyan'
      },
      {
        label: 'Develi',
        value: 'Develi'
      },
      {
        label: 'Felahiye',
        value: 'Felahiye'
      },
      {
        label: 'İncesu',
        value: 'İncesu'
      },
      {
        label: 'Pınarbaşı',
        value: 'Pınarbaşı'
      },
      {
        label: 'Sarıoğlan',
        value: 'Sarıoğlan'
      },
      {
        label: 'Sarız',
        value: 'Sarız'
      },
      {
        label: 'Tomarza',
        value: 'Tomarza'
      },
      {
        label: 'Yahyalı',
        value: 'Yahyalı'
      },
      {
        label: 'Yeşlabelhisar',
        value: 'Yeşlabelhisar'
      },
      {
        label: 'Akkışla',
        value: 'Akkışla'
      },
      {
        label: 'Talas',
        value: 'Talas'
      },
      {
        label: 'Kocasinan',
        value: 'Kocasinan'
      },
      {
        label: 'Melikgazi',
        value: 'Melikgazi'
      },
      {
        label: 'Hacılar',
        value: 'Hacılar'
      },
      {
        label: 'Özvatan',
        value: 'Özvatan'
      }
    ]
  },
  {
    label: 'Kırklareli',
    value: 'Kırklareli',
    districts: [
      {
        label: 'Babaeski',
        value: 'Babaeski'
      },
      {
        label: 'Demirköy',
        value: 'Demirköy'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Kofçaz',
        value: 'Kofçaz'
      },
      {
        label: 'Lüleburgaz',
        value: 'Lüleburgaz'
      },
      {
        label: 'Pehlivanköy',
        value: 'Pehlivanköy'
      },
      {
        label: 'Pınarhisar',
        value: 'Pınarhisar'
      },
      {
        label: 'Vize',
        value: 'Vize'
      }
    ]
  },
  {
    label: 'Kırşehir',
    value: 'Kırşehir',
    districts: [
      {
        label: 'Çiçekdağı',
        value: 'Çiçekdağı'
      },
      {
        label: 'Kaman',
        value: 'Kaman'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Mucur',
        value: 'Mucur'
      },
      {
        label: 'Akpınar',
        value: 'Akpınar'
      },
      {
        label: 'Akçakent',
        value: 'Akçakent'
      },
      {
        label: 'Boztepe',
        value: 'Boztepe'
      }
    ]
  },
  {
    label: 'Kocaeli',
    value: 'Kocaeli',
    districts: [
      {
        label: 'Gebze',
        value: 'Gebze'
      },
      {
        label: 'Gölcük',
        value: 'Gölcük'
      },
      {
        label: 'Kandıra',
        value: 'Kandıra'
      },
      {
        label: 'Karamürsel',
        value: 'Karamürsel'
      },
      {
        label: 'Körfez',
        value: 'Körfez'
      },
      {
        label: 'Derince',
        value: 'Derince'
      },
      {
        label: 'Başiskele',
        value: 'Başiskele'
      },
      {
        label: 'Çayırova',
        value: 'Çayırova'
      },
      {
        label: 'Darıca',
        value: 'Darıca'
      },
      {
        label: 'Dlabelovası',
        value: 'Dlabelovası'
      },
      {
        label: 'İzmit',
        value: 'İzmit'
      },
      {
        label: 'Kartepe',
        value: 'Kartepe'
      }
    ]
  },
  {
    label: 'Konya',
    value: 'Konya',
    districts: [
      {
        label: 'Akşehir',
        value: 'Akşehir'
      },
      {
        label: 'Beyşehir',
        value: 'Beyşehir'
      },
      {
        label: 'Bozkır',
        value: 'Bozkır'
      },
      {
        label: 'Cihanbeyli',
        value: 'Cihanbeyli'
      },
      {
        label: 'Çumra',
        value: 'Çumra'
      },
      {
        label: 'Doğanhisar',
        value: 'Doğanhisar'
      },
      {
        label: 'Ereğli',
        value: 'Ereğli'
      },
      {
        label: 'Hadim',
        value: 'Hadim'
      },
      {
        label: 'labelgın',
        value: 'labelgın'
      },
      {
        label: 'Kadınhanı',
        value: 'Kadınhanı'
      },
      {
        label: 'Karapınar',
        value: 'Karapınar'
      },
      {
        label: 'Kulu',
        value: 'Kulu'
      },
      {
        label: 'Sarayönü',
        value: 'Sarayönü'
      },
      {
        label: 'Seydişehir',
        value: 'Seydişehir'
      },
      {
        label: 'Yunak',
        value: 'Yunak'
      },
      {
        label: 'Akören',
        value: 'Akören'
      },
      {
        label: 'Altınekin',
        value: 'Altınekin'
      },
      {
        label: 'Derebucak',
        value: 'Derebucak'
      },
      {
        label: 'Hüyük',
        value: 'Hüyük'
      },
      {
        label: 'Karatay',
        value: 'Karatay'
      },
      {
        label: 'Meram',
        value: 'Meram'
      },
      {
        label: 'Selçuklu',
        value: 'Selçuklu'
      },
      {
        label: 'Taşkent',
        value: 'Taşkent'
      },
      {
        label: 'Ahırlı',
        value: 'Ahırlı'
      },
      {
        label: 'Çeltik',
        value: 'Çeltik'
      },
      {
        label: 'Derbent',
        value: 'Derbent'
      },
      {
        label: 'Emirgazi',
        value: 'Emirgazi'
      },
      {
        label: 'Güneysınır',
        value: 'Güneysınır'
      },
      {
        label: 'Halkapınar',
        value: 'Halkapınar'
      },
      {
        label: 'Tuzlukçu',
        value: 'Tuzlukçu'
      },
      {
        label: 'Yalıhüyük',
        value: 'Yalıhüyük'
      }
    ]
  },
  {
    label: 'Kütahya',
    value: 'Kütahya',
    districts: [
      {
        label: 'Altıntaş',
        value: 'Altıntaş'
      },
      {
        label: 'Domaniç',
        value: 'Domaniç'
      },
      {
        label: 'Emet',
        value: 'Emet'
      },
      {
        label: 'Gediz',
        value: 'Gediz'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Simav',
        value: 'Simav'
      },
      {
        label: 'Tavşanlı',
        value: 'Tavşanlı'
      },
      {
        label: 'Aslanapa',
        value: 'Aslanapa'
      },
      {
        label: 'Dumlupınar',
        value: 'Dumlupınar'
      },
      {
        label: 'Hisarcık',
        value: 'Hisarcık'
      },
      {
        label: 'Şaphane',
        value: 'Şaphane'
      },
      {
        label: 'Çavdarhisar',
        value: 'Çavdarhisar'
      },
      {
        label: 'Pazarlar',
        value: 'Pazarlar'
      }
    ]
  },
  {
    label: 'Malatya',
    value: 'Malatya',
    districts: [
      {
        label: 'Akçadağ',
        value: 'Akçadağ'
      },
      {
        label: 'Arapgir',
        value: 'Arapgir'
      },
      {
        label: 'Arguvan',
        value: 'Arguvan'
      },
      {
        label: 'Darende',
        value: 'Darende'
      },
      {
        label: 'Doğanşehir',
        value: 'Doğanşehir'
      },
      {
        label: 'Hekimhan',
        value: 'Hekimhan'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Pütürge',
        value: 'Pütürge'
      },
      {
        label: 'Yeşlabelyurt',
        value: 'Yeşlabelyurt'
      },
      {
        label: 'Battalgazi',
        value: 'Battalgazi'
      },
      {
        label: 'Doğanyol',
        value: 'Doğanyol'
      },
      {
        label: 'Kale',
        value: 'Kale'
      },
      {
        label: 'Kuluncak',
        value: 'Kuluncak'
      },
      {
        label: 'Yazıhan',
        value: 'Yazıhan'
      }
    ]
  },
  {
    label: 'Manisa',
    value: 'Manisa',
    districts: [
      {
        label: 'Akhisar',
        value: 'Akhisar'
      },
      {
        label: 'Alaşehir',
        value: 'Alaşehir'
      },
      {
        label: 'Demirci',
        value: 'Demirci'
      },
      {
        label: 'Gördes',
        value: 'Gördes'
      },
      {
        label: 'Kırkağaç',
        value: 'Kırkağaç'
      },
      {
        label: 'Kula',
        value: 'Kula'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Salihli',
        value: 'Salihli'
      },
      {
        label: 'Sarıgöl',
        value: 'Sarıgöl'
      },
      {
        label: 'Saruhanlı',
        value: 'Saruhanlı'
      },
      {
        label: 'Selendi',
        value: 'Selendi'
      },
      {
        label: 'Soma',
        value: 'Soma'
      },
      {
        label: 'Şehzadeler',
        value: 'Şehzadeler'
      },
      {
        label: 'Yunusemre',
        value: 'Yunusemre'
      },
      {
        label: 'Turgutlu',
        value: 'Turgutlu'
      },
      {
        label: 'Ahmetli',
        value: 'Ahmetli'
      },
      {
        label: 'Gölmarmara',
        value: 'Gölmarmara'
      },
      {
        label: 'Köprübaşı',
        value: 'Köprübaşı'
      }
    ]
  },
  {
    label: 'Kahramanmaraş',
    value: 'Kahramanmaraş',
    districts: [
      {
        label: 'Afşin',
        value: 'Afşin'
      },
      {
        label: 'Andırın',
        value: 'Andırın'
      },
      {
        label: 'Dulkadiroğlu',
        value: 'Dulkadiroğlu'
      },
      {
        label: 'Onikişubat',
        value: 'Onikişubat'
      },
      {
        label: 'Elbistan',
        value: 'Elbistan'
      },
      {
        label: 'Göksun',
        value: 'Göksun'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Pazarcık',
        value: 'Pazarcık'
      },
      {
        label: 'Türkoğlu',
        value: 'Türkoğlu'
      },
      {
        label: 'Çağlayancerit',
        value: 'Çağlayancerit'
      },
      {
        label: 'Ekinözü',
        value: 'Ekinözü'
      },
      {
        label: 'Nurhak',
        value: 'Nurhak'
      }
    ]
  },
  {
    label: 'Mardin',
    value: 'Mardin',
    districts: [
      {
        label: 'Derik',
        value: 'Derik'
      },
      {
        label: 'Kızıltepe',
        value: 'Kızıltepe'
      },
      {
        label: 'Artuklu',
        value: 'Artuklu'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Mazıdağı',
        value: 'Mazıdağı'
      },
      {
        label: 'Midyat',
        value: 'Midyat'
      },
      {
        label: 'Nusaybin',
        value: 'Nusaybin'
      },
      {
        label: 'Ömerli',
        value: 'Ömerli'
      },
      {
        label: 'Savur',
        value: 'Savur'
      },
      {
        label: 'Dargeçit',
        value: 'Dargeçit'
      },
      {
        label: 'Yeşlabelli',
        value: 'Yeşlabelli'
      }
    ]
  },
  {
    label: 'Muğla',
    value: 'Muğla',
    districts: [
      {
        label: 'Bodrum',
        value: 'Bodrum'
      },
      {
        label: 'Datça',
        value: 'Datça'
      },
      {
        label: 'Fethiye',
        value: 'Fethiye'
      },
      {
        label: 'Köyceğiz',
        value: 'Köyceğiz'
      },
      {
        label: 'Marmaris',
        value: 'Marmaris'
      },
      {
        label: 'Menteşe',
        value: 'Menteşe'
      },
      {
        label: 'Mlabelas',
        value: 'Mlabelas'
      },
      {
        label: 'Ula',
        value: 'Ula'
      },
      {
        label: 'Yatağan',
        value: 'Yatağan'
      },
      {
        label: 'Dalaman',
        value: 'Dalaman'
      },
      {
        label: 'Seydikemer',
        value: 'Seydikemer'
      },
      {
        label: 'Ortaca',
        value: 'Ortaca'
      },
      {
        label: 'Kavaklıdere',
        value: 'Kavaklıdere'
      }
    ]
  },
  {
    label: 'Muş',
    value: 'Muş',
    districts: [
      {
        label: 'Bulanık',
        value: 'Bulanık'
      },
      {
        label: 'Malazgirt',
        value: 'Malazgirt'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Varto',
        value: 'Varto'
      },
      {
        label: 'Hasköy',
        value: 'Hasköy'
      },
      {
        label: 'Korkut',
        value: 'Korkut'
      }
    ]
  },
  {
    label: 'Nevşehir',
    value: 'Nevşehir',
    districts: [
      {
        label: 'Avanos',
        value: 'Avanos'
      },
      {
        label: 'Derinkuyu',
        value: 'Derinkuyu'
      },
      {
        label: 'Gülşehir',
        value: 'Gülşehir'
      },
      {
        label: 'Hacıbektaş',
        value: 'Hacıbektaş'
      },
      {
        label: 'Kozaklı',
        value: 'Kozaklı'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Ürgüp',
        value: 'Ürgüp'
      },
      {
        label: 'Acıgöl',
        value: 'Acıgöl'
      }
    ]
  },
  {
    label: 'Niğde',
    value: 'Niğde',
    districts: [
      {
        label: 'Bor',
        value: 'Bor'
      },
      {
        label: 'Çamardı',
        value: 'Çamardı'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Ulukışla',
        value: 'Ulukışla'
      },
      {
        label: 'Altunhisar',
        value: 'Altunhisar'
      },
      {
        label: 'Çiftlik',
        value: 'Çiftlik'
      }
    ]
  },
  {
    label: 'Ordu',
    value: 'Ordu',
    districts: [
      {
        label: 'Akkuş',
        value: 'Akkuş'
      },
      {
        label: 'Altınordu',
        value: 'Altınordu'
      },
      {
        label: 'Aybastı',
        value: 'Aybastı'
      },
      {
        label: 'Fatsa',
        value: 'Fatsa'
      },
      {
        label: 'Gölköy',
        value: 'Gölköy'
      },
      {
        label: 'Korgan',
        value: 'Korgan'
      },
      {
        label: 'Kumru',
        value: 'Kumru'
      },
      {
        label: 'Mesudiye',
        value: 'Mesudiye'
      },
      {
        label: 'Perşembe',
        value: 'Perşembe'
      },
      {
        label: 'Ulubey',
        value: 'Ulubey'
      },
      {
        label: 'Ünye',
        value: 'Ünye'
      },
      {
        label: 'Gülyalı',
        value: 'Gülyalı'
      },
      {
        label: 'Gürgentepe',
        value: 'Gürgentepe'
      },
      {
        label: 'Çamaş',
        value: 'Çamaş'
      },
      {
        label: 'Çatalpınar',
        value: 'Çatalpınar'
      },
      {
        label: 'Çaybaşı',
        value: 'Çaybaşı'
      },
      {
        label: 'İkizce',
        value: 'İkizce'
      },
      {
        label: 'Kabadüz',
        value: 'Kabadüz'
      },
      {
        label: 'Kabataş',
        value: 'Kabataş'
      }
    ]
  },
  {
    label: 'Rize',
    value: 'Rize',
    districts: [
      {
        label: 'Ardeşen',
        value: 'Ardeşen'
      },
      {
        label: 'Çamlıhemşin',
        value: 'Çamlıhemşin'
      },
      {
        label: 'Çayeli',
        value: 'Çayeli'
      },
      {
        label: 'Fındıklı',
        value: 'Fındıklı'
      },
      {
        label: 'İkizdere',
        value: 'İkizdere'
      },
      {
        label: 'Kalkandere',
        value: 'Kalkandere'
      },
      {
        label: 'Pazar',
        value: 'Pazar'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Güneysu',
        value: 'Güneysu'
      },
      {
        label: 'Derepazarı',
        value: 'Derepazarı'
      },
      {
        label: 'Hemşin',
        value: 'Hemşin'
      },
      {
        label: 'İyidere',
        value: 'İyidere'
      }
    ]
  },
  {
    label: 'Sakarya',
    value: 'Sakarya',
    districts: [
      {
        label: 'Akyazı',
        value: 'Akyazı'
      },
      {
        label: 'Geyve',
        value: 'Geyve'
      },
      {
        label: 'Hendek',
        value: 'Hendek'
      },
      {
        label: 'Karasu',
        value: 'Karasu'
      },
      {
        label: 'Kaynarca',
        value: 'Kaynarca'
      },
      {
        label: 'Sapanca',
        value: 'Sapanca'
      },
      {
        label: 'Kocaali',
        value: 'Kocaali'
      },
      {
        label: 'Pamukova',
        value: 'Pamukova'
      },
      {
        label: 'Taraklı',
        value: 'Taraklı'
      },
      {
        label: 'Ferizli',
        value: 'Ferizli'
      },
      {
        label: 'Karapürçek',
        value: 'Karapürçek'
      },
      {
        label: 'Söğütlü',
        value: 'Söğütlü'
      },
      {
        label: 'Adapazarı',
        value: 'Adapazarı'
      },
      {
        label: 'Arifiye',
        value: 'Arifiye'
      },
      {
        label: 'Erenler',
        value: 'Erenler'
      },
      {
        label: 'Serdivan',
        value: 'Serdivan'
      }
    ]
  },
  {
    label: 'Samsun',
    value: 'Samsun',
    districts: [
      {
        label: 'Alaçam',
        value: 'Alaçam'
      },
      {
        label: 'Bafra',
        value: 'Bafra'
      },
      {
        label: 'Çarşamba',
        value: 'Çarşamba'
      },
      {
        label: 'Havza',
        value: 'Havza'
      },
      {
        label: 'Kavak',
        value: 'Kavak'
      },
      {
        label: 'Ladik',
        value: 'Ladik'
      },
      {
        label: 'Terme',
        value: 'Terme'
      },
      {
        label: 'Vezirköprü',
        value: 'Vezirköprü'
      },
      {
        label: 'Asarcık',
        value: 'Asarcık'
      },
      {
        label: 'Ondokuzmayıs',
        value: 'Ondokuzmayıs'
      },
      {
        label: 'Salıpazarı',
        value: 'Salıpazarı'
      },
      {
        label: 'Tekkeköy',
        value: 'Tekkeköy'
      },
      {
        label: 'Ayvacık',
        value: 'Ayvacık'
      },
      {
        label: 'Yakakent',
        value: 'Yakakent'
      },
      {
        label: 'Atakum',
        value: 'Atakum'
      },
      {
        label: 'Canik',
        value: 'Canik'
      },
      {
        label: 'İlkadım',
        value: 'İlkadım'
      }
    ]
  },
  {
    label: 'Siirt',
    value: 'Siirt',
    districts: [
      {
        label: 'Baykan',
        value: 'Baykan'
      },
      {
        label: 'Eruh',
        value: 'Eruh'
      },
      {
        label: 'Kurtalan',
        value: 'Kurtalan'
      },
      {
        label: 'Pervari',
        value: 'Pervari'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Şirvan',
        value: 'Şirvan'
      },
      {
        label: 'Tlabello',
        value: 'Tlabello'
      }
    ]
  },
  {
    label: 'Sinop',
    value: 'Sinop',
    districts: [
      {
        label: 'Ayancık',
        value: 'Ayancık'
      },
      {
        label: 'Boyabat',
        value: 'Boyabat'
      },
      {
        label: 'Durağan',
        value: 'Durağan'
      },
      {
        label: 'Erfelek',
        value: 'Erfelek'
      },
      {
        label: 'Gerze',
        value: 'Gerze'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Türkeli',
        value: 'Türkeli'
      },
      {
        label: 'Dikmen',
        value: 'Dikmen'
      },
      {
        label: 'Saraydüzü',
        value: 'Saraydüzü'
      }
    ]
  },
  {
    label: 'Sivas',
    value: 'Sivas',
    districts: [
      {
        label: 'Divriği',
        value: 'Divriği'
      },
      {
        label: 'Gemerek',
        value: 'Gemerek'
      },
      {
        label: 'Gürün',
        value: 'Gürün'
      },
      {
        label: 'Hafik',
        value: 'Hafik'
      },
      {
        label: 'İmranlı',
        value: 'İmranlı'
      },
      {
        label: 'Kangal',
        value: 'Kangal'
      },
      {
        label: 'Koyulhisar',
        value: 'Koyulhisar'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Suşehri',
        value: 'Suşehri'
      },
      {
        label: 'Şarkışla',
        value: 'Şarkışla'
      },
      {
        label: 'Yıldızeli',
        value: 'Yıldızeli'
      },
      {
        label: 'Zara',
        value: 'Zara'
      },
      {
        label: 'Akıncılar',
        value: 'Akıncılar'
      },
      {
        label: 'Altınyayla',
        value: 'Altınyayla'
      },
      {
        label: 'Doğanşar',
        value: 'Doğanşar'
      },
      {
        label: 'Gölova',
        value: 'Gölova'
      },
      {
        label: 'Ulaş',
        value: 'Ulaş'
      }
    ]
  },
  {
    label: 'Tekirdağ',
    value: 'Tekirdağ',
    districts: [
      {
        label: 'Çerkezköy',
        value: 'Çerkezköy'
      },
      {
        label: 'Çorlu',
        value: 'Çorlu'
      },
      {
        label: 'Ergene',
        value: 'Ergene'
      },
      {
        label: 'Hayrabolu',
        value: 'Hayrabolu'
      },
      {
        label: 'Malkara',
        value: 'Malkara'
      },
      {
        label: 'Muratlı',
        value: 'Muratlı'
      },
      {
        label: 'Saray',
        value: 'Saray'
      },
      {
        label: 'Süleymanpaşa',
        value: 'Süleymanpaşa'
      },
      {
        label: 'Kapaklı',
        value: 'Kapaklı'
      },
      {
        label: 'Şarköy',
        value: 'Şarköy'
      },
      {
        label: 'Marmaraereğlisi',
        value: 'Marmaraereğlisi'
      }
    ]
  },
  {
    label: 'Tokat',
    value: 'Tokat',
    districts: [
      {
        label: 'Almus',
        value: 'Almus'
      },
      {
        label: 'Artova',
        value: 'Artova'
      },
      {
        label: 'Erbaa',
        value: 'Erbaa'
      },
      {
        label: 'Niksar',
        value: 'Niksar'
      },
      {
        label: 'Reşadiye',
        value: 'Reşadiye'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Turhal',
        value: 'Turhal'
      },
      {
        label: 'Zlabele',
        value: 'Zlabele'
      },
      {
        label: 'Pazar',
        value: 'Pazar'
      },
      {
        label: 'Yeşlabelyurt',
        value: 'Yeşlabelyurt'
      },
      {
        label: 'Başçiftlik',
        value: 'Başçiftlik'
      },
      {
        label: 'Sulusaray',
        value: 'Sulusaray'
      }
    ]
  },
  {
    label: 'Trabzon',
    value: 'Trabzon',
    districts: [
      {
        label: 'Akçaabat',
        value: 'Akçaabat'
      },
      {
        label: 'Araklı',
        value: 'Araklı'
      },
      {
        label: 'Arsin',
        value: 'Arsin'
      },
      {
        label: 'Çaykara',
        value: 'Çaykara'
      },
      {
        label: 'Maçka',
        value: 'Maçka'
      },
      {
        label: 'Of',
        value: 'Of'
      },
      {
        label: 'Ortahisar',
        value: 'Ortahisar'
      },
      {
        label: 'Sürmene',
        value: 'Sürmene'
      },
      {
        label: 'Tonya',
        value: 'Tonya'
      },
      {
        label: 'Vakfıkebir',
        value: 'Vakfıkebir'
      },
      {
        label: 'Yomra',
        value: 'Yomra'
      },
      {
        label: 'Beşikdüzü',
        value: 'Beşikdüzü'
      },
      {
        label: 'Şalpazarı',
        value: 'Şalpazarı'
      },
      {
        label: 'Çarşıbaşı',
        value: 'Çarşıbaşı'
      },
      {
        label: 'Dernekpazarı',
        value: 'Dernekpazarı'
      },
      {
        label: 'Düzköy',
        value: 'Düzköy'
      },
      {
        label: 'Hayrat',
        value: 'Hayrat'
      },
      {
        label: 'Köprübaşı',
        value: 'Köprübaşı'
      }
    ]
  },
  {
    label: 'Tunceli',
    value: 'Tunceli',
    districts: [
      {
        label: 'Çemişgezek',
        value: 'Çemişgezek'
      },
      {
        label: 'Hozat',
        value: 'Hozat'
      },
      {
        label: 'Mazgirt',
        value: 'Mazgirt'
      },
      {
        label: 'Nazımiye',
        value: 'Nazımiye'
      },
      {
        label: 'Ovacık',
        value: 'Ovacık'
      },
      {
        label: 'Pertek',
        value: 'Pertek'
      },
      {
        label: 'Pülümür',
        value: 'Pülümür'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      }
    ]
  },
  {
    label: 'Şanlıurfa',
    value: 'Şanlıurfa',
    districts: [
      {
        label: 'Akçakale',
        value: 'Akçakale'
      },
      {
        label: 'Birecik',
        value: 'Birecik'
      },
      {
        label: 'Bozova',
        value: 'Bozova'
      },
      {
        label: 'Ceylanpınar',
        value: 'Ceylanpınar'
      },
      {
        label: 'Eyyübiye',
        value: 'Eyyübiye'
      },
      {
        label: 'Halfeti',
        value: 'Halfeti'
      },
      {
        label: 'Hallabeliye',
        value: 'Hallabeliye'
      },
      {
        label: 'Hlabelvan',
        value: 'Hlabelvan'
      },
      {
        label: 'Karaköprü',
        value: 'Karaköprü'
      },
      {
        label: 'Siverek',
        value: 'Siverek'
      },
      {
        label: 'Suruç',
        value: 'Suruç'
      },
      {
        label: 'Viranşehir',
        value: 'Viranşehir'
      },
      {
        label: 'Harran',
        value: 'Harran'
      }
    ]
  },
  {
    label: 'Uşak',
    value: 'Uşak',
    districts: [
      {
        label: 'Banaz',
        value: 'Banaz'
      },
      {
        label: 'Eşme',
        value: 'Eşme'
      },
      {
        label: 'Karahallı',
        value: 'Karahallı'
      },
      {
        label: 'Sivaslı',
        value: 'Sivaslı'
      },
      {
        label: 'Ulubey',
        value: 'Ulubey'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      }
    ]
  },
  {
    label: 'Van',
    value: 'Van',
    districts: [
      {
        label: 'Başkale',
        value: 'Başkale'
      },
      {
        label: 'Çatak',
        value: 'Çatak'
      },
      {
        label: 'Erciş',
        value: 'Erciş'
      },
      {
        label: 'Gevaş',
        value: 'Gevaş'
      },
      {
        label: 'Gürpınar',
        value: 'Gürpınar'
      },
      {
        label: 'İpekyolu',
        value: 'İpekyolu'
      },
      {
        label: 'Muradiye',
        value: 'Muradiye'
      },
      {
        label: 'Özalp',
        value: 'Özalp'
      },
      {
        label: 'Tuşba',
        value: 'Tuşba'
      },
      {
        label: 'Bahçesaray',
        value: 'Bahçesaray'
      },
      {
        label: 'Çaldıran',
        value: 'Çaldıran'
      },
      {
        label: 'Edremit',
        value: 'Edremit'
      },
      {
        label: 'Saray',
        value: 'Saray'
      }
    ]
  },
  {
    label: 'Yozgat',
    value: 'Yozgat',
    districts: [
      {
        label: 'Akdağmadeni',
        value: 'Akdağmadeni'
      },
      {
        label: 'Boğazlıyan',
        value: 'Boğazlıyan'
      },
      {
        label: 'Çayıralan',
        value: 'Çayıralan'
      },
      {
        label: 'Çekerek',
        value: 'Çekerek'
      },
      {
        label: 'Sarıkaya',
        value: 'Sarıkaya'
      },
      {
        label: 'Sorgun',
        value: 'Sorgun'
      },
      {
        label: 'Şefaatli',
        value: 'Şefaatli'
      },
      {
        label: 'Yerköy',
        value: 'Yerköy'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Aydıncık',
        value: 'Aydıncık'
      },
      {
        label: 'Çandır',
        value: 'Çandır'
      },
      {
        label: 'Kadışehri',
        value: 'Kadışehri'
      },
      {
        label: 'Saraykent',
        value: 'Saraykent'
      },
      {
        label: 'Yenifakılı',
        value: 'Yenifakılı'
      }
    ]
  },
  {
    label: 'Zonguldak',
    value: 'Zonguldak',
    districts: [
      {
        label: 'Çaycuma',
        value: 'Çaycuma'
      },
      {
        label: 'Devrek',
        value: 'Devrek'
      },
      {
        label: 'Ereğli',
        value: 'Ereğli'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Alaplı',
        value: 'Alaplı'
      },
      {
        label: 'Gökçebey',
        value: 'Gökçebey'
      }
    ]
  },
  {
    label: 'Aksaray',
    value: 'Aksaray',
    districts: [
      {
        label: 'Ağaçören',
        value: 'Ağaçören'
      },
      {
        label: 'Esklabel',
        value: 'Esklabel'
      },
      {
        label: 'Gülağaç',
        value: 'Gülağaç'
      },
      {
        label: 'Güzelyurt',
        value: 'Güzelyurt'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Ortaköy',
        value: 'Ortaköy'
      },
      {
        label: 'Sarıyahşi',
        value: 'Sarıyahşi'
      }
    ]
  },
  {
    label: 'Bayburt',
    value: 'Bayburt',
    districts: [
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Aydıntepe',
        value: 'Aydıntepe'
      },
      {
        label: 'Demirözü',
        value: 'Demirözü'
      }
    ]
  },
  {
    label: 'Karaman',
    value: 'Karaman',
    districts: [
      {
        label: 'Ermenek',
        value: 'Ermenek'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Ayrancı',
        value: 'Ayrancı'
      },
      {
        label: 'Kazımkarabekir',
        value: 'Kazımkarabekir'
      },
      {
        label: 'Başyayla',
        value: 'Başyayla'
      },
      {
        label: 'Sarıvellabeler',
        value: 'Sarıvellabeler'
      }
    ]
  },
  {
    label: 'Kırıkkale',
    value: 'Kırıkkale',
    districts: [
      {
        label: 'Delice',
        value: 'Delice'
      },
      {
        label: 'Keskin',
        value: 'Keskin'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Sulakyurt',
        value: 'Sulakyurt'
      },
      {
        label: 'Bahşlabeli',
        value: 'Bahşlabeli'
      },
      {
        label: 'Balışeyh',
        value: 'Balışeyh'
      },
      {
        label: 'Çelebi',
        value: 'Çelebi'
      },
      {
        label: 'Karakeçlabeli',
        value: 'Karakeçlabeli'
      },
      {
        label: 'Yahşihan',
        value: 'Yahşihan'
      }
    ]
  },
  {
    label: 'Batman',
    value: 'Batman',
    districts: [
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Beşiri',
        value: 'Beşiri'
      },
      {
        label: 'Gercüş',
        value: 'Gercüş'
      },
      {
        label: 'Kozluk',
        value: 'Kozluk'
      },
      {
        label: 'Sason',
        value: 'Sason'
      },
      {
        label: 'Hasankeyf',
        value: 'Hasankeyf'
      }
    ]
  },
  {
    label: 'Şırnak',
    value: 'Şırnak',
    districts: [
      {
        label: 'Beytüşşebap',
        value: 'Beytüşşebap'
      },
      {
        label: 'Cizre',
        value: 'Cizre'
      },
      {
        label: 'İdlabel',
        value: 'İdlabel'
      },
      {
        label: 'Slabelopi',
        value: 'Slabelopi'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Uludere',
        value: 'Uludere'
      },
      {
        label: 'Güçlükonak',
        value: 'Güçlükonak'
      }
    ]
  },
  {
    label: 'Bartın',
    value: 'Bartın',
    districts: [
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Kurucaşlabele',
        value: 'Kurucaşlabele'
      },
      {
        label: 'Ulus',
        value: 'Ulus'
      },
      {
        label: 'Amasra',
        value: 'Amasra'
      }
    ]
  },
  {
    label: 'Ardahan',
    value: 'Ardahan',
    districts: [
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Çıldır',
        value: 'Çıldır'
      },
      {
        label: 'Göle',
        value: 'Göle'
      },
      {
        label: 'Hanak',
        value: 'Hanak'
      },
      {
        label: 'Posof',
        value: 'Posof'
      },
      {
        label: 'Damal',
        value: 'Damal'
      }
    ]
  },
  {
    label: 'Iğdır',
    value: 'Iğdır',
    districts: [
      {
        label: 'Aralık',
        value: 'Aralık'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Tuzluca',
        value: 'Tuzluca'
      },
      {
        label: 'Karakoyunlu',
        value: 'Karakoyunlu'
      }
    ]
  },
  {
    label: 'Yalova',
    value: 'Yalova',
    districts: [
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Altınova',
        value: 'Altınova'
      },
      {
        label: 'Armutlu',
        value: 'Armutlu'
      },
      {
        label: 'Çınarcık',
        value: 'Çınarcık'
      },
      {
        label: 'Çiftlikköy',
        value: 'Çiftlikköy'
      },
      {
        label: 'Termal',
        value: 'Termal'
      }
    ]
  },
  {
    label: 'Karabük',
    value: 'Karabük',
    districts: [
      {
        label: 'Eflani',
        value: 'Eflani'
      },
      {
        label: 'Eskipazar',
        value: 'Eskipazar'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Ovacık',
        value: 'Ovacık'
      },
      {
        label: 'Safranbolu',
        value: 'Safranbolu'
      },
      {
        label: 'Yenice',
        value: 'Yenice'
      }
    ]
  },
  {
    label: 'Kilis',
    value: 'Kilis',
    districts: [
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Elbeyli',
        value: 'Elbeyli'
      },
      {
        label: 'Musabeyli',
        value: 'Musabeyli'
      },
      {
        label: 'Polateli',
        value: 'Polateli'
      }
    ]
  },
  {
    label: 'Osmaniye',
    value: 'Osmaniye',
    districts: [
      {
        label: 'Bahçe',
        value: 'Bahçe'
      },
      {
        label: 'Kadirli',
        value: 'Kadirli'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Düziçi',
        value: 'Düziçi'
      },
      {
        label: 'Hasanbeyli',
        value: 'Hasanbeyli'
      },
      {
        label: 'Sumbas',
        value: 'Sumbas'
      },
      {
        label: 'Toprakkale',
        value: 'Toprakkale'
      }
    ]
  },
  {
    label: 'Düzce',
    value: 'Düzce',
    districts: [
      {
        label: 'Akçakoca',
        value: 'Akçakoca'
      },
      {
        label: 'Merkez',
        value: 'Merkez'
      },
      {
        label: 'Yığılca',
        value: 'Yığılca'
      },
      {
        label: 'Cumayeri',
        value: 'Cumayeri'
      },
      {
        label: 'Gölyaka',
        value: 'Gölyaka'
      },
      {
        label: 'Çlabelimli',
        value: 'Çlabelimli'
      },
      {
        label: 'Gümüşova',
        value: 'Gümüşova'
      },
      {
        label: 'Kaynaşlı',
        value: 'Kaynaşlı'
      }
    ]
  }
];
