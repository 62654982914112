import AppText from 'components/Typography/AppText/AppText.component';
import React, { useEffect, useState } from 'react';
import api from 'services/api';
import { timeAgo } from 'utils/date';
import { AnimatePresence, motion } from 'framer-motion';
import AppButton from 'components/AppButton/AppButton.component';
import { ArrowDropDown, HighlightOff } from '@mui/icons-material';
import Skeleton from 'react-loading-skeleton';
import { levels } from 'constants/levels';

const SurveyAnswerCard = ({ answer, allSurveys, onAnswerReply }) => {
  const survey = allSurveys.find(item => item._id === answer.surveyId);
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const alphabet = 'abcdefghijklmnopqrstuvwxyz';

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      await api.global
        .get('customer/user', answer.userId)
        .then(async res => {
          setUser({
            ...res.data.user,
            level: levels(res.data.user).find(level => level.selected)
          });
        })
        .catch(error => {
          setUser(null);
          console.log(error);
        });
      setIsLoading(false);
    };
    getData();
  }, []);

  const asterisks = name => {
    const asterisks = '*'.repeat(name.length - 1);
    return `${asterisks}`;
  };

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton width="10%" className="mt-7 " />
          <Skeleton width="20%" className="mt-1" />
          <Skeleton width="50%" className="mt-2 py-1" />
          <Skeleton width="40%" className="mt-2 py-1" />
          <Skeleton width="100%" className="mt-2 py-2" />
        </>
      ) : (
        <div className="mt-4 xl:px-6 xl:py-3 p-3 border rounded-md  border-gray-200 relative shadow-sm bg-white flex-row flex ">
          <div className="flex flex-col justify-center w-full">
            <div className="flex justify-between">
              <span className="flex flex-col">
                <span className="flex flex-row">
                  <span className="text-xs font-medium text-gray-400">
                    {timeAgo(answer?.createdAt, new Date())}
                  </span>
                </span>
                <AppText
                  className="text-gray-900 mt-1 xl:text-base text-sm flex font-semibold"
                  text={survey?.surveyName}
                />
              </span>
              <span className="flex flex-col items-center max-w-fit min-w-fit absolute top-3 right-3">
                {user ? (
                  <>
                    <span className="flex-row flex font-medium text-xs text-center">
                      Kullanıcı
                    </span>
                    <span className="inline-block align-bottom text-gray-400 text-xs text-center">
                      {user?.firstName && (
                        <>
                          {user?.firstName[0]}
                          <span className="align-sub mr-1">
                            {asterisks(user?.firstName)}
                          </span>
                        </>
                      )}
                      {user?.lastName && (
                        <>
                          {user?.lastName[0]}
                          <span className="align-sub">
                            {asterisks(user?.lastName)}
                          </span>
                        </>
                      )}
                    </span>
                    <span className="flex flex-row items-center text-gray-400 text-xs text-center">
                      <img src={user?.level.image} className="w-6 h-6 mr-2" />
                      {user?.level.title}
                    </span>
                  </>
                ) : (
                  <span className="inline-block align-bottom text-gray-400 text-xs text-center">
                    Kullanıcı bulunamadı
                  </span>
                )}
              </span>
            </div>
            <ul className="mt-2">
              {answer.answers.map((item, index) => (
                <AnimatePresence initial={false} key={index}>
                  {(index === 0 || isOpen) && (
                    <motion.li
                      key="content"
                      initial="collapsed"
                      animate="open"
                      exit="collapsed"
                      variants={{
                        open: {
                          opacity: 1,
                          height: 'auto'
                        },
                        collapsed: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{
                        duration: 0.4,
                        type: 'spring',
                        bounce: 0
                      }}
                      className={`flex flex-col`}
                    >
                      <span
                        className={`xl:text-sm text-xs font-medium ${
                          index === 0 && 'pr-24'
                        }`}
                      >
                        {`${index + 1}. ${item.question.question}`}
                      </span>

                      {item.question.questionType === 'multipleChoices' && (
                        <ul>
                          {item.question.multipleChoices.map(
                            (choice, index) => (
                              <li
                                key={index}
                                className={`font-medium xl:text-sm text-xs mt-1 ${
                                  choice._id === item.answer
                                    ? 'text-amber-400'
                                    : 'text-gray-400'
                                }`}
                              >
                                {item.isUserReply
                                  ? `${alphabet[index]}-) ${choice.choice}`
                                  : 'Kullanıcı cevap vermedi.'}
                              </li>
                            )
                          )}
                        </ul>
                      )}
                      {item.question.questionType === 'explanation' && (
                        <span className="xl:text-sm text-xs mt-1 text-gray-400 font-medium">
                          {item.isUserReply
                            ? item.answer
                            : 'Kullanıcı cevap vermedi.'}
                        </span>
                      )}
                      {item.question.questionType === 'rate' && (
                        <span className="mt-1">
                          {item.isUserReply ? (
                            [...Array(5)].map((star, index) => (
                              <span
                                key={index}
                                className={`${
                                  Number(item.answer) >= index + 1
                                    ? 'text-amber-400'
                                    : 'text-gray-300'
                                } mr-1 xl:text-lg text-base leading-5`}
                              >
                                ★
                              </span>
                            ))
                          ) : (
                            <span className="text-gray-400 font-medium xl:text-sm text-xs items-center flex">
                              Kullanıcı cevap vermedi.
                              <span className="text-red-500 ml-1">
                                <HighlightOff />
                              </span>
                            </span>
                          )}
                        </span>
                      )}
                      {isOpen && (
                        <div className="w-full h-[1px] bg-gray-100 mt-2 mb-2" />
                      )}
                    </motion.li>
                  )}
                </AnimatePresence>
              ))}
            </ul>

            <div className="flex flex-row w-full mt-3 justify-between">
              <AppButton
                className={`text-xs w-[60%] xl:!w-[70%] ${
                  !isOpen
                    ? '!bg-amber-100 !text-amber-500 focus:!ring-amber-500 hover:!bg-amber-200'
                    : 'bg-transparent border border-gray-300 text-gray-600 hover:bg-gray-200 focus:ring-gray-500'
                }`}
                isFullWidth={false}
                onClick={() => setIsOpen(!isOpen)}
              >
                {isOpen ? 'Cevapları Gizle' : 'Tüm Cevapları Göster'}
                <motion.span
                  initial={false}
                  className="ml-1"
                  animate={{ rotate: isOpen ? 180 : 360 }}
                  transition={{ duration: 0.4, type: 'just' }}
                >
                  <ArrowDropDown fontSize="small" />
                </motion.span>
              </AppButton>
              {(user || answer?.storeReply?.content) && (
                <AppButton
                  className={`w-[20%] ${
                    answer?.storeReply?.content
                      ? '!bg-gray-100 !text-gray-500 focus:!ring-gray-500 hover:!bg-gray-200'
                      : '!bg-emerald-100 !text-emerald-500 focus:!ring-emerald-500 hover:!bg-emerald-200'
                  }`}
                  onClick={() => onAnswerReply(answer)}
                  isFullWidth={false}
                >
                  {answer?.storeReply?.content ? 'Cevap Verildi' : 'Cevap Ver'}
                </AppButton>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SurveyAnswerCard;
