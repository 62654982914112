import React, { useState } from 'react';
import { FormErrorMessage } from 'components';

const AppInput = ({
  label,
  name,
  placeholder,
  register,
  error,
  isLabelActive = true,
  isRequiredSymbolActive = false,
  rules,
  type = 'text',
  pattern,
  maxLength,
  ...props
}) => {
  const [inputType, setInputType] = useState(type);

  return (
    <div className="w-full mb-5 relative">
      {isLabelActive && (
        <label
          htmlFor={name}
          className={`w-full  block mb-2 text-sm font-medium ${
            error ? 'text-red-600' : 'text-gray-900'
          }`}
        >
          {label}
          {isRequiredSymbolActive && (
            <span className="align-top text-red-600 font-bold ml-1">*</span>
          )}
        </label>
      )}
      <input
        type={inputType}
        id={name}
        maxLength={maxLength}
        className={`w-full outline-none  border text-gray-900 text-sm rounded-lg  block p-2.5  ${
          error
            ? 'border-red-500 focus:border-red-500'
            : 'border-gray-200 focus:border-gray-700'
        } ${type === 'password' && 'pr-[50px]'}`}
        placeholder={placeholder}
        pattern={pattern}
        {...register(name, rules)}
        {...props}
      />
      {type === 'password' && (
        <a
          onClick={() =>
            setInputType(prev => (prev === 'text' ? 'password' : 'text'))
          }
          className="absolute top-[42px] cursor-pointer text-xs right-2.5 font-semibold text-gray-900"
        >
          {inputType === 'text' ? 'Gizle' : 'Göster'}
        </a>
      )}
      <FormErrorMessage label={label} error={error} />
    </div>
  );
};

export default AppInput;
