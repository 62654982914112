import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import { AppHeader, AppSidebar } from 'components';
import { appMenuItems } from 'constants/appMenu';

const DashboardLayout = () => {
  const location = useLocation();
  const getUrl = location.pathname;
  let childrenPath = null;

  const page = appMenuItems.find(item => {
    if (item.path === getUrl) {
      return true;
    }
    if (item.children) {
      return (childrenPath = item.children.find(
        child => child.path === getUrl
      ));
    }
  });

  const PrivateRoute = ({ children }) => {
    const { user } = useAuth();

    if (!user) {
      return <Navigate to="/auth/login" replace={true} />;
    }

    return children;
  };

  return (
    <PrivateRoute>
      <div className="flex xl:flex-row flex-col relative xl:px-6 xl:py-12 px-4 py-2">
        <div className="flex xl:flex-col xl:w-1/5 w-full">
          <AppSidebar />
        </div>
        <div className="flex flex-col xl:w-4/5 mt-4 xl:mt-0 w-full xl:pb-0 pb-24 xl:ml-12 relative">
          <AppHeader
            pageName={childrenPath ? childrenPath?.name : page?.name}
          />
          <Outlet />
        </div>
      </div>
    </PrivateRoute>
  );
};

export default DashboardLayout;
