import React from 'react';
import { FormErrorMessage } from 'components';

const AppTextArea = ({
  label,
  name,
  placeholder,
  register,
  error,
  isLabelActive = true,
  rules,
  type = 'text',
  isRequiredSymbolActive = false,
  pattern,
  rows = 2,
  cols = 2,
  disabled = false,
  maxLength,
  ...props
}) => {
  return (
    <div className="w-full mb-5">
      {isLabelActive && (
        <label
          htmlFor={name}
          className={`w-full  block mb-2 text-sm font-medium ${
            error ? 'text-red-600' : 'text-gray-900'
          }`}
        >
          {label}
          {isRequiredSymbolActive && (
            <span className="align-top text-red-600 font-bold ml-1">*</span>
          )}
        </label>
      )}
      <textarea
        type={type}
        id={name}
        disabled={disabled}
        maxLength={maxLength}
        className={`w-full outline-none  border text-gray-900 text-sm rounded-lg  resize-none block p-2.5  ${
          error
            ? 'border-red-500 focus:border-red-500'
            : 'border-gray-200 focus:border-gray-700'
        }`}
        placeholder={placeholder}
        pattern={pattern}
        rows={rows}
        cols={cols}
        {...register(name, rules)}
        {...props}
      />
      <FormErrorMessage label={label} error={error} />
    </div>
  );
};

export default AppTextArea;
