import { AppButton, AppInput, AppModal, AppTextArea } from 'components';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import api from 'services/api';
import { dateToStringWithTime } from 'utils/date';

const SurveyReplyModal = ({ answer, onClose, isOpen }) => {
  const [isLoading, setIsLoading] = useState(false);

  //form values
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
    setValue
  } = useForm({
    defaultValues: {
      reply: answer?.storeReply?.content
    }
  });

  const onSubmit = async submitData => {
    setIsLoading(true);

    try {
      const response = await api.store.addSurveyReply(
        answer._id,
        submitData.reply
      );
      closeModal(response.data.answer);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const closeModal = data => {
    reset();
    onClose(data);
  };

  return (
    <AppModal
      open={isOpen}
      title="Kullanıcı Anket Cevap"
      handleClose={() => closeModal()}
    >
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppTextArea
          label="Cevap - (Max 330 Karakter) "
          name="reply"
          error={errors.reply}
          rules={{ required: true }}
          isRequiredSymbolActive={true}
          maxLength={330}
          disabled={!!answer?.storeReply?.content}
          rows={6}
          control={control}
          register={register}
          placeholder="Kullanıcıya bir cevap gönder - (Max 330 Karakter)"
        />

        {!answer?.storeReply?.content ? (
          <AppButton
            disabled={!!answer?.storeReply?.content}
            type="submit"
            className={`mt-5 ${
              !!answer?.storeReply?.content &&
              '!bg-gray-100 !text-gray-500 focus:!ring-gray-500 hover:!bg-gray-100 pointer-events-none'
            }`}
            isLoading={isLoading}
          >
            {'Gönder'}
          </AppButton>
        ) : (
          <p className={`w-full text-sm font-medium text-gray-500`}>
            {`${dateToStringWithTime(
              answer?.storeReply?.createdDate
            )} tarihinde cevap verildi.`}
          </p>
        )}
      </form>
    </AppModal>
  );
};

export default SurveyReplyModal;
