import {
  AppButton,
  AppCurrencyInput,
  AppFile,
  AppInput,
  AppModal,
  AppSelect,
  AppSwitch,
  AppTextArea
} from 'components';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import api from 'services/api';
import { getFileId } from 'utils/file';

const UpdateMenuProductModal = ({
  store,
  onClose,
  isOpen,
  categories,
  product
}) => {
  const [isLoading, setIsLoading] = useState(false);

  //form values
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue
  } = useForm({
    defaultValues: {
      tr: product?.tr,
      en: product?.en,
      categoryId: product.category,
      price: product.price,
      isVisible: product.isVisible,
      photo: product.photo
    }
  });

  const onSubmit = async data => {
    setIsLoading(true);
    if (data?.photo !== product?.photo) {
      if (product?.photo) {
        const fileId = getFileId(product?.photo);
        const deletedOldFile = await api.global.delete('file', fileId);
      }
      if (data?.photo) {
        const res = await api.file.upload(data?.photo);
        if (res) {
          data.photo = res?.url;
        }
      }
    }

    if (!data.price.includes(',')) {
      data.price = `${data.price},00`;
    } else {
      const decimalSeparatorIndex = data.price.indexOf(',');
      const decimals = data.price.slice(
        decimalSeparatorIndex + 1,
        data.price.length
      );
      if (decimals.length === 1) {
        data.price = `${data.price}0`;
      }
    }

    const submitData = {
      ...data,
      categoryId: data.categoryId.value,
      lastCategoryId: product.category.value,
      storeId: store._id,
      photo: data.photo,
      _id: product._id
    };

    try {
      const response = await api.store.updateMenuProduct(store._id, submitData);

      onClose(response.data.store);
    } catch (err) {
      console.log(err);
    }

    setIsLoading(false);
  };

  const closeModal = data => {
    onClose(data);
  };

  return (
    <AppModal open={isOpen} title="Ürün Ekle" handleClose={() => closeModal()}>
      <div className="w-full justify-center items-center flex flex-col">
        <AppFile
          selectedImage={getValues('photo')}
          label="Fotoğraf"
          register={register}
          name="photo"
          error={errors.photo}
          rules={{ required: false }}
          onFileSaved={url => {
            setValue('photo', url);
          }}
        />
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label="Ürün İsmi (TR)"
          isRequiredSymbolActive={true}
          name="tr.name"
          error={errors.tr?.name}
          rules={{ required: true }}
          register={register}
          autoComplete="off"
          placeholder="Ürün İsmi (TR)"
        />
        <AppInput
          label="Ürün İsmi (EN-İngilizce)"
          name="en.name"
          register={register}
          autoComplete="off"
          placeholder="Ürün İsmi (EN-İngilizce)"
        />
        <AppTextArea
          label="Ürün Açıklaması (TR)"
          name="tr.about"
          register={register}
          autoComplete="off"
          placeholder="Ürün hakkında bilgi verin"
        />
        <AppTextArea
          label="Ürün Açıklaması (EN-İngilizce)"
          name="en.about"
          register={register}
          autoComplete="off"
          placeholder="Ürün hakkında ingilizce bilgi verin "
        />
        <AppSelect
          options={categories}
          label="Kategori"
          name="categoryId"
          error={errors.categoryId}
          isRequiredSymbolActive={true}
          rules={{ required: true }}
          control={control}
          placeholder="Kategori"
        />
        <AppCurrencyInput
          label="Ürün Fiyatı"
          name="price"
          control={control}
          error={errors.price}
          isRequiredSymbolActive={true}
          rules={{ required: true }}
          placeholder="Örn: ₺19,99"
        />

        <AppSwitch
          control={control}
          name="isVisible"
          label="Ürün Görünürlüğü ?"
        />
        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          Güncelle
        </AppButton>
      </form>
    </AppModal>
  );
};

export default UpdateMenuProductModal;
