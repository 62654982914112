import { Info } from '@mui/icons-material';
import {
  AppAlert,
  AppButton,
  AppCheckBox,
  AppCurrencyInput,
  AppInput,
  AppSelect,
  AppSwitch
} from 'components';
import { priceTypes } from 'constants/campaign';
import { emailPattern } from 'constants/patterns';
import { useAuth } from 'context/AuthContext';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';
import {
  listAllMenuProducts,
  myStoresAsOption,
  setSubmitDataForCampaign
} from 'utils/campaign';

const UpdateCampaign = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [isLoading, setIsLoading] = useState(true);
  const [stores, setStores] = useState([]);
  const [storeOptions, setStoreOptions] = useState();
  const [productOptions, setProductOptions] = useState();
  const [errorAlert, setErrorAlert] = useState({ isActive: false });
  const [campaignActiveErrorAlert, setCampaignActiveErrorAlert] =
    useState(false);
  const [campaign, setCampaign] = useState();

  //form values
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    watch,
    setValue
  } = useForm({
    defaultValues: {
      title: '',
      enabledStores: stores,
      requiredSurveyCount: 0,
      price: {},
      enabledMailAdresses: [],
      isActive: false
    }
  });

  const { fields: storeFields } = useFieldArray({
    control,
    name: 'enabledStores'
  });

  const {
    fields: mailFields,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'enabledMailAdresses'
  });

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      let campaignDetail;

      try {
        const response = await api.campaign.getCampaign(id);
        campaignDetail = response.data.campaign;
        const mailFormArr = [];
        for (let i = 0; i < campaignDetail.enabledMailAdresses.length; i++) {
          const mail = campaignDetail.enabledMailAdresses[i];

          mailFormArr.push({ address: mail });
        }

        setValue('enabledMailAdresses', mailFormArr);
        setValue('isActive', campaignDetail.isActive);
        setValue('title', campaignDetail.title);
        setValue('requiredSurveyCount', campaignDetail.requiredSurveyCount);
      } catch (error) {
        console.log(error, 'getCampaignError');
      }

      try {
        const response = await api.store.myStores();
        const resStore = response.data.store;
        const storeAsOption = myStoresAsOption(resStore);

        for (let i = 0; i < resStore.length; i++) {
          const store = resStore[i];

          if (campaignDetail.enabledStores.includes(store._id)) {
            store.isEnableForCampaign = true;
          }
        }

        if (campaignDetail.price.priceType === 'product') {
          const productStore = resStore.find(
            item => item?._id === campaignDetail.price.productStoreId
          );
          const productStoreAsOption = storeAsOption.find(
            item => item?.value === productStore._id
          );
          campaignDetail.price.productStoreId = productStoreAsOption;

          for (let i = 0; i < productStore.menu.length; i++) {
            const category = productStore.menu[i];
            const product = category.categoryProductList.find(
              item => item._id === campaignDetail.price.productId
            );
            const listedProducts = listAllMenuProducts(productStore.menu);

            const productAsOption = listedProducts.find(
              item => item.value === product?._id
            );

            if (productAsOption) {
              campaignDetail.price.product = productAsOption;
              break;
            }
          }
        }

        const priceTypeAsOption = priceTypes.find(
          item => item.value === campaignDetail.price.priceType
        );

        campaignDetail.price.priceType = priceTypeAsOption;
        setValue('price', campaignDetail.price);

        setStoreOptions(storeAsOption);
        setValue('enabledStores', resStore);
        setStores(resStore);
      } catch (error) {
        console.log(error, 'myStoresError');
      }
      setCampaign(campaignDetail);
      setIsLoading(false);
    };
    getData();
    return () => {
      setIsLoading(true);
    };
  }, []);

  useEffect(() => {
    const listProductOptions = () => {
      const storeId = getValues('price.productStoreId')?.value;
      if (storeId) {
        const store = stores.find(item => item._id === storeId);

        const listedProducts = listAllMenuProducts(store.menu);

        setProductOptions(listedProducts);
      }
    };
    listProductOptions();
  }, [watch('price.productStoreId')?.value]);

  const onSubmit = async data => {
    if (!campaign.isActive) {
      //kampanya zaten aktif miydi kontrolü
      if (data.isActive) {
        const response = await api.campaign.getAllMyCampaigns();
        const resCampaigns = response.data.customerCampaigns;

        const activeCampaigns = resCampaigns.filter(item => item.isActive);

        if (activeCampaigns.length >= user.allowedCampaignNumber) {
          setCampaignActiveErrorAlert(true);
          setTimeout(() => {
            setCampaignActiveErrorAlert(false);
          }, 5000);
          return;
        }
      }
    }

    const submitData = setSubmitDataForCampaign(data);

    if (submitData.enabledStores.length <= 0) {
      setErrorAlert({
        isActive: true
      });
      return;
    }

    try {
      const response = await api.campaign.updateCampaign(id, submitData);
      navigate(`/campaign/detail?id=${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <div className="ml-auto mr-auto xl:w-1/2 w-full pb-4">
          <h2 className="text-gray-500 text-sm  xl:text-center pb-4">
            Aşağıdaki bilgileri doldurarak seçtiğiniz işletmelerinizde
            kullanabileceğiniz kampanya oluşturun. Bu kampanya müşterilerinizin
            5 saatte bir ankete katılmasıyla sizin belirlediğiniz limitler
            içerisinde gerçekleşir. Müşterini ödülü kazandığında izin verdiğiniz
            e-posta adresleri Repidas mobil uygulamasından müşterinin karekodunu
            okutarak bu ödülü onaylayabilir.
          </h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <AppInput
              label="Kampanya İsmi"
              isRequiredSymbolActive={true}
              name="title"
              error={errors.title}
              rules={{ required: true }}
              register={register}
              autoComplete="off"
              placeholder="Kampanya İsmi"
            />
            <AppSelect
              options={priceTypes}
              label="Ödül Türü"
              name="price.priceType"
              error={errors?.price?.priceType}
              isRequiredSymbolActive={true}
              rules={{ required: true }}
              control={control}
              placeholder="Ödül Türü"
            />
            {watch('price.priceType')?.value === 'discount' && (
              <AppCurrencyInput
                label="İndirim Tutarı (TL)"
                name="price.discount"
                control={control}
                error={errors.price?.discount}
                isRequiredSymbolActive={true}
                rules={{ required: true }}
                autoComplete="off"
                register={register}
                placeholder="İndirim Tutarı (TL)"
              />
            )}
            {watch('price.priceType')?.value === 'product' && (
              <>
                <AppSelect
                  options={storeOptions}
                  label="Ürünün Seçileceği İşletme"
                  name="price.productStoreId"
                  error={errors?.price?.productStoreId}
                  isRequiredSymbolActive={true}
                  rules={{ required: true }}
                  control={control}
                  placeholder="Ürünün Seçileceği İşletme"
                />
                {watch('price.productStoreId')?.value && productOptions && (
                  <AppSelect
                    options={productOptions}
                    label="Ürün"
                    name="price.product"
                    error={errors?.price?.product}
                    isRequiredSymbolActive={true}
                    rules={{ required: true }}
                    control={control}
                    placeholder="Ürün"
                  />
                )}
                {watch('price.product')?.value && (
                  <AppInput
                    label="Ödül Ürün Sayısı"
                    isRequiredSymbolActive={true}
                    name="price.productCount"
                    error={errors?.price?.productCount}
                    rules={{ required: true }}
                    register={register}
                    type="number"
                    autoComplete="off"
                    placeholder="Ödül Ürün Sayısı"
                  />
                )}
              </>
            )}
            <AppInput
              label="Anket Sayısı"
              isRequiredSymbolActive={true}
              name="requiredSurveyCount"
              error={errors.requiredSurveyCount}
              rules={{ required: true }}
              register={register}
              type="number"
              autoComplete="off"
              placeholder="Müşterileriniz kaç anket sonunda ödüle ulaşabilir ?"
            />

            <ul>
              <label
                className={`w-full  block mb-4 text-sm font-medium ${
                  errorAlert.isActive ? 'text-red-500' : 'text-gray-900'
                }`}
              >
                Kampanyaya Dahil Olan İşletmeler
                <span className="align-top text-red-600 font-bold ml-1">*</span>
              </label>

              {storeFields.map((item, index) => {
                return (
                  <AppCheckBox
                    key={item._id}
                    title={item.storeName}
                    name={`enabledStores.${index}.isEnableForCampaign`}
                    register={register}
                    autoComplete="off"
                  />
                );
              })}
            </ul>

            <ul>
              <div className="flex w-full mb-2 justify-between items-center">
                <div className="flex flex-col w-2/3">
                  <label className={` text-sm font-medium ${'text-gray-900'}`}>
                    Kampanyayı Uygulayabilecek E-posta Adresleri
                    <span className="align-top text-red-600 font-bold ml-1">
                      *
                    </span>
                  </label>
                  <span className="text-gray-500 font-medium text-xs mt-2 flex items-center">
                    <Info fontSize="small" className="mr-1 text-sm" />
                    Repidas mobil uygulamasından müşterilerinizin ödül
                    karekodunu (QR) okutabilecek e-posta adreslerini girin.
                    (Mobil uygulamadan karekod okutma kısmından okutabilecek.)
                  </span>
                </div>
                <AppButton
                  type="button"
                  isFullWidth={false}
                  onClick={() => {
                    append({
                      address: ''
                    });
                  }}
                  className="!py-1 !px-3   bg-transparent border hover:bg-gray-100 border-gray-300 text-gray-800 focus:!ring-gray-500"
                  isLoading={isLoading}
                >
                  Adres Ekle
                </AppButton>
              </div>
              {mailFields.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    className={`flex flex-col border-gray-300 relative ${
                      index > 0 && 'mt-2'
                    }`}
                  >
                    <div
                      className={`flex text-sm z-10 absolute -top-3 right-0 flex-row items-center justify-end`}
                    >
                      {index !== 0 && (
                        <AppButton
                          isFullWidth={false}
                          onClick={() => {
                            remove(index);
                          }}
                          className="bg-transparent  !text-xs focus:ring-red-500 border border-red-300 text-red-500 hover:bg-red-100"
                        >
                          {index + 1}. E-postayı sil
                        </AppButton>
                      )}
                    </div>

                    <li className="flex flex-col w-full">
                      <AppInput
                        label={`${index + 1}. E-posta Adresi`}
                        name={`enabledMailAdresses.${index}.address`}
                        error={
                          errors?.enabledMailAdresses &&
                          errors?.enabledMailAdresses[index]?.address
                        }
                        register={register}
                        rules={{ required: true, pattern: emailPattern }}
                        placeholder={`${index + 1}. E-posta`}
                        autoComplete="off"
                      />
                    </li>
                  </div>
                );
              })}
            </ul>
            <AppSwitch
              control={control}
              name="isActive"
              label="Kampanya Aktifliği"
            />
            <AppButton type="submit">Kampanyayı Güncelle</AppButton>
          </form>
          {campaignActiveErrorAlert && (
            <AppAlert
              type="error"
              title={'Kampanya Aktif Edilemez!'}
              subtitle={`Kampanyayı aktif olarak ekleyemezsiniz. ${user?.allowedCampaignNumber} adet size izin verilen aktif kampanya sayısına ulaştınız.`}
            />
          )}
        </div>
      )}
    </>
  );
};

export default UpdateCampaign;
