import {
  AddStoreModal,
  AppAlert,
  AppButton,
  AppTable,
  ScreenLoading
} from 'components';
import { useAuth } from 'context/AuthContext';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import api from 'services/api';

const StoreList = () => {
  const { user, setUser } = useAuth();
  const locationState = useLocation().state;
  const isFromLogin = locationState?.isAddModalOpen || false;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [isAddStoreModalOpen, setIsAddStoreModalOpen] = useState(isFromLogin);
  const [alertInfo, setAlertInfo] = useState({ isActive: false });

  const titles = [
    'İşletme İsmi',
    'Logo',
    'Menü Aktifliği',
    'Anket Cevap Sayısı',
    'Çalışan Cevap Sayısı',
    'QR Erişimi',
    'QR Okutma Sayısı'
  ];
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      await api.store.myStores().then(async res => {
        const resStores = res.data.store;
        for (let i = 0; i < resStores.length; i++) {
          let store = resStores[i];

          const statisticsResponse = await api.global.get(
            'statistics',
            `qrScanned/${store._id}`
          );

          store.totalQrScan = statisticsResponse.data.statistics.length || 0;
        }
        setData(resStores);
      });

      setIsLoading(false);
    };
    getData();
    return () => {
      setIsLoading(true);
    };
  }, []);

  const onCloseAddStoreModal = async data => {
    setIsAddStoreModalOpen(false);
    if (data) {
      await api.store.myStores().then(async res => {
        const resMyStores = res.data.store;
        const storeIds = [];

        for (let i = 0; i < resMyStores.length; i++) {
          const store = resMyStores[i];
          storeIds.push(store._id);

          const statisticsResponse = await api.global.get(
            'statistics',
            `qrScanned/${store._id}`
          );

          store.totalQrScan = statisticsResponse.data.statistics.length || 0;
        }

        setData(resMyStores);
        setUser({ ...user, addedStores: storeIds });
      });
      setAlertInfo({
        isActive: true,
        type: 'success',
        title: 'Oluşturuldu',
        subtitle: 'İşletme başarıyla oluşturuldu'
      });
      setTimeout(() => {
        setAlertInfo({ isActive: false });
      }, 1500);
    }
  };

  const onDelete = async id => {
    try {
      const response = await api.campaign.getAllMyCampaigns();
      const resCampaigns = response.data.customerCampaigns;

      for (let i = 0; i < resCampaigns.length; i++) {
        const campaign = resCampaigns[i];

        const index = campaign.enabledStores.findIndex(item => item === id);

        if (index > -1) {
          setAlertInfo({
            isActive: true,
            type: 'error',
            title: 'İşletme kampanya dahilinde!',
            subtitle:
              'Bu işletmeyi silebilmeniz için önce kampanyadan çıkarmanız veya kampanyayı silmeniz gerekmektedir.'
          });
          setTimeout(() => {
            setAlertInfo({ isActive: false });
          }, 10000);
        }
        return;
      }

      await api.global.delete('store', id);

      const deletedIndex = user.addedStores.findIndex(item => item === id);
      user.addedStores.splice(deletedIndex, 1);
      const storeIds = [...user.addedStores];
      setUser({ ...user, addedStores: storeIds });

      location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const openStoreModal = async () => {
    try {
      const response = await api.global.get('customer', 'me');
      setUser({
        ...user,
        allowedStoreNumber: response.data.customer.allowedStoreNumber
      });

      if (response.data.customer.allowedStoreNumber > data.length) {
        setIsAddStoreModalOpen(true);
      } else {
        setAlertInfo({
          isActive: true,
          type: 'error',
          title: 'Maksimum İşletme Sayısına Ulaşıldı',
          subtitle:
            'Sizin için izin verilen maksimum işletme sayısına ulaşıldı. Eğer daha fazla işletme eklemek istiyorsanız lütfen bizimle iletişime geçin.'
        });
        setTimeout(() => {
          setAlertInfo({ isActive: false });
        }, 5000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {isLoading ? (
        <ScreenLoading />
      ) : (
        <>
          <div className="w-full flex justify-end mb-2">
            <AppButton
              className="shadow-lg w-[160px] !text-white hover:!bg-opacity-90 hover:!bg-[#FFC907]  focus:!ring-[#FFC907]  !bg-[#FFC907]"
              isFullWidth={false}
              onClick={openStoreModal}
            >
              İşletme Ekle
            </AppButton>
          </div>
          <AppTable
            titles={titles}
            rows={data}
            type="store"
            emptyText="Hemen bir işletme ekleyerek Repidas'ın özelliklerini kullanmaya başlayın!"
            deleteConfirmSubtitle="Bu işletmeye ait kampanyalar dahil tüm bilgiler silinecektir. Bunu yapmak istediğinize emin misiniz?"
            onDelete={onDelete}
            isDetail={true}
            isDelete={true}
            hideRows="__v,_id,createdAt,customerId,qrScannedCount,answersCount,survey,customerFullName,storeWorkers,updatedAt,allSurveys,visibleSurveyId,menu,themeColor,location,storeType,socialLinks"
          />
          <AddStoreModal
            onClose={onCloseAddStoreModal}
            isOpen={isAddStoreModalOpen}
          />
        </>
      )}
      {alertInfo.isActive && (
        <AppAlert
          type={alertInfo.type}
          title={alertInfo.title}
          subtitle={alertInfo.subtitle}
        />
      )}
    </div>
  );
};

export default StoreList;
