import {
  CampaignOutlined,
  HomeOutlined,
  LocalMallOutlined,
  PersonOutline
} from '@mui/icons-material';

export const appMenuItems = [
  {
    name: 'Anasayfa',
    Icon: HomeOutlined,
    path: '/'
  },
  {
    name: 'İşletmeler',
    Icon: LocalMallOutlined,
    path: '/store',
    children: [
      {
        name: 'İşletme Detayı',
        path: '/store/detail'
      },
      {
        name: 'Qr Menü',
        path: '/store/menu/list'
      }
    ]
  },
  {
    name: 'Kampanyalar',
    Icon: CampaignOutlined,
    path: '/campaign',
    children: [
      {
        name: 'Kampanya Detayı',
        path: '/campaign/detail'
      },
      {
        name: 'Kampanya Ekle',
        path: '/campaign/add'
      },
      {
        name: 'Kampanya Güncelle',
        path: '/campaign/update'
      },
      {
        name: 'Kampanya İstatistikleri',
        path: '/campaign/statistics'
      }
    ]
  },
  {
    name: 'Profil',
    Icon: PersonOutline,
    path: '/my-profile'
  }
];
