import React, { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { checkValue, getValue } from 'utils/table';
import { AppButton, AppConfirm } from 'components';
import { motion } from 'framer-motion';
import { ArrowDownward } from '@mui/icons-material';

const AppTable = ({
  titles,
  rows,
  isUpdate = false,
  isDelete = false,
  isDetail = false,
  onDelete,
  draggable = false,
  handleDragEnter,
  handleDragStart,
  draggIndex,
  draggingEnd,
  tableButtons,
  page = 0,
  pageItems = 10,
  update,
  deleteConfirmSubtitle,
  type,
  isSearchEnabled = false,
  hideRows = '',
  objectKeys = [],
  loadMore,
  isLoadingMore,
  isLoadMoreActive = false,
  emptyText
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [onDeleteItemId, setOnDeleteItemId] = useState();
  const emptyDataText = emptyText || 'Herhangi bir veri bulunamadı.';

  const onCloseConfirm = () => {
    setIsConfirmOpen(false);
  };

  return (
    <>
      <div
        className={`overflow-x-auto bg-white relative shadow-md sm:rounded-lg border ${
          draggIndex ? 'border-slate-200 border-2' : 'border-[#f1f1f4]'
        }`}
      >
        <div className={`flex flex-row w-full mt-2 px-6`}>
          {tableButtons && (
            <div className="flex flex-row right-6 py-2.5 w-full ">
              {tableButtons}
            </div>
          )}
        </div>
        {rows && rows.length > 0 ? (
          <>
            <table
              className={`w-full text-sm text-left table text-gray-800  ${
                (isSearchEnabled || tableButtons) && 'mt-2'
              }`}
            >
              <thead className="text-xs border-b border-dashed text-center">
                <tr>
                  <th scope="col" className="py-3 px-6">
                    #
                  </th>
                  {titles.map((title, index) => (
                    <th
                      scope="col"
                      className="py-3 px-3 font-semibold"
                      key={`title${index}`}
                    >
                      {title.toLocaleUpperCase('tr')}
                    </th>
                  ))}
                  {isDetail && (
                    <th scope="col" className="py-3 px-3 font-semibold">
                      DETAY
                    </th>
                  )}
                  {isUpdate && (
                    <th scope="col" className="py-3 px-3 font-semibold">
                      DÜZENLE
                    </th>
                  )}
                  {isDelete && (
                    <th scope="col" className="py-3 px-3 font-semibold">
                      SİL
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => (
                  <tr
                    className={`border-dashed  text-center ${
                      index + 1 !== rows.length && 'border-b'
                    } ${
                      draggable && draggIndex === index
                        ? 'bg-amber-100 '
                        : 'bg-white'
                    }`}
                    key={`tr${index}`}
                    draggable={draggable}
                    onDragStart={e => draggable && handleDragStart(e, index)}
                    onDragOver={e => {
                      draggable && e.preventDefault();
                    }}
                    onDrop={draggable ? draggingEnd : null}
                    onDragEnter={e => draggable && handleDragEnter(e, index)}
                  >
                    <td
                      className="px-3 py-4 text-gray-800"
                      key={`index${index}`}
                    >
                      {index + 1 + page * pageItems}
                    </td>
                    {Object.keys(row).map(
                      (key, index) =>
                        checkValue(key, hideRows) && (
                          <td
                            className="py-3 px-3 text-gray-500"
                            key={`row${index}`}
                          >
                            {type === 'menuCategory' &&
                            key === 'categoryPhoto' ? (
                              <div className="flex h-10 items-center justify-center">
                                <img
                                  src={row[key]}
                                  className={`h-10 w-auto rounded-md object-contain`}
                                />
                              </div>
                            ) : (
                              getValue(row[key], key === 'logo', objectKeys)
                            )}
                          </td>
                        )
                    )}
                    {isDetail && (
                      <td className="py-3 px-3">
                        <Link
                          to={`/${type}/detail?id=${row._id}`}
                          className="py-2 text-xs flex items-center justify-center font-medium text-center text-white bg-orange-500 transition-all focus:ring-4 focus:outline-none focus:ring-orange-300 rounded-md hover:bg-orange-400 cursor-pointer"
                        >
                          Detay
                        </Link>
                      </td>
                    )}
                    {isUpdate && (
                      <td className="py-3 px-3">
                        <button
                          onClick={() => update(row._id)}
                          className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-500 rounded-md hover:bg-blue-400  focus:ring-4 focus:outline-none focus:ring-blue-300 cursor-pointer"
                        >
                          Düzenle
                        </button>
                      </td>
                    )}
                    {isDelete && (
                      <td className="py-3 px-3">
                        <button
                          onClick={() => {
                            setOnDeleteItemId(row._id);
                            setIsConfirmOpen(true);
                          }}
                          className="px-3 py-2 text-xs font-medium text-center text-white bg-red-500 rounded-md hover:bg-red-400  focus:ring-4 focus:outline-none focus:ring-red-300 cursor-pointer"
                        >
                          Sil
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            {isLoadMoreActive && (
              <div className="pt-2 mb-4 h-[40px] flex justify-center">
                {isLoadingMore ? (
                  <div className=" bg-white grid gap-4 grid-flow-col justify-center items-center ">
                    <motion.div
                      className="w-3 h-3 bg-[#FFC907]"
                      animate={{
                        scale: [1, 2, 2, 1, 1],
                        rotate: [0, 0, 270, 270, 0],
                        borderRadius: ['20%', '20%', '50%', '50%', '20%']
                      }}
                      transition={{ duration: 2, repeat: Infinity }}
                    />
                    <motion.div
                      className="w-3 h-3 bg-[#FFC907]"
                      animate={{
                        scale: [1, 2, 2, 1, 1],
                        rotate: [0, 0, 270, 270, 0],
                        borderRadius: ['20%', '20%', '50%', '50%', '20%']
                      }}
                      transition={{ duration: 2, repeat: Infinity }}
                    />
                    <motion.div
                      className="w-3 h-3 bg-[#FFC907]"
                      animate={{
                        scale: [1, 2, 2, 1, 1],
                        rotate: [0, 0, 270, 270, 0],
                        borderRadius: ['20%', '20%', '50%', '50%', '20%']
                      }}
                      transition={{ duration: 2, repeat: Infinity }}
                    />
                  </div>
                ) : (
                  <div>
                    <button
                      className="rounded-[4px] border-gray-300 border text-gray-900 px-4 py-2 text-sm hover:bg-gray-100 focus:ring-2 focus:ring-gray-900 transition-all"
                      onClick={loadMore}
                    >
                      Daha fazla göster
                      <ArrowDownward className="ml-2" fontSize="small" />
                    </button>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <div className="text-center px-5 py-5 text-sm font-medium text-gray-500">
            {emptyDataText}
          </div>
        )}
      </div>

      <AppConfirm isOpen={isConfirmOpen} handleClose={onCloseConfirm}>
        <div className="flex flex-col mt-1">
          <span className="text-sm font-medium text-gray-900">
            {deleteConfirmSubtitle ||
              'Bu bilgiler geri alınamaz. Bu işlemi yapmak istediğinize emin misiniz?'}
          </span>
          <div className="flex flex-row mt-3">
            <AppButton
              onClick={() => setIsConfirmOpen(false)}
              className="mr-3 !bg-white !text-gray-500 focus:!ring-gray-500 hover:!bg-gray-200 border border-gray-500"
            >
              İptal Et
            </AppButton>
            <AppButton
              onClick={() => {
                setIsConfirmOpen(false);
                onDelete(onDeleteItemId);
              }}
              className="ml-3 !bg-white !text-red-500 focus:!ring-red-500 hover:!bg-red-300 border border-red-500"
            >
              Onayla
            </AppButton>
          </div>
        </div>
      </AppConfirm>
    </>
  );
};

export default AppTable;
