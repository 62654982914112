import { Apps, FormatListBulleted } from '@mui/icons-material';
import {
  AppButton,
  AppTabBar,
  MenuCategoryListTab,
  MenuProductListTab,
  ScreenLoading
} from 'components';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import api from 'services/api';

const StoreMenuList = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [isLoading, setIsLoading] = useState(true);
  const [store, setStore] = useState();
  const tabItems = [
    {
      name: 'Ürünler',
      Icon: Apps,
      Component: MenuProductListTab,
      props: { store: store, setStore: setStore }
    },
    {
      name: 'Kategoriler',
      Icon: FormatListBulleted,
      Component: MenuCategoryListTab,
      props: { store: store, setStore: setStore }
    }
  ];
  const [selectedTab, setSelectedTab] = useState(tabItems[0]);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);

      const response = await api.global.get('store', id);

      const resStore = response.data.store;

      setStore(resStore);
      tabItems[0].props.store = resStore;
      tabItems[1].props.store = resStore;

      setIsLoading(false);
    };

    getData();
  }, []);

  return (
    <div className="w-full flex flex-col pb-6 relative">
      {isLoading ? (
        <ScreenLoading />
      ) : (
        <div>
          <h4 className="text-gray-900 text-xl font-medium tracking-tight mb-4">
            {store.storeName}
          </h4>
          <div className="w-full flex justify-between items-center">
            <AppTabBar items={tabItems} setSelectedTab={setSelectedTab} />
            <AppButton
              onClick={() =>
                window.open(
                  `https://www.repidas.com/store/${store._id}`,
                  '_blank'
                )
              }
              isFullWidth={false}
            >
              Menüyü Görüntüle
            </AppButton>
          </div>
          <div className="mt-6">
            <selectedTab.Component {...selectedTab.props} />
          </div>
        </div>
      )}
    </div>
  );
};

export default StoreMenuList;
