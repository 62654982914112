const PREFIX = 'campaign';

export const campaignApi = api => ({
  getAllMyCampaigns() {
    return api.get(`/${PREFIX}/customer/getAll`);
  },
  addCampaign(data) {
    return api.post(`/${PREFIX}/add`, { campaignData: data });
  },
  getCampaign(id) {
    return api.get(`/${PREFIX}/get/${id}`);
  },
  updateCampaign(id, data) {
    return api.put(`/${PREFIX}/update/${id}`, { campaignData: data });
  },
  deleteCampaign(id) {
    return api.delete(`/${PREFIX}/delete/${id}`);
  },
  getListedCampaignAttendances(campaignId, page, storeId) {
    if (storeId) {
      return api.get(
        `/${PREFIX}/get/store/attendances/${campaignId}/${storeId}/${page}`
      );
    }
    return api.get(`/${PREFIX}/get/listed/attendances/${campaignId}/${page}`);
  },
  getAllCampaignAttendances(campaignId) {
    return api.get(`/${PREFIX}/getAll/attendances/${campaignId}`);
  },
  getRewardedAttendances(campaignId) {
    return api.get(`${PREFIX}/get/rewarded/attendances/${campaignId}`);
  }
});
