import React, { useEffect, useState } from 'react';
import {
  AddMenuProductModal,
  AppAlert,
  AppButton,
  AppPagination,
  AppSelect,
  AppTable,
  SortMenuProductModal,
  UpdateMenuProductModal
} from 'components';
import api from 'services/api';
import { convertMenuData } from 'utils/menu';
import { splitTableValues } from 'utils/table';
import { useForm } from 'react-hook-form';
import { getFileId } from 'utils/file';

const MenuProductListTab = ({ store, setStore }) => {
  const titles = [
    'Fotoğraf',
    'Kategori',
    'Fiyat (₺)',
    'Görünürlük Durumu',
    'İsim',
    'Açıklama'
  ];

  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [tableProducts, setTableProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [alertInfo, setAlertInfo] = useState({ isActive: false });

  //modals
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
  const [isSortProductModalOpen, setIsSortProductOpenModal] = useState(false);
  const [isUpdateProductModalOpen, setIsUpdateProductModalOpen] =
    useState(false);

  //form values
  const { control, watch, getValues } = useForm({
    defaultValues: {
      selectedCategoryValue: { label: 'Tümü', value: 'all' }
    }
  });

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);

      const { convertedCategories, convertedProducts } = convertMenuData(store);
      const totalPageResult = Math.floor((convertedProducts.length - 1) / 10);

      setTotalPage(totalPageResult !== 0 ? totalPageResult + 1 : 1);

      setAllCategories([
        { label: 'Tümü', value: 'all' },
        ...convertedCategories
      ]);
      setCategories(convertedCategories);
      setProducts(convertedProducts);
      setTableProducts(splitTableValues(convertedProducts, page, 'categoryId'));
      setIsLoading(false);
    };

    getData();
  }, []);

  useEffect(() => {
    const selectedCategoryValue = getValues('selectedCategoryValue');
    if (selectedCategoryValue.value === 'all') {
      setTableProducts(splitTableValues(products, 0, 'categoryId'));
      const totalPageResult = Math.floor((products.length - 1) / 10);
      setPage(0);
      setTotalPage(totalPageResult !== 0 ? totalPageResult + 1 : 1);
    } else {
      const sortedValues = products.filter(
        item => item.category.value === selectedCategoryValue.value
      );
      const totalPageResult = Math.floor((sortedValues.length - 1) / 10);
      setTotalPage(totalPageResult !== 0 ? totalPageResult + 1 : 1);
      setPage(0);
      setTableProducts(splitTableValues(sortedValues, 0, 'categoryId'));
    }
  }, [watch('selectedCategoryValue')]);

  const updatedTableProducts = updatedProducts => {
    const selectedCategoryValue = getValues('selectedCategoryValue');
    setProducts(updatedProducts);
    if (selectedCategoryValue.value === 'all') {
      setTableProducts(splitTableValues(updatedProducts, page, 'categoryId'));
      const totalPageResult = Math.floor((updatedProducts.length - 1) / 10);
      setTotalPage(totalPageResult !== 0 ? totalPageResult + 1 : 1);
    } else {
      const sortedValues = updatedProducts.filter(
        item => item.category.value === selectedCategoryValue.value
      );
      const totalPageResult = Math.floor((sortedValues.length - 1) / 10);
      setTotalPage(totalPageResult !== 0 ? totalPageResult + 1 : 1);
      setTableProducts(splitTableValues(sortedValues, page, 'categoryId'));
    }
  };

  const deleteProduct = async productId => {
    const product = products.find(item => item._id === productId);

    if (product?.isUsedForCampaign) {
      setAlertInfo({
        isActive: true,
        type: 'error',
        title: 'Başarısız!',
        subtitle: 'Bu ürün bir kampanyada kullanıldığı için silemezsiniz.'
      });
      setTimeout(() => {
        setAlertInfo({ isActive: false });
      }, 3000);
      return;
    }

    setIsLoading(true);
    try {
      const response = await api.store.deleteMenuProduct(
        store._id,
        product.category.value,
        productId
      );
      if (product?.photo) {
        const fileId = getFileId(product?.photo);
        const deletedOldFile = await api.global.delete('file', fileId);
      }

      const { convertedCategories, convertedProducts } = convertMenuData(
        response.data.store
      );
      updatedTableProducts(convertedProducts);
      setIsLoading(false);
      setAlertInfo({
        isActive: true,
        type: 'success',
        title: 'Silindi',
        subtitle: 'Ürün başarıyla silindi.'
      });
      setTimeout(() => {
        setAlertInfo({ isActive: false });
      }, 1500);
    } catch (err) {
      console.log(err);
    }
  };

  const openUpdateProductModal = productId => {
    const product = products.find(item => item._id === productId);
    setSelectedProduct(product);
    setIsUpdateProductModalOpen(true);
  };

  const onCloseAddProductModal = data => {
    if (data?._id) {
      setStore(data);
      const { convertedCategories, convertedProducts } = convertMenuData(data);
      updatedTableProducts(convertedProducts);
      setAlertInfo({
        isActive: true,
        type: 'success',
        title: 'Eklendi',
        subtitle: 'Ürün başarıyla eklendi.'
      });
      setTimeout(() => {
        setAlertInfo({ isActive: false });
      }, 1500);
    }
    setIsAddProductModalOpen(false);
  };

  const onCloseUpdateProductModal = data => {
    if (data?._id) {
      setStore(data);
      const { convertedCategories, convertedProducts } = convertMenuData(data);
      updatedTableProducts(convertedProducts);
      setAlertInfo({
        isActive: true,
        type: 'success',
        title: 'Güncellendi',
        subtitle: 'Ürün başarıyla güncellendi.'
      });
      setTimeout(() => {
        setAlertInfo({ isActive: false });
      }, 1500);
    }
    setIsUpdateProductModalOpen(false);
  };

  const onSort = async productList => {
    for (let i = 0; i < productList.length; i++) {
      const product = productList[i];
      product.categoryId = product.category.value;
      delete product.category;
    }
    const response = await api.store.sortMenuProductList(
      store._id,
      productList[0].categoryId,
      productList
    );
    setStore(response.data.store);
    const { convertedCategories, convertedProducts } = convertMenuData(
      response.data.store
    );
    updatedTableProducts(convertedProducts);
    setIsSortProductOpenModal(false);
  };

  const onPageChange = targetPage => {
    setPage(targetPage);
    const selectedCategoryValue = getValues('selectedCategoryValue');
    if (selectedCategoryValue.value === 'all') {
      setTableProducts(splitTableValues(products, targetPage, 'categoryId'));
    } else {
      const sortedValues = products.filter(
        item => item.category.value === selectedCategoryValue.value
      );
      setTableProducts(
        splitTableValues(sortedValues, targetPage, 'categoryId')
      );
    }
  };

  const ProductTableButtons = () => (
    <div className="flex justify-between w-full">
      <div className="xl:w-[240px] w-[180px]">
        <AppSelect
          options={allCategories}
          label="Kategori Seç"
          name="selectedCategoryValue"
          placeholder="Kategori Seç"
          control={control}
        />
      </div>
      <div className="flex xl:flex-row flex-col items-center">
        <AppButton
          className="xl:mr-4 xl:mb-0 xl:w-auto w-28 mb-2 flex items-center !h-fit  justify-center bg-neutral-100 text-neutral-500 hover:bg-neutral-200 focus:ring-2 focus:ring-neutral-500"
          isFullWidth={false}
          onClick={() => setIsSortProductOpenModal(true)}
        >
          Ürün Sıralama
        </AppButton>

        {allCategories.length > 1 && (
          <AppButton
            className="!h-fit w-28 xl:w-auto"
            isFullWidth={false}
            onClick={() => setIsAddProductModalOpen(true)}
          >
            Ürün Ekle
          </AppButton>
        )}
      </div>
    </div>
  );

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <>
          <AppTable
            titles={titles}
            rows={tableProducts}
            isUpdate={true}
            page={page}
            onDelete={deleteProduct}
            update={openUpdateProductModal}
            tableButtons={<ProductTableButtons />}
            isDelete={true}
            hideRows="_id,category,tr,en,isUsedForCampaign"
            emptyText={
              allCategories.length > 1
                ? 'Herhangi bir ürün bulunamadı.'
                : 'Henüz bir kategori eklemediniz. Ürün ekleyebilmek için bir kategori ekleyin.'
            }
          />
          <AppPagination
            onChange={onPageChange}
            page={page}
            totalPage={totalPage}
          />
          {isAddProductModalOpen && (
            <AddMenuProductModal
              store={store}
              categories={categories}
              onClose={onCloseAddProductModal}
              isOpen={isAddProductModalOpen}
            />
          )}
          {isUpdateProductModalOpen && (
            <UpdateMenuProductModal
              product={selectedProduct}
              store={store}
              categories={categories}
              onClose={onCloseUpdateProductModal}
              isOpen={isUpdateProductModalOpen}
            />
          )}
          {isSortProductModalOpen && (
            <SortMenuProductModal
              data={products}
              categories={categories}
              onSave={onSort}
              onClose={() => setIsSortProductOpenModal(false)}
              isOpen={isSortProductModalOpen}
            />
          )}
        </>
      )}
      {alertInfo.isActive && (
        <AppAlert
          type={alertInfo.type}
          title={alertInfo.title}
          subtitle={alertInfo.subtitle}
        />
      )}
    </>
  );
};

export default MenuProductListTab;
