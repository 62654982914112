import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import api from 'services/api';
import { getAttendanceStats } from 'utils/campaign';

const CampaignStatistics = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [campaign, setCampaign] = useState();
  const [totalCollectionStats, setTotalCollectionStats] = useState();
  const [storeStats, setStoreStats] = useState();

  const totalCollectionStatValues = [
    {
      label: 'Toplam Katılım',
      property: 'allAttendanceCount',
      bgColor: 'bg-violet-600',
      textColor: 'text-violet-600'
    },
    {
      label: 'Aktif Durumdaki Katılım',
      property: 'isActiveCount',
      bgColor: 'bg-teal-500',
      textColor: 'text-teal-500'
    },
    {
      label: 'Pasif Durumdaki Katılım',
      property: 'finishedCount',
      bgColor: 'bg-red-500',
      textColor: 'text-red-500'
    },
    {
      label: 'Katılan Farklı Kullanıcı',
      property: 'userCount',
      bgColor: 'bg-amber-500',
      textColor: 'text-amber-500'
    },
    {
      label: 'Verilen Toplam Ödül',
      property: 'rewardCount',
      bgColor: 'bg-blue-500',
      textColor: 'text-blue-500'
    }
  ];
  const storeCollectionStatValues = [
    {
      label: 'Toplam Katılım',
      property: 'allAttendanceCount',
      bgColor: 'bg-violet-600',
      textColor: 'text-violet-600'
    },
    {
      label: 'Aktif Durumdaki Katılım',
      property: 'isActiveCount',
      bgColor: 'bg-teal-500',
      textColor: 'text-teal-500'
    },
    {
      label: 'Pasif Durumdaki Katılım',
      property: 'finishedCount',
      bgColor: 'bg-red-500',
      textColor: 'text-red-500'
    },
    {
      label: 'Verilen Toplam Ödül',
      property: 'rewardCount',
      bgColor: 'bg-blue-500',
      textColor: 'text-blue-500'
    }
  ];

  useEffect(() => {
    const getCampaignData = async () => {
      setIsInitialLoading(true);
      try {
        const response = await api.campaign.getCampaign(id);
        const resCampaign = response.data.campaign;

        setCampaign(resCampaign);

        const resStores = [];

        for (let i = 0; i < resCampaign.enabledStores.length; i++) {
          const storeId = resCampaign.enabledStores[i];
          const response = await api.global.get('store', storeId);

          resStores.push(response.data.store);
        }

        const attendanceResponse = await api.campaign.getAllCampaignAttendances(
          id
        );

        const rewardedAttendanceResponse =
          await api.campaign.getRewardedAttendances(id);
        const rewardedAttendances = rewardedAttendanceResponse.data.attendances;

        const stats = getAttendanceStats(
          attendanceResponse.data.attendances,
          resCampaign,
          resStores,
          rewardedAttendances
        );

        const shiftedStat = stats.shift();

        console.log(stats);

        setTotalCollectionStats(shiftedStat);
        setStoreStats(stats);
      } catch (error) {
        console.log(error, 'campaign error');
      }

      setIsInitialLoading(false);
    };
    getCampaignData();
  }, [id]);

  return (
    <>
      {isInitialLoading ? (
        <></>
      ) : (
        <div>
          <h2 className="text-xl font-semibold text-gray-900 tracking-tighter">
            {campaign.title}
          </h2>
          <div className="mt-4 flex flex-col flex-shrink border border-gray-200 rounded-md shadow-md bg-white py-6 px-8">
            <div className="flex flex-row justify-between items-center pb-2 border-b border-gray-100">
              <span className="text-xl font-medium text-gray-800 tracking-tight">
                {totalCollectionStats.storeName}
              </span>
            </div>

            {totalCollectionStatValues.map((value, i) => (
              <div
                key={i}
                className="flex flex-row justify-between mt-4 border-b border-gray-100 pb-1"
              >
                <span className="flex flex-row items-center">
                  <span className={`w-1 h-1 rounded-full ${value.bgColor} `} />
                  <span className="text-gray-900 font-medium text-sm ml-2">
                    {value.label}
                  </span>
                </span>
                <span
                  className={` ${value.textColor} tracking-tight font-medium text-sm`}
                >
                  {totalCollectionStats[value.property]}
                </span>
              </div>
            ))}
          </div>
          <div className="mt-8 grid grid-cols-1 xl:grid-cols-3 gap-8">
            {storeStats.map((stat, index) => (
              <div
                className="flex flex-col flex-shrink border border-gray-200 rounded-md shadow-md bg-white py-6 px-8"
                key={index}
              >
                <div className="flex flex-row justify-between items-center pb-2 border-b border-gray-100">
                  <span className="text-xl font-medium text-gray-800 tracking-tight">
                    {stat.storeName}
                  </span>
                  {stat.logo && (
                    <img
                      src={stat.logo}
                      className="h-8 w-auto object-contain"
                    />
                  )}
                </div>
                {storeCollectionStatValues.map((value, i) => (
                  <div
                    key={i}
                    className="flex flex-row justify-between mt-4 border-b border-gray-100 pb-1"
                  >
                    <span className="flex flex-row items-center">
                      <span
                        className={`w-1 h-1 rounded-full ${value.bgColor} `}
                      />
                      <span className="text-gray-900 font-medium text-sm ml-2">
                        {value.label}
                      </span>
                    </span>
                    <span
                      className={` ${value.textColor} tracking-tight font-medium text-sm`}
                    >
                      {stat[value.property]}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default CampaignStatistics;
