import { Dialog } from '@mui/material';
import React from 'react';

const AppConfirm = ({ isOpen, handleClose, children }) => {
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <div className="p-4 flex flex-col max-w-[400px] ">
        <span className="text-red-500 text-xl font-medium text-center">
          Emin misiniz ?
        </span>
        {children}
      </div>
    </Dialog>
  );
};

export default AppConfirm;
