import React, { useState } from 'react';
import { DoneAll, HighlightOff } from '@mui/icons-material';
import { AppButton, AppInput } from 'components';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import api from 'services/api';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const params = useParams();
  const { id, token } = params;

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError
  } = useForm();

  const onSubmit = async submitData => {
    setIsLoading(true);
    if (submitData.password === submitData.passwordConfirm) {
      setIsLoading(true);
      try {
        await api.auth.resetPassword(id, token, submitData.password);
        setIsSuccess(true);
        setTimeout(() => {
          navigate('/auth/login');
        }, 1200);
      } catch (err) {
        setIsError(true);
      }

      setIsLoading(false);
    } else {
      setError('passwordConfirm', {
        type: 'passwordNotEqual'
      });
    }
    setIsLoading(false);
  };

  return (
    <main className="p-10 border max-w-[720px] border-neutral-100 bg-neutral-50 rounded-md">
      <h1 className="text-gray-900 font-bold text-2xl">Şifremi Yenile</h1>
      <p className="mt-2 text-gray-500 text-sm">
        Yeni şifrenizi girerek şifrenizi değiştirebilirsiniz.
      </p>
      <form className="w-full mt-5" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label="Yeni Şifre"
          name="password"
          type="password"
          error={errors.password}
          rules={{ required: true }}
          register={register}
          placeholder="Yeni şifreni gir"
          autoComplete="off"
        />
        <AppInput
          label="Yeni Şifreni Doğrula"
          name="passwordConfirm"
          type="password"
          error={errors.passwordConfirm}
          rules={{ required: true }}
          register={register}
          placeholder="Yeni şifreni doğrula"
          autoComplete="off"
        />
        {isSuccess && (
          <div className="mb-4 bg-emerald-200 p-3 rounded-md">
            <p className="text-emerald-700 text-xs text-center">
              <DoneAll fontSize="small" className="mr-2" />
              Şifreniz başarıyla değiştirilmiştir. Giriş ekranına
              yönlendiriliyorsunuz.
            </p>
          </div>
        )}
        {isError && (
          <div className="mb-4 bg-red-200 p-3 rounded-md">
            <p className="text-red-700 text-xs text-center">
              <HighlightOff fontSize="small" className="mr-2" />
              Şifreniz değiştirilemedi. Değiştirme linkinin süresi dolmuş
              olabilir. Lütfen yeni link alıp tekrar deneyin.
            </p>
          </div>
        )}
        <AppButton
          className="!p-3 bg-gray-900 text-white hover:bg-gray-800 focus:ring-2 focus:ring-black !text-sm"
          type="submit"
          isLoading={isLoading}
        >
          Şifremi Yenile
        </AppButton>
      </form>
    </main>
  );
};

export default ResetPassword;
