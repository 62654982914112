export const calculateQrStatistics = data => {
  const today = new Date();

  const qrScanningCounts = {
    total: data.length,
    monthly: 0,
    weekly: 0,
    daily: 0
  };

  for (let i = 0; i < data.length; i++) {
    const stat = data[i];
    const statDate = new Date(stat.createdAt);
    const diffTime = Math.abs(statDate - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays <= 30) {
      qrScanningCounts.monthly++;
      if (diffDays <= 7) {
        qrScanningCounts.weekly++;
      }
      if (diffDays <= 1) {
        qrScanningCounts.daily++;
      }
    }
  }

  return qrScanningCounts;
};
