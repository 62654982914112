import { AppButton, AppInput } from 'components';
import { emailPattern } from 'constants/patterns';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isMailSubmit, setIsMailSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError
  } = useForm();

  const onSubmit = async submitData => {
    setIsLoading(true);

    try {
      await api.auth.forgotPassword(submitData.email);
      setIsMailSubmit(true);
    } catch (err) {
      if (err.response.data.reason === 'notExists') {
        setError('email', {
          type: 'notExists',
          message: err.response.data.message
        });
      }
    }

    setIsLoading(false);
  };

  return (
    <main className="p-10 max-w-[720px] border border-neutral-100 bg-neutral-50 rounded-md">
      <h1 className="text-gray-900 font-bold text-2xl">Şifremi Unuttum</h1>
      <p className="mt-2 text-gray-500 text-sm">
        Repidas&#39;a kayıtlı olduğunuz e-postanızı girerek şifrenizi
        yenileyebilirsiniz.
      </p>
      <form className="w-full mt-5" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label="E-posta"
          name="email"
          error={errors.email}
          rules={{ required: true, pattern: emailPattern }}
          register={register}
          placeholder="E-posta"
        />
        {isMailSubmit && (
          <div className="mb-4 bg-emerald-200 p-3 rounded-md flex flex-row items-center">
            <p className="text-emerald-700 text-xs">
              Şifre yenileme linki e-postanıza gönderilmiştir. Lütfen
              e-postanızı kontrol edin ve linke tıklayarak şifrenizi değiştirin.
            </p>
            <AppButton
              className="ml-4 w-40 bg-white text-gray-900 border border-emerald-900 hover:bg-gray-200 focus:ring-2 focus:!ring-black"
              type="button"
              onClick={() => navigate('/auth/login')}
              isFullWidth={false}
            >
              Giriş Yap
            </AppButton>
          </div>
        )}
        <AppButton
          className="!p-3 bg-gray-900 text-white hover:bg-gray-800 focus:ring-2 focus:!ring-black !text-sm"
          type="submit"
          isLoading={isLoading}
        >
          Şifremi Yenile
        </AppButton>
      </form>
    </main>
  );
};

export default ForgotPassword;
