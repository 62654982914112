import React from 'react';

const AppHeader = ({ pageName }) => {
  return (
    <div className="flex  items-center xl:pb-5 pb-3 xl:pt-5">
      <h1 className="xl:text-2xl text-lg font-semibold text-gray-900 tracking-tighter">
        {pageName}
      </h1>
    </div>
  );
};

export default AppHeader;
