import {
  AppButton,
  AppTabBar,
  AppTable,
  CampaignListCard,
  ScreenLoading
} from 'components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';
import { motion } from 'framer-motion';
import { useCampaignAttendanceQuery } from 'services/queries/campaignAttendance.query';

const CampaignDetail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [campaign, setCampaign] = useState();
  const [tabItems, setTabItems] = useState([
    {
      name: 'Tümü'
    }
  ]);
  const tableTitles = [
    'Kullanıcı İsmi',
    'Kullanıcı Seviyesi',
    'İşletme',
    'Anket İsmi',
    'Katılım Tarihi',
    'Katılım Aktifliği'
  ];
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isStoresLoading, setIsStoresLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(tabItems[0]);
  const { data, isLoading, hasNextPage, refetch, fetchNextPage } =
    useCampaignAttendanceQuery({ id, storeId: selectedTab?.storeId });

  useEffect(() => {
    const getCampaignData = async () => {
      setIsInitialLoading(true);
      try {
        const response = await api.campaign.getCampaign(id);
        const resCampaign = response.data.campaign;

        setCampaign(resCampaign);
      } catch (error) {
        console.log(error, 'campaign error');
      }

      setIsInitialLoading(false);
    };
    getCampaignData();
  }, [id]);

  const loadMoreAttendance = useCallback(() => {
    if (hasNextPage) {
      setIsRefreshing(true);
      fetchNextPage()
        .then(() => setIsRefreshing(false))
        .catch(() => setIsRefreshing(false));
    }
  }, [hasNextPage, fetchNextPage]);

  const campaignStores = data => {
    setIsStoresLoading(true);
    for (let i = 0; i < data.length; i++) {
      const store = data[i];
      setTabItems(prev => [
        ...prev,
        {
          name: store.storeName,
          storeId: store._id
        }
      ]);
    }

    setIsStoresLoading(false);
  };

  const onChangedTab = async selectedTab => {
    setSelectedTab(selectedTab);
    refetch();
  };

  const flatData = useMemo(() => {
    const flatMapData = data?.pages.flatMap(page => page) || [];

    return flatMapData || [];
  }, [data?.pages]);

  return (
    <>
      {isInitialLoading ? (
        <ScreenLoading />
      ) : (
        <div>
          <CampaignListCard
            campaign={campaign}
            campaignStores={campaignStores}
            isMoreDetail={true}
          />

          {isStoresLoading ? (
            <></>
          ) : (
            <>
              <div className="flex mt-12  pb-4 items-center w-full justify-between flex-row">
                <h2 className="text-xl font-semibold text-gray-900 tracking-tighter">
                  Kampanya Katılımları
                </h2>
                <AppButton
                  onClick={() => navigate(`/campaign/statistics?id=${id}`)}
                  className="flex"
                  isFullWidth={false}
                >
                  Kampanya İstatistikleri
                </AppButton>
              </div>
              <AppTabBar items={tabItems} setSelectedTab={onChangedTab} />
              {isLoading ? (
                <div className="mt-8 bg-white grid gap-4 grid-flow-col justify-center items-center p-6 border rounded-md border-gray-100">
                  <motion.div
                    className="w-3 h-3 bg-[#FFC907]"
                    animate={{
                      scale: [1, 2, 2, 1, 1],
                      rotate: [0, 0, 270, 270, 0],
                      borderRadius: ['20%', '20%', '50%', '50%', '20%']
                    }}
                    transition={{ duration: 2, repeat: Infinity }}
                  />
                  <motion.div
                    className="w-3 h-3 bg-[#FFC907]"
                    animate={{
                      scale: [1, 2, 2, 1, 1],
                      rotate: [0, 0, 270, 270, 0],
                      borderRadius: ['20%', '20%', '50%', '50%', '20%']
                    }}
                    transition={{ duration: 2, repeat: Infinity }}
                  />
                  <motion.div
                    className="w-3 h-3 bg-[#FFC907]"
                    animate={{
                      scale: [1, 2, 2, 1, 1],
                      rotate: [0, 0, 270, 270, 0],
                      borderRadius: ['20%', '20%', '50%', '50%', '20%']
                    }}
                    transition={{ duration: 2, repeat: Infinity }}
                  />
                </div>
              ) : (
                <div className="mt-4">
                  <AppTable
                    titles={tableTitles}
                    rows={flatData}
                    isLoadMoreActive={hasNextPage}
                    loadMore={loadMoreAttendance}
                    isLoadingMore={isRefreshing}
                    isUpdate={false}
                    isDelete={false}
                    hideRows="__v,_id,storeId,userId,campaignId,survey,isCampaignActive,isCampaignDeleted,createdAt,updatedAt,isActive"
                  />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default CampaignDetail;
