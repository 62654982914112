import { t } from 'locales';
import { dateSettings } from 'constants/date';

export const dateToString = (date, separator = '/') => {
  const formatterDate = new Date(date);
  let day = formatterDate.getDate();
  let month = formatterDate.getMonth() + 1;
  const year = formatterDate.getFullYear();

  return `${(day < 10 ? `0${day}` : day) + separator}${
    (month < 10 ? `0${month}` : month) + separator
  }${year}`;
};

export const timeToString = date => {
  const formatterDate = new Date(date);
  const hours = formatterDate.getHours().toString();
  let minutes = formatterDate.getMinutes().toString();
  minutes = minutes === '0' ? '00' : minutes;
  return `${hours}:${minutes}`;
};

export const dateToStringWithTime = (date, separator = '/') => {
  const formatterDate = new Date(date);
  let day = formatterDate.getDate();
  let month = formatterDate.getMonth() + 1;
  const year = formatterDate.getFullYear();
  const hour = formatterDate.getHours();
  const minutes = formatterDate.getMinutes();

  return `${(day < 10 ? `0${day}` : day) + separator}${
    (month < 10 ? `0${month}` : month) + separator
  }${year} - ${hour < 10 ? `0${hour}` : hour}:${
    minutes < 10 ? `0${minutes}` : minutes
  } `;
};

export const getDateDifference = (lowerDate, biggerDate = new Date()) => {
  return Math.floor((biggerDate - lowerDate) / 1000);
};

export const timeAgo = (lowerDate, biggerDate = new Date()) => {
  const getLowerData = new Date(lowerDate);
  let differenceDate = getDateDifference(getLowerData, biggerDate);
  const resultTime = {
    value: differenceDate,
    name: 'second'
  };

  for (let i = 0; i < dateSettings.length; i++) {
    const time = dateSettings[i];
    differenceDate = Math.trunc(differenceDate / time.divider);

    if (differenceDate) {
      resultTime.value = differenceDate;
      resultTime.name = time.name;
    } else {
      break;
    }
  }
  return t(
    `_timeAgoFunction.${resultTime.name}${resultTime.value > 1 ? 's' : ''}`,
    {
      label: resultTime.value
    }
  );
};
