import React, { useEffect, useRef, useState } from 'react';

const AppTabBar = ({ items, setSelectedTab }) => {
  const tabsRef = useRef([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

  useEffect(() => {
    if (activeTabIndex === null) {
      return;
    }

    const setTabPosition = () => {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    };

    setTabPosition();
  }, [activeTabIndex]);

  return (
    <div className="flew-row relative max-w-fit flex h-10 rounded-lg bg-stone-100 shadow-md  backdrop-blur-sm">
      <span
        className="absolute bottom-0 top-0 -z-10 flex overflow-hidden rounded-lg transition-all duration-300"
        style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
      >
        <span className="h-full w-full rounded-lg  bg-white border border-gray-300" />
      </span>
      {items.map((item, index) => (
        <a
          ref={el => (tabsRef.current[index] = el)}
          className={`my-auto relative flex items-center cursor-pointer select-none px-2 h-full text-xs xl:text-sm xl:px-5 text-center ${
            activeTabIndex === index
              ? 'text-gray-900 font-medium'
              : 'text-gray-500'
          }`}
          onClick={() => {
            setSelectedTab(item);
            setActiveTabIndex(index);
          }}
          key={index}
        >
          {item.Icon && (
            <item.Icon fontSize="inherit" className="xl:mr-2 mr-1" />
          )}
          {item.name}
          {activeTabIndex !== index &&
            activeTabIndex - 1 !== index &&
            index !== items.length - 1 && (
              <span className="h-6 w-[1px] bg-gray-200 absolute right-0" />
            )}
        </a>
      ))}
    </div>
  );
};

export default AppTabBar;
