export const dateSettings = [
  {
    name: 'second',
    divider: 1
  },
  {
    name: 'minute',
    divider: 60
  },
  {
    name: 'hour',
    divider: 60
  },
  {
    name: 'day',
    divider: 24
  },
  {
    name: 'month',
    divider: 30
  },
  {
    name: 'year',
    divider: 12
  }
];
