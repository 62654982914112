import React from 'react';
import AppButton from 'components/AppButton/AppButton.component';
import { useState } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from 'utils/cropImage';

function AppImageCrop({ src, onSave, aspect = 1 / 1 }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        src,
        croppedAreaPixels,
        rotation
      );
      onSave({ file: croppedImage.file, url: croppedImage.url });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="z-[999] justify-center  min-h-full xl:w-1/2 w-full ml-auto mr-auto absolute backdrop-blur-sm border top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex flex-col  mt-auto">
      <div className="bg-white w-full flex justify-between items-center p-4 absolute top-0">
        <span className="xl:text-xl text-base font-medium text-gray-800">
          Fotoğrafı Kırp
        </span>
        <AppButton isFullWidth={false} onClick={showCroppedImage}>
          Kaydet
        </AppButton>
      </div>
      <Cropper
        image={src}
        classes={{
          containerClassName:
            'flex flex-grow max-h-[50%]  mt-16 !bg-transparent  !relative'
        }}
        crop={crop}
        rotation={rotation}
        zoom={zoom}
        aspect={aspect}
        onCropChange={setCrop}
        onRotationChange={setRotation}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
      />
    </div>
  );
}

export default AppImageCrop;
