import React, { useRef, useState } from 'react';
import {
  AddMenuCategoryModal,
  AppAlert,
  AppButton,
  AppSwitch,
  AppTable,
  UpdateMenuCategoryModal
} from 'components';
import api from 'services/api';
import { useForm } from 'react-hook-form';
import { getFileId } from 'utils/file';
import { convertCategoryData } from 'utils/menu';

const MenuCategoryListTab = ({ store, setStore }) => {
  const [isLoading, setIsLoading] = useState(true);
  const titles = ['Fotoğraf', 'Görünürlük Durumu', 'Kategori İsmi'];
  const [categories, setCategories] = useState(convertCategoryData(store));
  const [selectedCategory, setSelectedCategory] = useState();
  const draggingItem = useRef();
  const dragOverItem = useRef();
  const [draggingIndex, setDraggingIndex] = useState(null);
  const [alertInfo, setAlertInfo] = useState({ isActive: false });

  //modals
  const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
  const [isUpdateCategoryModalOpen, setIsUpdateCategoryModalOpen] =
    useState(false);

  const { control, setValue, watch } = useForm({
    defaultValues: {
      isCategorySortActive: false
    }
  });

  const onCloseAddCategoryModal = data => {
    if (data?._id) {
      setStore(data);
      store.menu = data.menu;
      setCategories(data.menu);
      setAlertInfo({
        isActive: true,
        type: 'success',
        title: 'Oluşturuldu',
        subtitle: 'Kategori başarıyla eklendi.'
      });
      setTimeout(() => {
        setAlertInfo({ isActive: false });
      }, 1500);
    }
    setIsAddCategoryModalOpen(false);
  };

  const onCloseUpdateCategoryModal = data => {
    if (data?._id) {
      setStore(data);
      store.menu = data.menu;
      setCategories(data.menu);
      setAlertInfo({
        isActive: true,
        type: 'success',
        title: 'Güncellendi',
        subtitle: 'Kategori başarıyla güncellendi.'
      });
      setTimeout(() => {
        setAlertInfo({ isActive: false });
      }, 1500);
    }

    setIsUpdateCategoryModalOpen(false);
  };

  const openUpdateCategoryModal = categoryId => {
    const category = store.menu.find(item => item._id === categoryId);

    setSelectedCategory(category);
    setIsUpdateCategoryModalOpen(true);
  };

  const deleteCategory = async categoryId => {
    try {
      const response = await api.store.deleteMenuCategory(
        store._id,
        categoryId
      );

      const deletedCategory = store.menu.find(item => item._id === categoryId);
      if (deletedCategory?.categoryPhoto) {
        const fileId = getFileId(deletedCategory?.categoryPhoto);
        const deletedOldFile = await api.global.delete('file', fileId);
      }

      setIsLoading(true);
      setStore(response.data.store);
      setCategories(response.data.store.menu);
      setIsLoading(false);
      setAlertInfo({
        isActive: true,
        type: 'success',
        title: 'Silindi',
        subtitle: 'Kategori başarıyla silindi.'
      });
      setTimeout(() => {
        setAlertInfo({ isActive: false });
      }, 1500);
    } catch (err) {
      console.log(err);
    }
  };

  const onSort = async () => {
    const response = await api.store.sortMenuCategoryList(
      store._id,
      categories
    );
    setValue('isCategorySortActive', false);
    setStore(response.data.store);
  };

  const handleDragStart = (e, position) => {
    draggingItem.current = position;
  };

  const handleDragEnter = (e, position) => {
    dragOverItem.current = position;

    const listCopy = [...categories];
    const draggingItemContent = listCopy[draggingItem.current];
    listCopy.splice(draggingItem.current, 1);
    listCopy.splice(dragOverItem.current, 0, draggingItemContent);

    draggingItem.current = dragOverItem.current;
    setDraggingIndex(dragOverItem.current);
    dragOverItem.current = null;

    setCategories(listCopy);
  };

  const draggingEnd = () => {
    setDraggingIndex(null);
    draggingItem.current = null;
  };

  const TableButtons = () => (
    <div className="flex w-full justify-between">
      <div className="flex flex-row items-center">
        <span className="text-sm text-slate-900 font-medium mr-2">
          Kategori Sıralama
        </span>
        <AppSwitch
          control={control}
          isLabelActive={false}
          name="isCategorySortActive"
          label="Kategori Görünürlüğü ?"
        />
      </div>
      {watch('isCategorySortActive') ? (
        <AppButton
          onClick={onSort}
          isFullWidth={false}
          className="shadow-xl bg-slate-50 border-slate-200 text-slate-700 hover:bg-slate-200 focus:ring-2 focus:ring-slate-500 border-2"
        >
          Kaydet
        </AppButton>
      ) : (
        <AppButton
          onClick={() => setIsAddCategoryModalOpen(true)}
          isFullWidth={false}
        >
          Kategori Ekle
        </AppButton>
      )}
    </div>
  );

  return (
    <>
      <AppTable
        titles={titles}
        rows={categories}
        isUpdate={true}
        tableButtons={<TableButtons />}
        isDelete={true}
        type="menuCategory"
        draggable={watch('isCategorySortActive')}
        handleDragStart={handleDragStart}
        handleDragEnter={handleDragEnter}
        draggingEnd={draggingEnd}
        draggIndex={draggingItem.current}
        deleteConfirmSubtitle="Bu işlem geri alınamaz. Bir kategoriyi sildiğinizde o kategorideki ürünler de silinir bunu yapmak istediğinize emin misiniz?"
        onDelete={deleteCategory}
        update={openUpdateCategoryModal}
        hideRows="categoryProductList,_id,tr,en,categoryName"
      />

      {isAddCategoryModalOpen && (
        <AddMenuCategoryModal
          store={store}
          onClose={onCloseAddCategoryModal}
          isOpen={isAddCategoryModalOpen}
        />
      )}
      {isUpdateCategoryModalOpen && (
        <UpdateMenuCategoryModal
          store={store}
          data={selectedCategory}
          onClose={onCloseUpdateCategoryModal}
          isOpen={isUpdateCategoryModalOpen}
        />
      )}
      {alertInfo.isActive && (
        <AppAlert
          type={alertInfo.type}
          title={alertInfo.title}
          subtitle={alertInfo.subtitle}
        />
      )}
    </>
  );
};

export default MenuCategoryListTab;
