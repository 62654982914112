import React, { useState } from 'react';
import { AppModal, AppButton, AppInput, AppConfirm } from 'components';
import { useForm } from 'react-hook-form';
import api from 'services/api';

const ChangePasswordModal = ({ onClose, isOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const {
    register,
    formState: { errors },
    setError,
    reset,
    handleSubmit
  } = useForm({
    defaultValues: {}
  });

  const closeModal = () => {
    onClose();
  };

  const onSubmit = async submitData => {
    setIsConfirmOpen(false);
    if (submitData.password === submitData.passwordConfirm) {
      setIsLoading(true);
      try {
        await api.global.update('customer', 'password', {
          password: submitData.password,
          oldPassword: submitData.oldPassword
        });
        reset();
        onClose(true);
      } catch (err) {
        if (err?.response?.data?.reason === 'passwordMatch') {
          setError('oldPassword', {
            type: 'wrongData'
          });
        }
      }

      setIsLoading(false);
    } else {
      setError('passwordConfirm', {
        type: 'passwordNotEqual'
      });
    }
  };

  const onCloseConfirm = () => {
    setIsConfirmOpen(false);
  };

  return (
    <AppModal
      open={isOpen}
      title="Şifre Değiştir"
      handleClose={() => closeModal()}
    >
      <AppInput
        label="Şifre"
        name="oldPassword"
        type="password"
        error={errors.oldPassword}
        rules={{ required: true }}
        register={register}
        placeholder="Şifrenizi girin"
        autoComplete="off"
      />
      <AppInput
        label="Yeni Şifre"
        name="password"
        type="password"
        error={errors.password}
        rules={{ required: true }}
        register={register}
        placeholder="Yeni şifre"
        autoComplete="off"
      />
      <AppInput
        label="Yeni Şifreni Doğrula"
        name="passwordConfirm"
        type="password"
        error={errors.passwordConfirm}
        rules={{ required: true }}
        register={register}
        placeholder="Yeni şifreni doğrula"
        autoComplete="off"
      />

      <AppButton
        className="!p-3 bg-gray-900 text-white hover:bg-gray-800 focus:ring-2 focus:!ring-black !text-sm"
        onClick={() => setIsConfirmOpen(true)}
        isLoading={isLoading}
      >
        Şifremi Değiştir
      </AppButton>

      <AppConfirm isOpen={isConfirmOpen} handleClose={onCloseConfirm}>
        <div className="flex flex-col mt-1">
          <span className="text-sm font-medium text-gray-900">
            Şifrenizi değiştirmek istediğinize emin misiniz ?
          </span>
          <div className="flex flex-row mt-3">
            <AppButton
              onClick={() => setIsConfirmOpen(false)}
              className="mr-3 !bg-white !text-gray-500 focus:!ring-gray-500 hover:!bg-gray-200 border border-gray-500"
            >
              İptal Et
            </AppButton>
            <AppButton
              onClick={handleSubmit(onSubmit)}
              className="ml-3 !bg-white !text-red-500 focus:!ring-red-500 hover:!bg-red-300 border border-red-500"
            >
              Onayla
            </AppButton>
          </div>
        </div>
      </AppConfirm>
    </AppModal>
  );
};

export default ChangePasswordModal;
