import React, { useEffect, useState } from 'react';
import api from 'services/api';
import { AppPagination, WorkerAnswerCard } from 'components';

const WorkerAnswersTab = ({ store }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const totalPageResult = Math.floor(store.workersAnswersCount / 10);
  const totalPage = totalPageResult > 0 ? totalPageResult + 1 : 0;

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      await api.store
        .getStoreWorkerAllAnswers(store._id, page)
        .then(async res => {
          setData(res.data.answers);
        })
        .catch(error => console.log('error', error));

      setIsLoading(false);
    };
    getData();
  }, [page]);

  const onPageChange = targetPage => {
    setPage(targetPage);
  };

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <div>
          {data && data.length > 0 ? (
            <>
              {data.map((answer, index) => (
                <WorkerAnswerCard
                  key={index}
                  answer={answer}
                  storeWorkers={store.storeWorkers}
                />
              ))}
              <AppPagination
                onChange={onPageChange}
                page={page}
                totalPage={totalPage}
              />
            </>
          ) : (
            <div className="mt-4 text-gray-500 text-sm xl:px-6 xl:py-3 p-3 border rounded-md border-gray-200 shadow-sm bg-white text-center">
              Henüz herhangi bir cevap verilmedi.
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default WorkerAnswersTab;
