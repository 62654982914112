import { useInfiniteQuery } from '@tanstack/react-query';

import { getCampaignAttendancesListRequest } from 'services/requests/campaignAttendance.request';

export const useCampaignAttendanceQuery = ({ id, storeId }) =>
  useInfiniteQuery({
    queryKey: ['attendanceList', storeId],
    queryFn: ({ pageParam }) =>
      getCampaignAttendancesListRequest(id, pageParam, storeId),
    getNextPageParam(lastPage, allPages) {
      return lastPage.length === 10 ? allPages.length : undefined;
    },
    initialPageParam: 0,
    staleTime: 1000 * 60 * 5 // 5 dakika
  });
