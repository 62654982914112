export const questionTypes = [
  {
    label: 'Çoktan Seçmeli',
    value: 'multipleChoices'
  },
  {
    label: 'Açıklama',
    value: 'explanation'
  },
  {
    label: 'Oylama',
    value: 'rate'
  }
];
