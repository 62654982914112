import React from 'react';
import { FormErrorMessage } from 'components';
import CurrencyInput from 'react-currency-input-field';
import { Controller } from 'react-hook-form';

const AppCurrencyInput = ({
  label,
  name,
  placeholder,
  error,
  rules,
  control,
  isLabelActive = true,
  isRequiredSymbolActive = false
}) => {
  return (
    <div className="w-full mb-5">
      {isLabelActive && (
        <label
          htmlFor={name}
          className={`w-full  block mb-2 text-sm font-medium ${
            error ? 'text-red-600' : 'text-gray-900'
          }`}
        >
          {label}
          {isRequiredSymbolActive && (
            <span className="align-top text-red-600 font-bold ml-1">*</span>
          )}
        </label>
      )}
      <Controller
        control={control}
        rules={rules}
        name={name}
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <CurrencyInput
                id={name}
                name={name}
                placeholder={placeholder}
                className={`w-full outline-none  border text-gray-900 text-sm rounded-lg  block p-2.5  ${
                  error
                    ? 'border-red-500 focus:border-red-500'
                    : 'border-gray-200 focus:border-gray-700'
                }`}
                allowNegativeValue={false}
                decimalSeparator=","
                groupSeparator="."
                autoComplete="off"
                defaultValue={value && value.replaceAll('.', '')}
                onValueChange={(value, name, values) => {
                  const formattedValue = values.formatted.replace('₺', '');
                  onChange(formattedValue);
                }}
                intlConfig={{ locale: 'tr-Tr', currency: 'TRY' }}
              />
              <FormErrorMessage label={label} error={error} />
            </>
          );
        }}
      />
    </div>
  );
};

export default AppCurrencyInput;
