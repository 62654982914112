import React, { useState } from 'react';
import api from 'services/api';
import {
  UpdateWorkerModal,
  AddWorkerModal,
  AppTable,
  AppButton,
  AppAlert
} from 'components';
import { arrangeStoreDetails } from 'utils/store';
import { getFileId } from 'utils/file';

const StoreWorkersTab = props => {
  const [store, setStore] = useState(props.store);
  const [updateWorkerId, setUpdateWorkerId] = useState();

  const titles = [
    'Fotoğraf',
    'İsim',
    'Soyisim',
    'Meslek',
    'Performans Yüzdesi',
    'Toplam Oylama Sayısı',
    'Aktiflik Durumu'
  ];

  //modals
  const [isAddWorkerModalOpen, setIsAddWorkerModalOpen] = useState(false);
  const [isUpdateWorkerModalOpen, setIsUpdateWorkerModalOpen] = useState(false);

  const [alertInfo, setAlertInfo] = useState({ isActive: false });

  const deleteWorker = async workerId => {
    try {
      const response = await api.store.deleteWorker(store._id, workerId);
      const deletedWorker = store.storeWorkers.find(
        item => item._id === workerId
      );

      if (deletedWorker?.photo) {
        const fileId = getFileId(deletedWorker?.photo);
        const deletedOldFile = await api.global.delete('file', fileId);
      }

      const arrangedStore = arrangeStoreDetails(response.data.store);
      setStore(arrangedStore);
      props.setStore(arrangedStore);
      setAlertInfo({
        isActive: true,
        type: 'success',
        title: 'Silindi',
        subtitle: 'Çalışan başarıyla silindi'
      });
      setTimeout(() => {
        setAlertInfo({ isActive: false });
      }, 1500);
    } catch (err) {
      console.log(err);
    }
  };

  const onCloseAddWorkerModal = data => {
    if (data?._id) {
      const arrangedStore = arrangeStoreDetails(data);
      setStore(arrangedStore);
      props.setStore(arrangedStore);
      setAlertInfo({
        isActive: true,
        type: 'success',
        title: 'Oluşturuldu',
        subtitle: 'Çalışan başarıyla oluşturuldu'
      });
      setTimeout(() => {
        setAlertInfo({ isActive: false });
      }, 1500);
    }
    setIsAddWorkerModalOpen(false);
  };

  const onCloseUpdateWorkerModal = data => {
    if (data?._id) {
      const arrangedStore = arrangeStoreDetails(data);
      setStore(arrangedStore);
      props.setStore(arrangedStore);
      setAlertInfo({
        isActive: true,
        type: 'success',
        title: 'Güncellendi',
        subtitle: 'Çalışan başarıyla güncellendi'
      });
      setTimeout(() => {
        setAlertInfo({ isActive: false });
      }, 1500);
    }
    setIsUpdateWorkerModalOpen(false);
  };

  const openUpdateWorkerModal = id => {
    setUpdateWorkerId(id);
    setIsUpdateWorkerModalOpen(true);
  };

  const TableButtons = () => (
    <div className="flex justify-end w-full relative">
      <AppButton
        isFullWidth={false}
        onClick={() => setIsAddWorkerModalOpen(true)}
      >
        Çalışan Ekle
      </AppButton>
      {alertInfo.isActive && (
        <AppAlert
          type={alertInfo.type}
          title={alertInfo.title}
          subtitle={alertInfo.subtitle}
        />
      )}
    </div>
  );

  return (
    <div>
      <AppTable
        titles={titles}
        rows={store.storeWorkers}
        type="store"
        onDelete={deleteWorker}
        isUpdate={true}
        update={openUpdateWorkerModal}
        tableButtons={<TableButtons />}
        isDelete={true}
        pageItems={20}
        hideRows="__v,_id,customerId,answersCount,survey,customerFullName,storeWorkers,updatedAt,allSurveys,visibleSurveyId"
        emptyText="Henüz bir çalışan eklemediniz."
      />
      {isAddWorkerModalOpen && (
        <AddWorkerModal
          store={store}
          onClose={onCloseAddWorkerModal}
          isOpen={isAddWorkerModalOpen}
        />
      )}
      {isUpdateWorkerModalOpen && (
        <UpdateWorkerModal
          store={store}
          workerId={updateWorkerId}
          onClose={onCloseUpdateWorkerModal}
          isOpen={isUpdateWorkerModalOpen}
        />
      )}
    </div>
  );
};

export default StoreWorkersTab;
