import {
  AppButton,
  AppFile,
  AppInput,
  AppModal,
  AppSwitch,
  MenuCategoryTemplatesModal
} from 'components';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import api from 'services/api';

const AddMenuCategoryModal = ({ store, onClose, isOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);

  //form values
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    getValues
  } = useForm({
    defaultValues: {
      isCategoryVisible: true,
      categoryPhoto: null
    }
  });

  const onSubmit = async data => {
    setIsLoading(true);

    if (data?.categoryPhoto) {
      const isPhotoTemplate = typeof data?.categoryPhoto === 'string';

      if (!isPhotoTemplate) {
        const res = await api.file.upload(data?.categoryPhoto);
        if (res) {
          data.categoryPhoto = res?.url;
        }
      }
    }
    try {
      const response = await api.store.addMenuCategory(store._id, data);
      onClose(response.data.store);
    } catch (err) {
      console.log(err);
    }

    setIsLoading(false);
  };

  const closeModal = data => {
    reset();
    onClose(data);
  };

  const onSelectTemplate = url => {
    setValue('categoryPhoto', url);
    setIsTemplateModalOpen(false);
  };

  return (
    <AppModal
      open={isOpen}
      title="Kategori Ekle"
      handleClose={() => closeModal()}
    >
      <div className="w-full items-center flex flex-col">
        <AppFile
          name="categoryPhoto"
          error={errors.categoryPhoto}
          rules={{ required: true }}
          register={register}
          isRequiredSymbolActive={true}
          selectedImage={getValues('categoryPhoto')}
          label="Fotoğraf"
          onFileSaved={url => {
            setValue('categoryPhoto', url);
          }}
          aspect={5 / 1}
        />
        <AppButton
          onClick={() => setIsTemplateModalOpen(true)}
          type="button"
          className="mb-5 ml"
          isFullWidth={false}
        >
          Şablondan Seç
        </AppButton>
      </div>

      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label="Kategori İsmi (TR)"
          name="tr.categoryName"
          error={errors.tr?.categoryName}
          rules={{ required: true }}
          isRequiredSymbolActive={true}
          register={register}
          autoComplete="off"
          placeholder="Kahvaltı,Yemekler,İçecekler..."
        />
        <AppInput
          label="Kategori İsmi (EN-İngilizce)"
          name="en.categoryName"
          register={register}
          autoComplete="off"
          placeholder="Breakfast,Meals,Beverages..."
        />
        <AppSwitch
          control={control}
          name="isCategoryVisible"
          label="Kategori Görünürlüğü ?"
        />
        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          Ekle
        </AppButton>
      </form>
      <MenuCategoryTemplatesModal
        onClose={() => setIsTemplateModalOpen(false)}
        isOpen={isTemplateModalOpen}
        onSelect={onSelectTemplate}
      />
    </AppModal>
  );
};

export default AddMenuCategoryModal;
