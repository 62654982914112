import {
  AppButton,
  AppFile,
  AppInput,
  AppModal,
  AppSwitch,
  MenuCategoryTemplatesModal
} from 'components';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import api from 'services/api';
import { getFileId } from 'utils/file';

const UpdateMenuCategoryModal = ({ store, onClose, isOpen, data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [isPhotoTemplate, setIsPhotoTemplate] = useState(false);

  //form values
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    reset
  } = useForm({
    defaultValues: {
      categoryPhoto: data?.categoryPhoto,
      tr: data?.tr,
      en: data?.en,
      isCategoryVisible: data.isCategoryVisible
    }
  });

  const onSubmit = async submitData => {
    setIsLoading(true);
    const categoryId = data._id;
    if (submitData?.categoryPhoto !== data?.categoryPhoto) {
      if (data?.categoryPhoto) {
        const fileId = getFileId(data?.categoryPhoto);
        const deleteOldFile = await api.global.delete('file', fileId);
      }

      const isPhotoTemplate = typeof submitData?.categoryPhoto === 'string';

      if (!isPhotoTemplate) {
        if (submitData?.categoryPhoto) {
          const res = await api.file.upload(submitData?.categoryPhoto);
          if (res) {
            submitData.categoryPhoto = res?.url;
          }
        }
      }
    }
    try {
      const response = await api.store.updateMenuCategory(
        store._id,
        categoryId,
        submitData
      );

      onClose(response.data.store);
    } catch (err) {
      console.log(err);
    }

    setIsLoading(false);
  };

  const closeModal = data => {
    onClose(data);
    reset();
  };

  const onSelectTemplate = url => {
    setValue('categoryPhoto', url);
    setIsPhotoTemplate(true);
    setIsTemplateModalOpen(false);
  };

  return (
    <AppModal
      open={isOpen}
      title="Kategori Güncelle"
      handleClose={() => closeModal()}
    >
      <div className="w-full items-center flex flex-col">
        <AppFile
          name="categoryPhoto"
          error={errors.categoryPhoto}
          rules={{ required: true }}
          isRequiredSymbolActive={true}
          register={register}
          selectedImage={getValues('categoryPhoto')}
          label="Fotoğraf"
          aspect={5 / 1}
          onFileSaved={url => {
            if (isPhotoTemplate) {
              setIsPhotoTemplate(false);
              setValue('categoryPhoto', data?.categoryPhoto);
            } else {
              setValue('categoryPhoto', url);
            }
          }}
        />

        <AppButton
          onClick={() => setIsTemplateModalOpen(true)}
          type="button"
          className="mb-5 ml"
          isFullWidth={false}
        >
          Şablondan Seç
        </AppButton>
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label="Kategori İsmi (TR)"
          name="tr.categoryName"
          error={errors.tr?.categoryName}
          rules={{ required: true }}
          isRequiredSymbolActive={true}
          register={register}
          autoComplete="off"
          placeholder="Kahvaltı,Yemekler,İçecekler..."
        />
        <AppInput
          label="Kategori İsmi (EN-İngilizce)"
          name="en.categoryName"
          register={register}
          autoComplete="off"
          placeholder="Breakfast,Meals,Beverages..."
        />
        <AppSwitch
          control={control}
          name="isCategoryVisible"
          label="Kategori Görünürlüğü ?"
        />
        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          Güncelle
        </AppButton>
      </form>
      <MenuCategoryTemplatesModal
        onClose={() => setIsTemplateModalOpen(false)}
        isOpen={isTemplateModalOpen}
        onSelect={onSelectTemplate}
      />
    </AppModal>
  );
};

export default UpdateMenuCategoryModal;
