import axios from 'axios';

const baseUrl = 'https://api.repidas.com';

const apiConfig = axios.create({
  baseURL: `${baseUrl}/api/v1`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

apiConfig.interceptors.request.use(async config => {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user ? user.token : '';
  if (token) {
    config.headers['x-auth-token'] = token;
  }
  return config;
});

export { apiConfig };
