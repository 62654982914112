import React, { useEffect, useRef, useState } from 'react';
import {
  AssignmentIndOutlined,
  BarChart,
  Diversity1Outlined,
  QuestionAnswerOutlined,
  QuizOutlined,
  RestaurantMenuOutlined
} from '@mui/icons-material';
import {
  AppButton,
  StoreInfoModal,
  AppTabBar,
  AppText,
  AppTitle,
  SurveyContentTab,
  StoreWorkersTab,
  SurveyAnswersTab,
  WorkerAnswersTab,
  AppAlert,
  ScreenLoading
} from 'components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';
import { getValue } from 'utils/table';
import { arrangeStoreDetails } from 'utils/store';
import QRCode from 'react-qr-code';
import { storeTypes } from 'constants/store';
import { motion } from 'framer-motion';
import * as htmlToImage from 'html-to-image';

export default function StoreDetail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [alertInfo, setAlertInfo] = useState({ isActive: false });
  const [totalQrScan, setTotalQrScan] = useState(0);

  //modals
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const infoProperties = [
    {
      label: 'İsim',
      property: 'storeName'
    },
    { label: 'Tür', property: 'storeType' },
    { label: 'İl', property: 'city' },
    { label: 'İlçe', property: 'district' },
    { label: 'Konum', property: 'position' },
    {
      label: 'Menü Aktifliği',
      property: 'isMenuActive'
    },
    {
      label: 'QR Erişimi',
      property: 'isActive'
    },
    {
      label: 'Tema Rengi',
      property: 'themeColor'
    },
    {
      label: 'Kayıt Tarihi',
      property: 'createdAt'
    }
  ];

  //tab
  const tabItems = [
    {
      name: 'Anket',
      Icon: QuizOutlined,
      Component: SurveyContentTab,
      props: { store: data, setStore: setData }
    },
    {
      name: 'Anket Cevapları',
      Icon: QuestionAnswerOutlined,
      Component: SurveyAnswersTab,
      props: { store: data, setStore: setData }
    },
    {
      name: 'Çalışanlar',
      Icon: Diversity1Outlined,
      Component: StoreWorkersTab,
      props: { store: data, setStore: setData }
    },
    {
      name: 'Çalışan Cevapları',
      Icon: AssignmentIndOutlined,
      Component: WorkerAnswersTab,
      props: { store: data, setStore: setData }
    }
  ];
  const [selectedTab, setSelectedTab] = useState(tabItems[0]);

  //qr Kod
  const qrCodeRef = useRef(null);
  const [isOpenQrSize, setIsOpenQrSize] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);

      const response = await api.global.get('store', id);

      const statisticsResponse = await api.global.get(
        'statistics',
        `qrScanned/${id}`
      );
      setTotalQrScan(statisticsResponse.data.statistics.length || 0);

      const arrangedStore = arrangeStoreDetails(response.data.store);

      tabItems[0].props = { store: arrangedStore, setStore: setData };
      tabItems[1].props = { store: arrangedStore, setStore: setData };
      setData(arrangedStore);

      setIsLoading(false);
    };
    getData();
  }, []);

  const closeUpdateModal = updatedData => {
    if (updatedData) {
      const arrangedStore = arrangeStoreDetails(updatedData);
      setData(arrangedStore);
      setAlertInfo({
        isActive: true,
        type: 'success',
        title: 'Güncellendi',
        subtitle: 'İşletme bilgileri başarıyla güncellendi'
      });
      setTimeout(() => {
        setAlertInfo({ isActive: false });
      }, 1500);
    }
    setIsUpdateModalOpen(false);
  };

  const getInfoPropertyValues = property => {
    if (property === 'position') {
      return `${data.location[property].lat}, ${data.location[property].lng}`;
    } else if (property === 'city') {
      return `${data.location[property]}`;
    } else if (property === 'district') {
      return `${data.location[property]}`;
    } else if (property === 'storeType') {
      const type = storeTypes.find(item => item.value === data[property]);
      return `${type.label}`;
    } else if (property === 'themeColor') {
      return (
        <div className="flex flex-row items-center">
          {data.themeColor}
          <div
            style={{ backgroundColor: data.themeColor }}
            className="w-16 h-4 border border-gray-200 ml-3"
          />
        </div>
      );
    }
    return getValue(data[property]);
  };

  const downloadQRCode = (pixelRatio = 2) => {
    htmlToImage
      .toPng(qrCodeRef.current, {
        pixelRatio: pixelRatio
      })
      .then(function (dataUrl) {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'qr-code.png';
        link.click();
        setIsOpenQrSize(!isOpenQrSize);
      })
      .catch(function (error) {
        console.error('Error generating QR code:', error);
      });
  };

  return (
    <main className="w-full flex flex-col">
      {isLoading ? (
        <ScreenLoading />
      ) : (
        <div className="flex xl:flex-row flex-col">
          <div className="bg-white shadow-md border border-gray-200 xl:w-fit w-full px-4 py-6 rounded-lg xl:min-w-[320px] h-max">
            <div className="flex w-full flex-col items-center">
              <div className="flex justify-center flex-row items-center w-full">
                <AppTitle
                  title={data.storeName}
                  className="text-xl font-medium text-gray-800"
                />
                {data.logo && <img src={data.logo} className="h-6 ml-4" />}
              </div>
              <span className="mt-4 flex flex-row items-center justify-center text-[13px]">
                <AppText
                  text="İşletme Verileri"
                  className="font-medium text-[13px] text-gray-800"
                />
                <BarChart fontSize="inherit" className="ml-2 text-gray-800" />
              </span>
              <div className="flex flex-row mt-3">
                <span className="p-2 justify-center border-dashed border border-gray-300 rounded-md flex flex-col mr-1">
                  <AppText
                    text={data.surveyAnswersCount}
                    className="font-medium text-center"
                  />
                  <AppText
                    text="Anket Cevapları"
                    className="text-xs mt-1 text-gray-400 font-normal"
                  />
                </span>
                <span className="p-2 font-medium justify-center border-dashed border border-gray-300 rounded-md flex flex-col ml-1">
                  <AppText
                    text={data.workersAnswersCount}
                    className="font-medium text-gray-800 text-center"
                  />
                  <AppText
                    text="Çalışan Cevapları"
                    className="text-xs mt-1 text-gray-400 font-normal"
                  />
                </span>
              </div>
              <div className="flex flex-row mt-3">
                <span className="p-2 justify-center border-dashed border border-gray-300 rounded-md flex flex-col mr-1">
                  <AppText
                    text={totalQrScan}
                    className="font-medium text-center"
                  />
                  <AppText
                    text="Qr Okutma Sayısı"
                    className="text-xs mt-1 text-gray-400 font-normal"
                  />
                </span>
              </div>

              <span className="mt-6 border-b pb-2 items-center border-gray-200 flex w-full justify-between flex-row">
                <AppText
                  className="font-medium text-gray-800"
                  text="İşletme Bilgileri"
                />
                <AppButton
                  onClick={() => setIsUpdateModalOpen(true)}
                  isFullWidth={false}
                  className="text-xs rounded-md"
                >
                  Güncelle
                </AppButton>
              </span>

              <div className="flex-col mt-4 w-full">
                {infoProperties.map((item, index) => (
                  <div
                    key={index}
                    className={`transition-all flex flex-col w-full justify-start items-start ${
                      index + 1 !== infoProperties.length && 'pb-4'
                    }`}
                  >
                    <AppText
                      className="font-medium text-gray-800"
                      text={item.label}
                    />
                    <AppText
                      className="text-[14px] mt-px text-gray-500"
                      text={getInfoPropertyValues(item.property)}
                    />
                  </div>
                ))}
                <div className="mt-4 flex  xl:justify-center">
                  <div className="h-auto relative w-24 mt-1  bg-transparent">
                    <QRCode
                      size={256}
                      ref={qrCodeRef}
                      bgColor="transparent"
                      level="M"
                      style={{
                        height: '120px',
                        maxWidth: '120px',
                        width: '120px'
                      }}
                      value={`http://repidas.com/store/${data._id}`}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                </div>
                <motion.div
                  animate={{
                    display: isOpenQrSize ? 'none' : 'flex',
                    opacity: isOpenQrSize ? 0 : 1
                  }}
                  transition={{ duration: 0.5 }}
                >
                  <AppButton
                    className="mt-4"
                    onClick={() => setIsOpenQrSize(!isOpenQrSize)}
                  >
                    Qr Kodu İndir
                  </AppButton>
                </motion.div>

                <motion.div
                  animate={{
                    display: isOpenQrSize ? 'flex' : 'none',
                    opacity: isOpenQrSize ? 1 : 0,
                    scale: isOpenQrSize ? 1 : 0
                  }}
                  className="flex-col mt-4"
                  transition={{ duration: 0.5 }}
                >
                  <AppText
                    text="Qr Boyutu Seç"
                    className="font-medium text-gray-800 "
                  />
                  <div className="flex flex-row gap-4 mt-3">
                    <AppButton
                      isFullWidth={false}
                      onClick={() => downloadQRCode(1)}
                    >
                      1x
                    </AppButton>
                    <AppButton
                      isFullWidth={false}
                      onClick={() => downloadQRCode(2)}
                    >
                      2x
                    </AppButton>
                    <AppButton
                      isFullWidth={false}
                      onClick={() => downloadQRCode(3)}
                    >
                      3x
                    </AppButton>
                    <AppButton
                      isFullWidth={false}
                      onClick={() => downloadQRCode(4)}
                    >
                      4x
                    </AppButton>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>

          <div className="xl:ml-12 w-full xl:mt-0 mt-6">
            <AppButton
              className="xl:hidden visible mb-6 h-10 shadow-md"
              onClick={() => navigate(`/store/menu/list?id=${id}`)}
            >
              Qr Menü
              <RestaurantMenuOutlined fontSize="small" className="ml-2" />
            </AppButton>

            <div className="flex justify-between items-center">
              <AppTabBar items={tabItems} setSelectedTab={setSelectedTab} />

              <AppButton
                className="xl:inline-block hidden h-10 shadow-md"
                isFullWidth={false}
                onClick={() => navigate(`/store/menu/list?id=${id}`)}
              >
                Qr Menü
                <RestaurantMenuOutlined fontSize="small" className="ml-2" />
              </AppButton>
            </div>

            <div className="mt-6">
              <selectedTab.Component {...selectedTab.props} />
            </div>
          </div>
          <StoreInfoModal
            store={data}
            onClose={closeUpdateModal}
            isOpen={isUpdateModalOpen}
          />
        </div>
      )}
      {alertInfo.isActive && (
        <AppAlert
          type={alertInfo.type}
          title={alertInfo.title}
          subtitle={alertInfo.subtitle}
        />
      )}
    </main>
  );
}
