import React, { useState } from 'react';
import { AppModal, AppButton, AppInput } from 'components';
import { useAuth } from 'context/AuthContext';
import { useForm } from 'react-hook-form';
import api from 'services/api';

const EditProfileModal = ({ onClose, isOpen, setScreenUser }) => {
  const { user, setUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName
    }
  });

  const closeModal = () => {
    onClose();
  };

  const onSubmit = async submitData => {
    setIsLoading(true);
    const response = await api.global.update('customer', user._id, submitData);

    const updatedUser = {
      ...user,
      ...response.data.customer
    };

    setUser(updatedUser);
    setScreenUser(prev => ({ ...prev, ...response.data.customer }));
    onClose(true);
    setIsLoading(false);
  };

  return (
    <AppModal
      open={isOpen}
      title="Profil Bilgilerini Güncelle"
      handleClose={() => closeModal()}
    >
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label="İsim"
          name="firstName"
          error={errors.firstName}
          rules={{ required: true }}
          register={register}
          placeholder="İsim"
          autoComplete="off"
        />
        <AppInput
          label="Soyisim"
          name="lastName"
          error={errors.lastName}
          rules={{ required: true }}
          register={register}
          placeholder="Soyisim"
          autoComplete="off"
        />

        <AppButton
          className="!p-3 bg-gray-900 text-white hover:bg-gray-800 focus:ring-2 focus:ring-black !text-sm"
          type="submit"
          isLoading={isLoading}
        >
          Güncelle
        </AppButton>
      </form>
    </AppModal>
  );
};

export default EditProfileModal;
