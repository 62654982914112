import { Bear, Deer, Fox, Panda, Wolf } from 'assets/images';

export const levels = user => [
  {
    title: 'Acemi',
    image: Deer,
    point: 0,
    selected: user.point < 30
  },
  {
    title: 'Deneyimli',
    image: Wolf,
    point: 30,
    selected: user.point >= 30 && user.point < 100
  },
  {
    title: 'Uzman',
    image: Panda,
    point: 100,
    selected: user.point >= 100 && user.point < 200
  },
  {
    title: 'Elçi',
    image: Fox,
    point: 200,
    selected: user.point >= 200 && user.point < 500
  },
  {
    title: 'Elit',
    image: Bear,
    point: 500,
    selected: user.point >= 500
  }
];
