import React, { useState } from 'react';
import {
  AddSurveyModal,
  AppButton,
  AppModal,
  AppText,
  UpdateSurveyModal
} from 'components';
import { AddCircleOutline, Check } from '@mui/icons-material';
import api from 'services/api';

const MySurveysModal = ({ store, onClose, isOpen }) => {
  const [storeData, setStoreData] = useState(store);
  const [currentSurvey, setCurrentSurvey] = useState(
    storeData.allSurveys.find(item => item._id === storeData.visibleSurveyId)
  );
  const [visibleSurveyChangeLoading, setVisibleSurveyChangeLoading] =
    useState(false);
  const [deletedSurveyLoading, setDeletedSurveyLoading] = useState(false);

  const [isAddSurveyModalOpen, setIsAddSurveyModalOpen] = useState(false);
  const [isUpdateSurveyModalOpen, setIsUpdateSurveyModalOpen] = useState(false);
  const [updatedSurvey, setUpdatedSurvey] = useState();

  const updateVisibleSurvey = async data => {
    setVisibleSurveyChangeLoading(true);
    const response = await api.store.updateVisibleSurvey(store._id, data._id);
    if (response.status === 200) {
      updateAndCloseModal(response.data.store);
    }
    setVisibleSurveyChangeLoading(false);
  };

  const updateAndCloseModal = (
    responseStore,
    isDeleted = false,
    isAlertActive = true
  ) => {
    onClose(responseStore, isDeleted, isAlertActive);
  };

  const deleteSurvey = async data => {
    setDeletedSurveyLoading(true);
    const response = await api.store.deleteSurvey(storeData._id, data._id);
    if (response.status === 200) {
      updateAndCloseModal(response.data.store, true);
    }
    setDeletedSurveyLoading(false);
  };

  const onCloseUpdateSurveyModal = data => {
    if (data?._id) {
      updateAndCloseModal(data, false, false);
    }
    setIsUpdateSurveyModalOpen(false);
  };
  return (
    <AppModal
      open={isOpen}
      title="Anketlerim"
      handleClose={() => updateAndCloseModal()}
    >
      {storeData.allSurveys.map((survey, index) => (
        <div
          key={survey._id}
          className={`py-5 px-3 border relative justify-between border-dashed rounded-md  flex flex-row items-center ${
            (index > 0 || storeData.allSurveys.length === 1) && 'mt-4'
          } ${
            currentSurvey._id === survey._id
              ? 'border-amber-400'
              : 'border-slate-300'
          }`}
        >
          {currentSurvey._id === survey._id && (
            <Check
              fontSize="small"
              className="justify-center absolute text-white -right-1 -top-[11px] bg-amber-400 rounded-sm"
            />
          )}
          <div className="flex flex-row items-center">
            <AppText
              className="text-gray-800 xl:text-base text-sm font-medium tracking-tight"
              text={survey.surveyName}
            />

            <AppButton
              className={`ml-3 !text-xs bg-transparent focus:ring-0  border !px-2 !py-1 rounded-sm text-gray-800 ${
                currentSurvey._id === survey._id
                  ? 'hover:bg-transparent !cursor-not-allowed !text-amber-400 border-amber-300'
                  : 'hover:bg-slate-100 text-slate-700 border-slate-300'
              }`}
              isFullWidth={false}
              onClick={() => updateVisibleSurvey(survey)}
              isLoading={visibleSurveyChangeLoading}
              disabled={currentSurvey._id === survey._id}
            >
              {currentSurvey._id === survey._id ? 'Yayında' : 'Yayına Al'}
            </AppButton>
          </div>
          <div className="flex xl:flex-row flex-col">
            <AppButton
              onClick={() => {
                setUpdatedSurvey(survey);
                setIsUpdateSurveyModalOpen(true);
              }}
              className="ml-3 !px-3 text-xs"
              isFullWidth={false}
            >
              Düzenle
            </AppButton>
            {currentSurvey._id !== survey._id && (
              <AppButton
                onClick={() => deleteSurvey(survey)}
                isLoading={deletedSurveyLoading}
                className="ml-3 mt-1 xl:mt-0 !px-3 text-xs bg-rose-100 text-rose-500 focus:ring-rose-500 hover:bg-rose-200 "
                isFullWidth={false}
              >
                Sil
              </AppButton>
            )}
          </div>
        </div>
      ))}
      <AppButton className="mt-4" onClick={() => setIsAddSurveyModalOpen(true)}>
        Yeni Anket Oluştur
        <AddCircleOutline className="ml-1" />
      </AppButton>
      {isAddSurveyModalOpen && (
        <AddSurveyModal
          store={storeData}
          onClose={() => setIsAddSurveyModalOpen(false)}
          isOpen={isAddSurveyModalOpen}
        />
      )}
      {isUpdateSurveyModalOpen && (
        <UpdateSurveyModal
          store={storeData}
          survey={updatedSurvey}
          onClose={onCloseUpdateSurveyModal}
          isOpen={isUpdateSurveyModalOpen}
        />
      )}
    </AppModal>
  );
};

export default MySurveysModal;
