export const authApi = api => ({
  login(email, password) {
    return api.post('/customer/login', {
      email,
      password
    });
  },
  register(customer) {
    return api.put('/customer/register', {
      customer
    });
  },
  forgotPassword(email) {
    return api.post('/customer/forgotPassword', {
      email
    });
  },
  resetPassword(id, token, password) {
    return api.post(`/customer/resetPassword/${id}/${token}`, {
      password
    });
  },
  confirmRegister(id, token) {
    return api.post(`/customer/confirmRegister/${id}/${token}`);
  },
  sendConfirmMail() {
    return api.post('/customer/sendConfirmMail');
  }
});
