import api from 'services/api';
import { getAttendanceTableData } from 'utils/campaign';

export const getCampaignAttendancesListRequest = async (
  category,
  pageParam = 0,
  storeId
) => {
  try {
    const { data } = await api.campaign.getListedCampaignAttendances(
      category,
      pageParam,
      storeId
    );

    if (data?.status !== 'success') {
      await Promise.reject(data);
    }

    if (data.status === 'success') {
      try {
        const response = await api.store.myStores();
        const myStores = response.data.store;

        const convertedData = await getAttendanceTableData(
          data?.attendances,
          myStores
        );

        return convertedData;
      } catch (err) {
        console.log(err);
      }

      return [];
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
