import React, { useEffect, useState } from 'react';
import {
  SurveyAnswerCard,
  AppPagination,
  SurveyReplyModal,
  AppAlert
} from 'components';
import api from 'services/api';

const SurveyAnswersTab = ({ store }) => {
  const [allAnswers, setAllAnswers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState();
  const [isReplyModalOpen, setIsReplyModalOpen] = useState(false);
  const [successInfo, setSuccessInfo] = useState({ isActive: false });
  const totalPageResult = Math.floor(store.surveyAnswersCount / 10);
  const totalPage = totalPageResult > 0 ? totalPageResult + 1 : 0;

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);

      await api.store
        .getSurveyAllAnswers(store._id, page)
        .then(async res => {
          setAllAnswers(res.data.answers);
        })
        .catch(error => console.log('error', error));

      setIsLoading(false);
    };
    getData();
  }, [page]);

  const onPageChange = targetPage => {
    setPage(targetPage);
  };

  const onAnswerReply = data => {
    setIsReplyModalOpen(true);
    setSelectedAnswer(data);
  };

  const onCloseReplyModal = data => {
    setIsLoading(true);
    setIsReplyModalOpen(false);
    if (data) {
      const answersData = allAnswers;
      const index = answersData.findIndex(item => item._id === data?._id);
      answersData[index] = data;
      setAllAnswers(answersData);

      setSuccessInfo({
        isActive: true,
        title: 'Başarılı!',
        subtitle: 'Cevap başarı ile gönderildi.'
      });
      setTimeout(() => {
        setSuccessInfo({ isActive: false });
      }, 3000);
    }
    setSelectedAnswer(null);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <div>
          {allAnswers && allAnswers.length > 0 ? (
            <>
              {allAnswers.map(answer => (
                <SurveyAnswerCard
                  key={answer._id}
                  onAnswerReply={onAnswerReply}
                  answer={answer}
                  allSurveys={store.allSurveys}
                />
              ))}
              <AppPagination
                onChange={onPageChange}
                page={page}
                totalPage={totalPage}
              />

              {selectedAnswer && (
                <SurveyReplyModal
                  answer={selectedAnswer}
                  onClose={onCloseReplyModal}
                  isOpen={isReplyModalOpen}
                />
              )}
              {successInfo.isActive && (
                <AppAlert
                  type="success"
                  title={successInfo.title}
                  subtitle={successInfo.subtitle}
                />
              )}
            </>
          ) : (
            <div className="mt-4 text-gray-500 text-sm xl:px-6 xl:py-3 p-3 border rounded-md border-gray-200 shadow-sm bg-white text-center">
              Henüz herhangi bir cevap verilmedi.
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SurveyAnswersTab;
