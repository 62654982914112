import React, { useEffect, useRef, useState } from 'react';
import { DragHandleOutlined } from '@mui/icons-material';
import { AppSelect, AppModal, AppButton } from 'components';
import { useForm } from 'react-hook-form';

const SortMenuProductModal = ({
  data,
  categories,
  onClose,
  isOpen,
  onSave
}) => {
  const [listValues, setListValues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const draggingItem = useRef();
  const dragOverItem = useRef();
  const [draggingIndex, setDraggingIndex] = useState();

  const {
    control,
    watch,
    formState: { errors },
    getValues
  } = useForm({
    defaultValues: {
      category: categories[0]
    }
  });

  useEffect(() => {
    const convertData = () => {
      setIsLoading(true);
      const selectedCategory = getValues('category');
      const listArr = [];
      for (let i = 0; i < data.length; i++) {
        const listItem = data[i];
        if (listItem.category.value === selectedCategory.value) {
          listArr.push(listItem);
        }
      }
      setListValues(listArr);
      setIsLoading(false);
    };
    convertData();
  }, [watch('category')]);

  const closeModal = () => {
    onClose();
  };

  const handleDragStart = (e, position) => {
    draggingItem.current = position;
  };

  const handleDragEnter = (e, position) => {
    dragOverItem.current = position;

    const listCopy = [...listValues];
    const draggingItemContent = listCopy[draggingItem.current];
    listCopy.splice(draggingItem.current, 1);
    listCopy.splice(dragOverItem.current, 0, draggingItemContent);

    draggingItem.current = dragOverItem.current;
    setDraggingIndex(dragOverItem.current);
    dragOverItem.current = null;

    setListValues(listCopy);
  };

  const draggingEnd = () => {
    setDraggingIndex(null);
    draggingItem.current = null;
  };

  return (
    <AppModal
      open={isOpen}
      title="Ürün Sıralama"
      handleClose={() => closeModal()}
    >
      <AppSelect
        options={categories}
        label="Kategori"
        name="category"
        error={errors.category}
        rules={{ required: true }}
        control={control}
        placeholder="Kategori"
      />
      {isLoading ? (
        <></>
      ) : (
        listValues.map((value, index) => (
          <div
            key={index}
            draggable={true}
            onDragStart={e => handleDragStart(e, index)}
            onDragOver={e => {
              e.preventDefault();
            }}
            onDrop={draggingEnd}
            onDragEnter={e => handleDragEnter(e, index)}
            className={`h-16 px-2 border justify-between border-dashed rounded-md  flex flex-row  cursor-grabbing items-center ${
              index > 0 && 'mt-4'
            } ${draggingIndex === index && 'bg-amber-100'} `}
          >
            <div className="flex flex-row items-center h-full">
              <span className="flex h-full items-center text-xs font-medium border-r border-slate-100 border-dashed pr-2">
                #{index + 1}
              </span>

              <img
                className="h-12 w-12 ml-2 rounded-full"
                src={
                  value.photo ||
                  'https://i.pinimg.com/564x/57/29/6f/57296fda6a91216ac5e3b2f14c0416b9.jpg'
                }
              />
              <span className="ml-2 text-slate-900 font-medium">
                {value.name}
              </span>
            </div>
            <DragHandleOutlined />
          </div>
        ))
      )}
      <AppButton
        className="mt-4"
        onClick={() => listValues.length > 0 && onSave(listValues)}
      >
        Kaydet
      </AppButton>
    </AppModal>
  );
};

export default SortMenuProductModal;
