import React from 'react';
import Login from './auth/Login.screen';
import AuthLayout from 'layouts/auth.layout';
import DashboardLayout from 'layouts/dashboard.layout';
import Home from './Home.screen';
import StoreList from './store/StoreList.screen';
import StoreDetail from './store/StoreDetail.screen.js';
import StoreMenuList from './store/StoreMenuList.screen';
import FirstLogin from './auth/Register.screen';
import MyProfile from './MyProfile.screen';
import ForgotPassword from './auth/ForgotPassword.screen';
import ResetPassword from './auth/ResetPassword.screen';
import ConfirmRegister from './auth/ConfirmRegister.screen';
import CampaignList from './campaign';
import AddCampaign from './campaign/AddCampaign.screen';
import CampaignDetail from './campaign/CampaignDetail.screen';
import UpdateCampaign from './campaign/UpdateCampaign.screen';
import CampaignStatistics from './campaign/CampaignStatistics.screen';
import Page404 from './Page404';

const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: '/store',
        element: <StoreList />
      },
      {
        path: '/store/detail',
        element: <StoreDetail />
      },
      {
        path: '/store/menu/list',
        element: <StoreMenuList />
      },
      {
        path: '/campaign',
        element: <CampaignList />
      },
      {
        path: '/campaign/add',
        element: <AddCampaign />
      },
      {
        path: '/campaign/detail',
        element: <CampaignDetail />
      },
      {
        path: '/campaign/update',
        element: <UpdateCampaign />
      },
      {
        path: '/campaign/statistics',
        element: <CampaignStatistics />
      },
      {
        path: '/my-profile',
        element: <MyProfile />
      }
    ]
  },
  {
    element: <AuthLayout />,
    children: [
      {
        path: 'auth/login',
        element: <Login />
      },
      {
        path: 'auth/register',
        element: <FirstLogin />
      },
      {
        path: 'auth/forgot-password',
        element: <ForgotPassword />
      },
      {
        path: 'auth/reset-password/:id/:token',
        element: <ResetPassword />
      },
      {
        path: 'auth/confirm-register/:id/:token',
        element: <ConfirmRegister />
      }
    ]
  },
  {
    path: '*',
    element: <Page404 />
  }
];
export default routes;
