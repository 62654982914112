import AppButton from 'components/AppButton/AppButton.component';
import AppImageCrop from 'components/AppImageCrop/AppImageCrop.component';
import { t } from 'locales';
import React, { useEffect, useState } from 'react';
import ImageUploading from 'react-images-uploading';
import DefaultLogo from '../../assets/images/default-image.png';

function AppFile({
  onFileSaved,
  selectedImage,
  isRequiredSymbolActive,
  error,
  isLabelActive = true,
  label,
  name,
  rules,
  register,
  aspect = 1 / 1
}) {
  const [images, setImages] = useState(selectedImage);
  const [isImageUpload, setIsImageUpload] = useState(false);
  const [isImageCropping, setIsImageCropping] = useState(false);

  useEffect(() => {
    setImages(selectedImage);
  }, [selectedImage]);

  const onChange = imageList => {
    if (imageList.length > 0) {
      setIsImageCropping(true);
      setIsImageUpload(true);
      setImages(imageList);
    } else {
      setIsImageUpload(false);
      setIsImageUpload(false);
      setImages(selectedImage);
    }
  };

  const onImageCrop = croppedImage => {
    setImages(croppedImage);
    onFileSaved(croppedImage?.file);
    setIsImageCropping(false);
  };

  return (
    <div className="w-full mb-2 flex flex-col justify-center items-center">
      {isImageCropping ? (
        <AppImageCrop
          src={images[0]['data_url' || images]}
          onSave={onImageCrop}
          aspect={aspect}
        />
      ) : (
        <>
          {isLabelActive && (
            <label
              htmlFor="appFile"
              className={`text-sm font-medium ${
                error ? 'text-red-600' : 'text-gray-900'
              }`}
            >
              {label || t('_general.photo')}
              {isRequiredSymbolActive && (
                <span className="align-top text-red-600 font-bold ml-1">*</span>
              )}
            </label>
          )}
          <ImageUploading
            value={images}
            onChange={onChange}
            multiple={false}
            maxFileSize={50000000} //50 mb
            dataURLKey="data_url"
          >
            {({ onImageUpload, onImageRemoveAll }) => (
              <div className="flex flex-col items-center justify-center w-full">
                {images ? ( //kaldırdaki re-render problemi için geçici çözüm olarak getirildi.
                  <img
                    onClick={() => {
                      onImageUpload();
                    }}
                    src={images?.url || images || selectedImage}
                    className={`h-16 w-auto object-contain mt-2 mb-4 ${
                      images &&
                      `border rounded-md ${
                        error ? 'border-red-600' : 'border-gray-300'
                      }`
                    }`}
                    {...register(name, rules)}
                  />
                ) : (
                  <img
                    onClick={() => {
                      onImageUpload();
                    }}
                    src={DefaultLogo}
                    className={`h-16 w-auto object-contain mt-2 mb-4 ${
                      images &&
                      `border rounded-md ${
                        error ? 'border-red-600' : 'border-gray-300'
                      }`
                    }`}
                    {...register(name, rules)}
                  />
                )}

                <span className="align-top text-gray-500 mb-2 text-xs">
                  * Maksimum dosya boyutu: 50 MB
                </span>

                <div className="flex flex-row w-full justify-center">
                  {!isImageUpload && (
                    <AppButton
                      isFullWidth={false}
                      onClick={() => {
                        onImageUpload();
                      }}
                    >
                      Fotoğraf Yükle
                    </AppButton>
                  )}

                  {(isImageUpload || selectedImage) && (
                    <AppButton
                      isFullWidth={false}
                      className="bg-red-100 text-red-500 hover:bg-red-200 focus:ring-2 focus:ring-red-500 ml-2"
                      onClick={() => {
                        setIsImageUpload(false);
                        onFileSaved(isImageUpload ? selectedImage : null);
                        setImages(isImageUpload ? selectedImage : null);
                      }}
                    >
                      Kaldır
                    </AppButton>
                  )}
                </div>
              </div>
            )}
          </ImageUploading>
        </>
      )}
    </div>
  );
}

export default AppFile;
