import {
  AppButton,
  AppCurrencyInput,
  AppFile,
  AppInput,
  AppModal,
  AppSelect,
  AppSwitch,
  AppTextArea
} from 'components';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import api from 'services/api';

const AddMenuProductModal = ({ store, onClose, isOpen, categories }) => {
  const [isLoading, setIsLoading] = useState(false);

  //form values
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
    setValue
  } = useForm({
    defaultValues: {
      isVisible: true,
      photo: null
    }
  });

  const onSubmit = async data => {
    setIsLoading(true);

    if (data?.photo) {
      const res = await api.file.upload(data?.photo);
      if (res) {
        data.photo = res?.url;
      }
    }

    if (!data.price.includes(',')) {
      data.price = `${data.price},00`;
    } else {
      const decimalSeparatorIndex = data.price.indexOf(',');
      const decimals = data.price.slice(
        decimalSeparatorIndex + 1,
        data.price.length
      );
      if (decimals.length === 1) {
        data.price = `${data.price}0`;
      }
    }

    const submitData = {
      ...data,
      categoryId: data.categoryId.value,
      storeId: store._id
    };

    try {
      const response = await api.store.addMenuProduct(store._id, submitData);
      onClose(response.data.store);
    } catch (err) {
      console.log(err);
    }

    setIsLoading(false);
  };

  const closeModal = data => {
    reset();
    onClose(data);
  };

  return (
    <AppModal open={isOpen} title="Ürün Ekle" handleClose={() => closeModal()}>
      <div className="w-full justify-center items-center flex flex-col">
        <AppFile
          selectedImage={getValues('photo')}
          label="Fotoğraf"
          register={register}
          name="photo"
          error={errors.photo}
          rules={{ required: false }}
          onFileSaved={url => {
            setValue('photo', url);
          }}
        />
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label="Ürün İsmi (TR)"
          isRequiredSymbolActive={true}
          name="tr.name"
          error={errors.tr?.name}
          rules={{ required: true }}
          register={register}
          autoComplete="off"
          placeholder="Ürün İsmi (TR)"
        />
        <AppInput
          label="Ürün İsmi (EN-İngilizce)"
          name="en.name"
          register={register}
          autoComplete="off"
          placeholder="Ürün İsmi (EN-İngilizce)"
        />
        <AppTextArea
          label="Ürün Açıklaması (TR)"
          name="tr.about"
          register={register}
          autoComplete="off"
          placeholder="Ürün hakkında bilgi verin"
        />
        <AppTextArea
          label="Ürün Açıklaması (EN-İngilizce)"
          name="en.about"
          register={register}
          autoComplete="off"
          placeholder="Ürün hakkında ingilizce bilgi verin "
        />
        <AppSelect
          options={categories}
          label="Kategori"
          name="categoryId"
          error={errors.categoryId}
          isRequiredSymbolActive={true}
          rules={{ required: true }}
          control={control}
          placeholder="Kategori"
        />
        <AppCurrencyInput
          label="Ürün Fiyatı"
          name="price"
          control={control}
          error={errors.price}
          isRequiredSymbolActive={true}
          rules={{ required: true }}
          register={register}
          autoComplete="off"
          placeholder="Örn:₺19,99 "
        />

        <AppSwitch
          control={control}
          name="isVisible"
          label="Ürün Görünürlüğü ?"
        />
        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          Ekle
        </AppButton>
      </form>
    </AppModal>
  );
};

export default AddMenuProductModal;
