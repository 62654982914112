import { AppButton } from 'components';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Page404 = () => {
  const navigate = useNavigate();
  return (
    <section className="bg-white min-h-screen pt-20">
      <div className="py-8 px-4 mx-auto max-w-screen-xl xl:py-16 xl:px-6">
        <div className="mx-auto max-w-screen-sm text-center flex justify-center flex-col items-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold xl:text-9xl text-primary-600">
            4<span className="text-[#FFC907]">0</span>4
          </h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl ">
            Sayfa bulunamadı.
          </p>
          <p className="mb-4 text-lg font-light text-gray-500 ">
            Üzgünüz. Gitmek istediğiniz sayfa mevcut değil. Lütfen doğru adrese
            gittiğinizden emin olun.
          </p>
          <AppButton
            isFullWidth={false}
            className="shadow-lg w-[160px] !text-[#fefefe] hover:!bg-opacity-90 hover:!bg-[#FFC907]  focus:!ring-[#FFC907]  !bg-[#FFC907]"
            onClick={() => navigate('/auth/login')}
          >
            Giriş Ekranına Dön
          </AppButton>
        </div>
      </div>
    </section>
  );
};

export default Page404;
