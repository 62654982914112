// @ts-nocheck
import { apiConfig } from '../config';
import { authApi, fileApi, globalApi, storeApi, campaignApi } from 'services';

const api = apiConfig;

api.auth = authApi(api);
api.global = globalApi(api);
api.store = storeApi(api);
api.file = fileApi();
api.campaign = campaignApi(api);

export default api;
