import { AppButton, AppInput } from 'components';
import { emailPattern } from 'constants/patterns';
import { useAuth } from 'context/AuthContext';
import { t } from 'locales';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { setUser } = useAuth();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError
  } = useForm();

  const onSubmit = async submitData => {
    setIsLoading(true);

    await api.auth
      .login(submitData.email, submitData.password)
      .then(async response => {
        const user = response.data.customer;
        setUser(user);
        if (user.addedStores.length > 0) {
          navigate('/', { replace: true });
        } else {
          navigate('/store', {
            replace: true,
            state: { isAddModalOpen: true }
          });
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response.data.reason === 'email') {
          setError('email', {
            type: 'wrongData',
            message: error.response.data.message
          });
        }
        if (error.response.data.reason === 'password') {
          setError('password', {
            type: 'wrongData',
            message: error.response.data.message
          });
        }
      });
    setIsLoading(false);
  };
  return (
    <main className="p-10 border border-neutral-100 bg-neutral-50 rounded-md">
      <h1 className="font-bold text-3xl text-gray-900">{t('_auth.login')}</h1>
      <p className="text-sm text-gray-500 mt-2">{t('_auth.loginSubtitle')}</p>
      <form className="w-full mt-5" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label={t('_auth.email')}
          name="email"
          error={errors.email}
          rules={{ required: true, pattern: emailPattern }}
          register={register}
          placeholder={t('_auth.email')}
        />
        <AppInput
          label={t('_auth.password')}
          name="password"
          type="password"
          error={errors.password}
          rules={{ required: true }}
          register={register}
          placeholder={t('_auth.password')}
          autoComplete="off"
        />
        <AppButton
          className="!p-3 bg-gray-900 text-white hover:bg-gray-800 focus:ring-2 focus:!ring-black !text-sm"
          type="submit"
          isLoading={isLoading}
        >
          {t('_auth.login')}
        </AppButton>
      </form>

      <AppButton
        onClick={() => navigate('/auth/register')}
        className="!p-3 mt-5 bg-white text-gray-900 hover:bg-gray-50 focus:ring-2 border border-gray-500 focus:!ring-black !text-sm"
      >
        Ücretsiz Kayıt ol
      </AppButton>
      <span
        className="text-xs font-medium text-slate-500 mt-5 block text-center cursor-pointer"
        onClick={() => navigate('/auth/forgot-password')}
      >
        {t('_auth.forgotPassword')}
      </span>
    </main>
  );
};

export default Login;
