import React from 'react';
import { t } from 'locales';

const FormErrorMessage = ({ error, label }) => {
  return (
    <>
      {error && (
        <p className="mt-2 text-sm text-red-600">
          {t(`_formErrorMessages.${error.type}`, { label })}
        </p>
      )}
    </>
  );
};

export default FormErrorMessage;
