export const priceTypes = [
  {
    label: 'Ürün',
    value: 'product'
  },
  {
    label: 'İndirim',
    value: 'discount'
  }
];
