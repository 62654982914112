import React, { useEffect, useState } from 'react';
import { AccountCircle, HighlightOff, Verified } from '@mui/icons-material';
import {
  AppAlert,
  AppButton,
  AppConfirm,
  ChangePasswordModal,
  EditProfileModal,
  ScreenLoading
} from 'components';
import api from 'services/api';
import { useNavigate } from 'react-router-dom';
import { dateToString } from 'utils/date';
import { useAuth } from 'context/AuthContext';

const MyProfile = () => {
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSendConfirmMail, setIsSendConfirmMail] = useState(false);
  const profileDetails = [
    {
      label: 'İsim',
      value: userData?.firstName
    },
    {
      label: 'Soyisim',
      value: userData?.lastName
    },
    {
      label: 'E-posta',
      value: userData?.email
    },
    {
      label: 'Onay Durumu',
      value: userData?.isVerified ? (
        <Verified className="text-blue-500" />
      ) : (
        <HighlightOff className="text-red-500" />
      )
    },
    {
      label: 'Eklenebilecek Toplam İşletme',
      value: userData?.allowedStoreNumber
    },
    {
      label: 'Eklenen İşletme',
      value: userData?.storeIds.length
    },
    {
      label: 'Kampanya Kullanılabilirliği',
      value: userData?.isCampaignEnable ? (
        <span className="px-2 py-1 inline-flex text-xs bg-green-100 text-green-500 font-semibold">
          Etkin
        </span>
      ) : (
        <span className="px-2 py-1 inline-flex text-xs bg-red-100 text-red-500 font-semibold">
          Devre Dışı
        </span>
      )
    },
    {
      label: 'Aktif Edilebilecek Kampanya Sayısı',
      value: userData?.allowedCampaignNumber
    },
    {
      label: 'Hesap Oluşturma Tarihi',
      value: dateToString(userData?.createdAt)
    }
  ];
  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [successInfo, setSuccessInfo] = useState({ isActive: false });

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        const response = await api.global.get('customer', 'me');

        setUserData(response.data.customer);
      } catch (err) {
        console.log(err);
      }
      setIsLoading(false);
    };

    getData();
  }, []);

  const onCloseEditProfileModal = isSubmitted => {
    setIsEditProfileModalOpen(false);
    if (isSubmitted) {
      setSuccessInfo({
        isActive: true,
        title: 'Başarılı!',
        subtitle: 'Bilgileriniz başarıyla güncellendi'
      });
      setTimeout(() => {
        setSuccessInfo({ isActive: false });
      }, 3000);
    }
  };

  const onCloseChangePasswordModal = isSubmitted => {
    setIsChangePasswordModalOpen(false);
    if (isSubmitted) {
      setSuccessInfo({
        isActive: true,
        title: 'Başarılı!',
        subtitle: 'Şifreniz başarıyla güncellendi'
      });
      setTimeout(() => {
        setSuccessInfo({ isActive: false });
      }, 3000);
    }
  };

  const sendConfirmMail = async () => {
    setIsSendConfirmMail(true);
    try {
      await api.auth.sendConfirmMail();
      setSuccessInfo({
        isActive: true,
        title: 'E-posta Gönderildi!',
        subtitle:
          'Hesabınızı onaylamak için e-postanıza bağlantı gönderilmiştir. Hesap onay aktivasyonu için onayladıktan sonra tekrar giriş yapmanız gerekmektedir. Bu yüzden giriş sayfasınza yönlendiriliyorsunuz.'
      });
      setTimeout(() => {
        setUser(false);
        navigate('/auth/login');
      }, 5000);
    } catch (err) {
      console.log(err);
    }
    setIsSendConfirmMail(false);
  };

  const deleteMyProfile = async () => {
    try {
      await api.global.delete('customer', userData._id);
      localStorage.removeItem('user');
      setUser(null);
      navigate('/auth/login', { replace: true });
    } catch (err) {
      console.log(err);
    }
  };

  const onCloseConfirm = () => {
    setIsConfirmOpen(false);
  };

  return (
    <main>
      {isLoading ? (
        <ScreenLoading />
      ) : (
        <div className="bg-white rounded-md border-gray-100 border shadow-sm py-6">
          <div className="px-6 pb-4 w-full flex flex-row justify-between items-center">
            <h3 className="text-gray-900 font-medium text-base xl:text-lg">
              Profil Bilgileri
              <AccountCircle className="ml-2" />
            </h3>
            <div className="flex xl:flex-row flex-col">
              {!userData?.isVerified && (
                <AppButton
                  className="xl:mr-4 mb-2 xl:mb-0"
                  isFullWidth={false}
                  isLoading={isSendConfirmMail}
                  onClick={sendConfirmMail}
                >
                  Hesabımı Onayla
                </AppButton>
              )}
              <AppButton
                className="!bg-slate-100 !text-slate-500  focus:ring-slate-500 hover:!bg-slate-200"
                isFullWidth={false}
                onClick={() => setIsChangePasswordModalOpen(true)}
              >
                Şifre Değiştir
              </AppButton>
              <AppButton
                className="xl:ml-4 mt-2 xl:mt-0  !bg-amber-100 !text-amber-500 focus:!ring-amber-500 hover:!bg-amber-200"
                isFullWidth={false}
                onClick={() => setIsEditProfileModalOpen(true)}
              >
                Düzenle
              </AppButton>
              <AppButton
                className="xl:ml-4 mt-2 xl:mt-0  !bg-red-100 !text-red-500 focus:!ring-red-500 hover:!bg-red-200"
                isFullWidth={false}
                onClick={() => setIsConfirmOpen(true)}
              >
                Hesabımı sil
              </AppButton>
            </div>
          </div>
          <div className="w-full h-px bg-gray-100" />
          <div className="xl:w-2/3 pt-6 px-6 grid grid-col-1 gap-4">
            {profileDetails.map((info, index) => (
              <div key={index} className="flex flex-col xl:flex-row">
                <span className="xl:w-1/2 text-gray-500 text-sm">
                  {info.label}
                </span>
                <span className="text-gray-700 font-medium text-sm mt-1 xl:mt-0">
                  {info.value}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}

      <EditProfileModal
        onClose={onCloseEditProfileModal}
        isOpen={isEditProfileModalOpen}
        setScreenUser={setUserData}
      />
      <ChangePasswordModal
        onClose={onCloseChangePasswordModal}
        isOpen={isChangePasswordModalOpen}
      />
      {successInfo.isActive && (
        <AppAlert
          type="success"
          title={successInfo.title}
          subtitle={successInfo.subtitle}
        />
      )}

      <AppConfirm isOpen={isConfirmOpen} handleClose={onCloseConfirm}>
        <div className="flex flex-col mt-1">
          <span className="text-sm font-medium text-gray-900">
            Hesabınızı silerseniz işletmeleriniz de silinecektir ve tekrar geri
            alamazsınız. Bunu yapmak istediğinize emin misiniz ?
          </span>
          <div className="flex flex-row mt-3">
            <AppButton
              onClick={() => setIsConfirmOpen(false)}
              className="mr-3 !bg-white !text-gray-500 focus:!ring-gray-500 hover:!bg-gray-200 border border-gray-500"
            >
              İptal Et
            </AppButton>
            <AppButton
              onClick={() => {
                deleteMyProfile();
              }}
              className="ml-3 !bg-white !text-red-500 focus:!ring-red-500 hover:!bg-red-300 border border-red-500"
            >
              Onayla
            </AppButton>
          </div>
        </div>
      </AppConfirm>
    </main>
  );
};

export default MyProfile;
