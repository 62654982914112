import React, { useEffect, useState } from 'react';
import api from 'services/api';
import { AppText } from 'components';
import { timeAgo } from 'utils/date';
import Skeleton from 'react-loading-skeleton';
import { levels } from 'constants/levels';
import { Bear, DefaultUser } from 'assets/images';

const WorkerAnswerCard = ({ answer, storeWorkers }) => {
  const worker = storeWorkers.find(item => item._id === answer.workerId);
  const [user, setUser] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      await api.global
        .get('customer/user', answer.userId)
        .then(async res => {
          setUser({
            ...res.data.user,
            level: levels(res.data.user).find(level => level.selected)
          });
        })
        .catch(error => {
          setUser(null);
          console.log('error', error);
        });
      setIsLoading(false);
    };
    getData();
  }, []);

  const asterisks = (name = 'Enis') => {
    const asterisks = '*'.repeat(name.length - 1);
    return `${asterisks}`;
  };

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton width="10%" className="mt-7 " />
          <span className="flex flex-row mt-2">
            <Skeleton width="50px" height="50px" circle={true} />
            <span className="ml-4">
              <Skeleton width="150px" />
              <Skeleton width="100px" />
            </span>
          </span>
          <Skeleton width="40%" className="mt-2" />
          <Skeleton width="100%" className="mt-2" count={2} />
        </>
      ) : (
        <div className="mt-4 xl:px-6 xl:py-3 p-3 border rounded-md border-gray-200 relative shadow-sm bg-white flex-row flex justify-between">
          <span className="flex flex-col justify-center w-full">
            <span className="flex flex-row w-full relative">
              <span className="text-xs font-medium text-gray-400">
                {timeAgo(answer.createdAt, new Date())}
              </span>
              <span className="flex flex-col items-center max-w-fit min-w-fit absolute top-0 right-0">
                {user ? (
                  <>
                    <span className="flex-row flex font-medium text-xs text-center">
                      Kullanıcı
                    </span>
                    <span className="inline-block align-bottom text-gray-400 text-xs text-center">
                      {user?.firstName && (
                        <>
                          {user?.firstName[0]}
                          <span className="align-sub mr-1">
                            {asterisks(user?.firstName)}
                          </span>
                        </>
                      )}
                      {user?.lastName && (
                        <>
                          {user?.lastName[0]}
                          <span className="align-sub">
                            {asterisks(user?.lastName)}
                          </span>
                        </>
                      )}
                    </span>
                    <span className="flex flex-row items-center text-gray-400 text-xs text-center">
                      <img src={user?.level.image} className="w-6 h-6 mr-2" />
                      {user?.level.title}
                    </span>
                  </>
                ) : (
                  <span className="inline-block align-bottom text-gray-400 text-xs text-center">
                    Kullanıcı bulunamadı
                  </span>
                )}
              </span>
            </span>
            <span className="flex flex-row mt-2 items-center">
              <img
                src={worker?.photo || DefaultUser}
                className="w-12 h-12 rounded-full object-cover"
              />
              <span className="flex flex-col ml-4">
                <AppText
                  className="text-gray-900 text-sm font-medium mt-1"
                  text={
                    worker?.firstName
                      ? worker?.firstName + ' ' + worker?.lastName
                      : 'Çalışan silindi'
                  }
                />

                {worker?.title && (
                  <AppText
                    className="text-gray-500 text-xs font-medium mt-1"
                    text={worker?.title}
                  />
                )}
              </span>
            </span>
            <span className="mt-2 flex flex-col w-full">
              <span className="flex flex-row items-center">
                <AppText
                  text="Değerlendirme:"
                  className="font-medium xl:text-sm text-xs"
                />
                <span className="ml-1">
                  {[...Array(5)].map((item, index) => (
                    <span
                      key={index}
                      className={`${
                        answer.ratePoint >= index + 1
                          ? 'text-amber-400'
                          : 'text-gray-300'
                      } mr-1 xl:text-lg text-base leading-5`}
                    >
                      ★
                    </span>
                  ))}
                </span>
              </span>
              {answer.comment && (
                <span className="flex flex-row mt-1">
                  <AppText
                    text="Yorum:"
                    className="font-medium xl:text-sm text-xs xl:leading-6 leading-5"
                  />
                  <p className="xl:text-sm text-xs ml-1 text-gray-500 xl:leading-6 leading-5">
                    {answer.comment}
                  </p>
                </span>
              )}
            </span>
          </span>
        </div>
      )}
    </>
  );
};

export default WorkerAnswerCard;
