import { useRoutes } from 'react-router-dom';
import routes from 'pages';
import 'assets/style/main.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-color-palette/css';

function App() {
  return useRoutes(routes);
}

export default App;
