import React from 'react';
import { Modal } from '@mui/material';
import { AppTitle } from 'components';
import { Close } from '@mui/icons-material';

const AppModal = ({ open, handleClose, title, children }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="overflow-y-scroll overflow-x-hidden flex flex-col justify-center xl:items-center pl-4 pr-4  mt-4"
    >
      <div
        className={`xl:w-1/3 w-full mr-4 customScroll overflow-x-hidden bg-white rounded-md justify-center items-center`}
      >
        <div className="flex-row flex relative justify-between w-full items-center xl:p-6 p-4 border-b border-gray-200">
          <AppTitle
            title={title}
            className="xl:text-xl text-base font-medium text-gray-800"
          />
          <Close
            fontSize="small"
            className="cursor-pointer"
            onClick={handleClose}
          />
        </div>
        <div
          className={`overflow-y-auto px-1 py-1  overflow-x-hidden xl:m-6 m-4`}
        >
          {children}
        </div>
      </div>
    </Modal>
  );
};

export default AppModal;
