const defaultLocale = 'tr'; //Dil konumuna göre değişecek

export const convertMenuData = store => {
  const allCategories = [];

  let allProducts = [];
  if (store.menu && store.menu.length > 0) {
    for (let index = 0; index < store.menu.length; index++) {
      const productCategory = store.menu[index];
      const categoryItem = {
        label:
          productCategory.categoryName ||
          productCategory[defaultLocale].categoryName,
        value: productCategory._id
      };
      for (let i = 0; i < productCategory.categoryProductList.length; i++) {
        const product = productCategory.categoryProductList[i];

        product.name = product[defaultLocale]?.name;
        product.about = product[defaultLocale]?.about;
        product.categoryId = categoryItem.label;
        product.category = categoryItem;
      }

      allCategories.push(categoryItem);
      allProducts = allProducts.concat(productCategory.categoryProductList);
    }
  }
  return { convertedCategories: allCategories, convertedProducts: allProducts };
};

export const convertCategoryData = store => {
  const allCategories = [];

  if (store.menu && store.menu.length > 0) {
    for (let index = 0; index < store.menu.length; index++) {
      const productCategory = store.menu[index];
      productCategory.name = productCategory[defaultLocale]?.categoryName;
      allCategories.push(productCategory);
    }
  }
  return allCategories;
};

export const convertMenuTemplateData = data => {
  const convertedTemplates = [];

  for (let i = 0; i < data.length; i++) {
    const template = data[i];

    const index = convertedTemplates.findIndex(
      item => item.categoryName === template.categoryName
    );

    if (index > -1) {
      convertedTemplates[index].photos.push({
        _id: template._id,
        photo: template.photo
      });
    } else {
      const templateDetail = {
        categoryName: template.categoryName,
        photos: [{ _id: template._id, photo: template.photo }]
      };
      convertedTemplates.push(templateDetail);
    }
  }

  convertedTemplates.sort((a, b) => {
    const firstItem = a.categoryName.toLocaleLowerCase('tr');
    const secondItem = b.categoryName.toLocaleLowerCase('tr');
    if (secondItem > firstItem) {
      return -1;
    }
    if (firstItem > secondItem) {
      return 1;
    }
    return 0;
  });

  return convertedTemplates;
};
