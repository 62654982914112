import axios from 'axios';

export const fileApi = () => ({
  async upload(file) {
    const data = new FormData();
    if (typeof file === 'string') {
      const convertedFile = new File([file], 'image', {
        type: 'image/jpeg'
      });
      data.append('file', convertedFile);
    } else {
      data.append('file', file);
    }
    const response = await axios.post(
      'https://api.repidas.com/api/v1/file/upload',
      data,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    if (response.status === 200) {
      return response?.data;
    } else {
      console.log(response);
      return null;
    }
  }
});
