import { AppButton, AppInput } from 'components';
import { emailPattern } from 'constants/patterns';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';

const Register = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isMailSubmit, setIsMailSubmit] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError
  } = useForm({});

  const onSubmit = async submitData => {
    setIsLoading(true);
    try {
      await api.auth.register(submitData);
      setIsMailSubmit(true);
    } catch (err) {
      if (err.response.data.reason === 'exists') {
        setError('email', {
          type: 'emailExists',
          message: err.response.data.message
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <main className="p-10 border xl:max-w-[720px] xl:w-[720px]  border-neutral-100 bg-neutral-50 rounded-md">
      <h1 className="text-gray-900 font-bold text-2xl">Ücretsiz Kayıt ol</h1>
      <p className="mt-2 text-gray-500 text-sm">
        Repidas&apos;ı 1 ay ücretsiz denemek için bilgilerinizi doldurun,
        ardından giriş yapın ve işletmenizi ekleyin.
      </p>
      <p className="text-gray-500 text-xs mt-1">* Kredi kartı gerekmez.</p>
      <form className="w-full mt-5" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label="İsim"
          name="firstName"
          error={errors.firstName}
          rules={{ required: true }}
          isRequiredSymbolActive={true}
          register={register}
          placeholder="İsim"
          autoComplete="off"
        />
        <AppInput
          label="Soyisim"
          name="lastName"
          error={errors.lastName}
          rules={{ required: true }}
          isRequiredSymbolActive={true}
          register={register}
          placeholder="Soyisim"
          autoComplete="off"
        />
        <AppInput
          label="E-posta"
          name="email"
          error={errors.email}
          isRequiredSymbolActive={true}
          rules={{ required: true, pattern: emailPattern }}
          register={register}
          placeholder="E-posta"
        />
        <AppInput
          label="Şifre"
          name="password"
          isRequiredSymbolActive={true}
          error={errors.password}
          rules={{ required: true }}
          register={register}
          type="password"
          placeholder="Şifre"
          autoComplete="off"
        />
        {isMailSubmit && (
          <div className="mb-4 bg-emerald-200 p-3 rounded-md flex flex-row items-center">
            <p className="text-emerald-700 text-xs">
              Kaydınızı onaylamanız için e-postanıza link gönderilmiştir.Linki
              onaylayıp giriş ekranından bilgilerinizi girerek giriş
              yapabilirsiniz.
            </p>
            <AppButton
              className="ml-4 w-40 bg-white text-gray-900 border border-emerald-900 hover:bg-gray-200 focus:ring-2 focus:!ring-black"
              type="button"
              onClick={() => navigate('/auth/login')}
              isFullWidth={false}
            >
              Giriş Yap
            </AppButton>
          </div>
        )}
        <AppButton
          className="!p-3 bg-gray-900 text-white hover:bg-gray-800 focus:ring-2 focus:ring-black !text-sm"
          type="submit"
          isLoading={isLoading}
        >
          Kayıt Ol
        </AppButton>
        <p
          onClick={() => navigate('/auth/login')}
          className="cursor-pointer text-center mt-4 text-xs font-medium text-slate-500"
        >
          Hesabın var mı ?{' '}
          <span className="text-blue-500">Hemen giriş yap.</span>
        </p>
      </form>
    </main>
  );
};

export default Register;
