const PREFIX = 'customer';

export const globalApi = api => ({
  list(type, page, sort = 1) {
    return api.get(`/${PREFIX}/list/${type}/${page}/${sort}`);
  },
  get(key, id) {
    return api.get(`/${key}/get/${id}`);
  },
  update(key, id, params) {
    return api.put(`/${key}/update/${id}`, params);
  },
  delete(key, id) {
    return api.delete(`/${key}/delete/${id}`);
  }
});
