const PREFIX = 'store';

export const storeApi = api => ({
  //store
  myStores() {
    return api.get(`/${PREFIX}/getAll`);
  },
  addStore(store) {
    return api.post(`/${PREFIX}/add`, {
      store
    });
  },

  //survey
  addSurvey(storeId, survey) {
    return api.post(`/${PREFIX}/${storeId}/survey/add`, {
      survey
    });
  },
  updateVisibleSurvey(storeId, surveyId) {
    return api.put(`/${PREFIX}/${storeId}/survey/update-visible/${surveyId}`);
  },
  updateSurvey(storeId, surveyId, survey) {
    return api.put(`/${PREFIX}/${storeId}/survey/update/${surveyId}`, {
      survey
    });
  },
  deleteSurvey(storeId, surveyId) {
    return api.delete(`/${PREFIX}/${storeId}/survey/delete/${surveyId}`);
  },
  getAllSurveyTemplates() {
    return api.get(`/${PREFIX}/survey/template`);
  },
  getSurveyAllAnswers(storeId, page) {
    return api.get(`/${PREFIX}/${storeId}/survey/answers/getAll/${page}`);
  },
  addSurveyReply(answerId, reply) {
    return api.put(`/${PREFIX}/survey/reply/add/${answerId}`, {
      reply
    });
  },

  //worker
  deleteWorker(storeId, workerId) {
    return api.delete(`/${PREFIX}/${storeId}/worker/delete/${workerId}`);
  },
  addStoreWorker(storeId, workerModel) {
    return api.post(`/${PREFIX}/${storeId}/worker/add`, {
      workerModel
    });
  },
  getStoreWorkerAllAnswers(storeId, page) {
    return api.get(`/${PREFIX}/${storeId}/worker/answers/getAll/${page}`);
  },
  updateStoreWorker(storeId, workerId, data) {
    return api.put(`/${PREFIX}/${storeId}/worker/update/${workerId}`, { data });
  },

  //menu
  addMenuProduct(storeId, product) {
    return api.post(`/${PREFIX}/${storeId}/menu/product/add`, {
      product
    });
  },
  updateMenuProduct(storeId, product) {
    return api.put(`/${PREFIX}/${storeId}/menu/product/update`, {
      product
    });
  },
  sortMenuProductList(storeId, categoryId, productList) {
    return api.put(`/${PREFIX}/${storeId}/menu/${categoryId}/product/sort`, {
      productList
    });
  },
  deleteMenuProduct(storeId, categoryId, productId) {
    return api.delete(
      `/${PREFIX}/${storeId}/menu/${categoryId}/product/${productId}/delete`
    );
  },
  addMenuCategory(storeId, category) {
    return api.post(`/${PREFIX}/${storeId}/menu/category/add`, {
      category
    });
  },
  updateMenuCategory(storeId, categoryId, category) {
    return api.put(`/${PREFIX}/${storeId}/menu/category/${categoryId}/update`, {
      category
    });
  },
  sortMenuCategoryList(storeId, categoryList) {
    return api.put(`/${PREFIX}/${storeId}/menu/category/sort`, {
      categoryList
    });
  },
  deleteMenuCategory(storeId, categoryId) {
    return api.delete(`/${PREFIX}/${storeId}/menu/${categoryId}/delete`);
  }
});
