import AppButton from 'components/AppButton/AppButton.component';
import AppConfirm from 'components/AppConfirm/AppConfirm.component';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { dateToStringWithTime } from 'utils/date';

const CampaignListCard = ({ campaign, isMoreDetail, campaignStores }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [stores, setStores] = useState([]);
  const [campaignDetails, setCampaignDetails] = useState([]);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  useEffect(() => {
    const getStoreDetails = async () => {
      setIsLoading(true);
      const resStores = [];

      for (let i = 0; i < campaign.enabledStores.length; i++) {
        const storeId = campaign.enabledStores[i];
        try {
          const response = await api.global.get('store', storeId);
          resStores.push(response.data.store);
        } catch (error) {
          console.log('get store error', error);
        }
      }

      const campaignPrice = campaign.price;
      let details = [];

      if (campaignPrice.priceType === 'product') {
        const productStore = resStores.find(
          item => item._id === campaign.price.productStoreId
        );
        let product;

        for (let i = 0; i < productStore.menu.length; i++) {
          const category = productStore.menu[i];

          const index = category.categoryProductList.findIndex(
            product => product._id.toString() === campaignPrice.productId
          );

          if (index > -1) {
            product = category.categoryProductList[index];
            break;
          }
        }
        details = [
          {
            label: 'Ödül Türü',
            value: 'Ürün'
          },
          {
            label: 'Ürünün Bulunduğu İşletme',
            value: productStore.storeName
          },
          {
            label: 'Ürün İsmi',
            value: product?.tr?.name
          },
          {
            label: 'Ödül Ürün Sayısı',
            value: campaignPrice.productCount
          },
          {
            label: 'Gerekli Anket Sayısı',
            value: campaign.requiredSurveyCount
          }
        ];
      } else {
        details = [
          {
            label: 'Ödül Türü',
            value: 'İndirim'
          },
          {
            label: 'İndirim Tutarı',
            value: `${campaignPrice.discount} ₺`
          },
          {
            label: 'Gerekli Anket Sayısı',
            value: campaign.requiredSurveyCount
          }
        ];
      }

      if (isMoreDetail) {
        details = [
          ...details,
          {
            label: 'Oluşturma Tarihi',
            value: dateToStringWithTime(new Date(campaign.createdAt))
          },
          {
            label: 'Son Güncelleme',
            value: dateToStringWithTime(new Date(campaign.updatedAt))
          }
        ];
      }

      if (campaignStores) {
        campaignStores(resStores);
      }
      setCampaignDetails(details);
      setStores(resStores);
      setIsLoading(false);
    };
    getStoreDetails();
  }, []);

  const onCloseConfirm = () => {
    setIsConfirmOpen(false);
  };

  const deleteCampaign = async () => {
    try {
      const response = await api.campaign.deleteCampaign(campaign._id);

      navigate('/campaign');
    } catch (error) {
      console.log(error, 'delete campaign error');
    }
  };

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <div className="py-4 px-6 bg-white rounded-md border border-gray-150 relative">
          <div className="flex flex-row justify-between items-center">
            <p className="text-lg font-medium text-slate-700 tracking-tight mr-4">
              {campaign?.title}
            </p>
            {campaign.isActive ? (
              <span className="px-4 py-1 border border-green-300 rounded-sm flex items-center text-xs bg-green-100 text-green-500 font-semibold">
                Aktif
              </span>
            ) : (
              <span className="px-4 py-1 border border-red-300 rounded-sm flex items-center text-xs bg-red-100 text-red-500 font-semibold">
                Devre Dışı
              </span>
            )}
          </div>
          <div className="mt-8">
            {campaignDetails.map((item, index) => (
              <div
                key={index}
                className="grid grid-cols-2 gap-4 border-b mb-4 pb-2"
              >
                <p className="text-sm text-gray-900 tracking-tight font-medium">
                  {item.label}
                </p>
                <p className="text-sm text-gray-900 font-medium tracking-tight">
                  {item.value}
                </p>
              </div>
            ))}
            <p className="text-sm text-gray-900 tracking-tight font-medium mb-2">
              Kampanyaya Dahil Olan İşletmeler:
            </p>
            {stores.map((store, index) => (
              <div
                key={store._id}
                className="border-b mb-4 h-12 pb-2 grid grid-cols-2 gap-4 items-center"
              >
                <p className="text-sm text-gray-900 tracking-tight font-medium">
                  {index + 1}. İşletme
                </p>
                <div className="flex flex-row items-center">
                  {store?.logo && (
                    <img
                      className="w-10 h-10 object-contain"
                      src={store?.logo}
                    />
                  )}
                  <p
                    className={`text-sm text-gray-900 font-medium tracking-tight ${
                      store?.logo && 'ml-2'
                    }`}
                  >
                    {store?.storeName}
                  </p>
                </div>
              </div>
            ))}
            {isMoreDetail && (
              <>
                <p className="text-sm text-gray-900 tracking-tight font-medium mb-2">
                  Kampanyayı Uygulayabilecek E-posta Adresleri:
                </p>
                {campaign.enabledMailAdresses.map((mail, index) => (
                  <div
                    key={index}
                    className="border-b mb-4 h-12 pb-2 grid grid-cols-2 gap-4 items-center"
                  >
                    <p className="text-sm text-gray-900 tracking-tight font-medium">
                      {index + 1}. E-posta
                    </p>

                    <p
                      className={`text-sm text-gray-900 font-medium tracking-tight `}
                    >
                      {mail}
                    </p>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="flex justify-start mt-4">
            <AppButton
              onClick={() =>
                isMoreDetail
                  ? navigate(`/campaign/update?id=${campaign._id}`)
                  : navigate(`/campaign/detail?id=${campaign._id}`)
              }
              className="!text-xs !bg-orange-100 !text-orange-500 focus:!ring-orange-500 hover:!bg-orange-100"
              isFullWidth={false}
            >
              {isMoreDetail ? 'Kampanyayı Güncelle' : 'Kampanya Detayı'}
            </AppButton>
            {isMoreDetail && (
              <AppButton
                className="!text-xs ml-4 !bg-red-100 !text-red-500 focus:!ring-red-500 hover:!bg-red-200"
                isFullWidth={false}
                onClick={() => setIsConfirmOpen(true)}
              >
                Kampanyayı sil
              </AppButton>
            )}
          </div>
          <AppConfirm isOpen={isConfirmOpen} handleClose={onCloseConfirm}>
            <div className="flex flex-col mt-2">
              <span className="text-sm text-gray-900">
                Bu kampanyayı sildiğinizde, kampanyaya dair her şey silinecektir
                ve tekrar geri alamazsınız. Bunu yapmak istediğinize emin
                misiniz ?
              </span>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <AppButton
                  onClick={() => setIsConfirmOpen(false)}
                  className="!bg-white !text-gray-500 focus:!ring-gray-500 hover:!bg-gray-200 border border-gray-500"
                >
                  İptal Et
                </AppButton>
                <AppButton
                  onClick={() => {
                    deleteCampaign();
                  }}
                  className="!bg-white !text-red-500 focus:!ring-red-500 hover:!bg-red-300 border border-red-500"
                >
                  Onayla
                </AppButton>
              </div>
            </div>
          </AppConfirm>
        </div>
      )}
    </>
  );
};

export default CampaignListCard;
