import {
  AppModal,
  AppButton,
  AppInput,
  AppFile,
  AppSwitch,
  AppLocationSelect,
  AppSelect
} from 'components';
import { citiesTr } from 'constants/city';
import { defaultStoreThemeColor, storeTypes } from 'constants/store';
import { t } from 'locales';
import React, { useEffect, useState } from 'react';
import { ColorPicker, useColor } from 'react-color-palette';
import { useForm } from 'react-hook-form';
import api from 'services/api';
import { getFileId } from 'utils/file';

const StoreInfoModal = ({ store, onClose, isOpen }) => {
  const initialStoreType = storeTypes.find(
    type => type.value === store.storeType
  );
  const initialCity = citiesTr.find(city => city.value === store.location.city);
  const initialDistrict = initialCity.districts.find(
    district => district.value === store.location.district
  );
  const [districts, setDistricts] = useState(initialCity.districts);

  const initialColor = store?.themeColor || defaultStoreThemeColor;
  const formattedInitialColor = useColor(initialColor)[0];
  const [color, setColor] = useColor(initialColor);
  const [isColorPaletteOpen, setIsColorPaletteOpen] = useState(false);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
    getValues,
    reset,
    setValue
  } = useForm({
    defaultValues: {
      logo: store.logo,
      storeName: store.storeName,
      isMenuActive: store.isMenuActive,
      position: store.location.position,
      city: initialCity,
      district: initialDistrict,
      storeType: initialStoreType,
      wifiName: store?.socialLinks.wifiName || null,
      wifiPassword: store?.socialLinks.wifiPassword || null,
      instagram: store?.socialLinks.instagram || null,
      facebook: store?.socialLinks.facebook || null,
      twitter: store?.socialLinks.twitter || null,
      tiktok: store?.socialLinks.tiktok || null,
      youtube: store?.socialLinks.youtube || null,
      themeColor: initialColor
    }
  });

  useEffect(() => {
    const selected = watch('city');

    if (selected?.value !== initialCity.value) {
      setValue('district', null);
      const city = citiesTr.find(item => item.label === selected.label);

      setDistricts(city.districts);
    } else {
      setDistricts(initialCity.districts);
    }
  }, [watch('city')]);

  const onSubmit = async data => {
    let submitData = {
      storeName: data.storeName,
      storeType: data.storeType.value,
      location: {
        position: data.position,
        city: data.city.value,
        district: data.district.value
      },
      isMenuActive: data.isMenuActive,
      logo: data.logo,
      wifiName: data?.wifiName || null,
      wifiPassword: data?.wifiPassword || null,
      tiktok: data?.tiktok || null,
      instagram: data?.instagram || null,
      facebook: data?.facebook || null,
      youtube: data?.youtube || null,
      twitter: data?.twitter || null,
      themeColor: data.themeColor
    };
    if (data?.logo !== store?.logo) {
      if (store?.logo) {
        const fileId = getFileId(store.logo);
        const deletedOldFile = await api.global.delete('file', fileId);
      }
      if (data?.logo) {
        const res = await api.file.upload(data?.logo);
        if (res) {
          submitData.logo = res?.url;
        }
      }
    }

    if (data.themeColor === initialColor) {
      setColor(formattedInitialColor);
    }

    try {
      const response = await api.global.update('store', store._id, submitData);

      onClose(response.data.store);
    } catch (err) {
      console.log(err);
    }
    setIsColorPaletteOpen(false);
  };

  const closeModal = () => {
    setColor(formattedInitialColor);
    setIsColorPaletteOpen(false);
    reset();
    onClose();
  };

  const onThemeChanged = () => {
    setValue('themeColor', color.hex);
    setIsColorPaletteOpen(false);
  };

  return (
    <AppModal
      open={isOpen}
      title="İşletme Bilgilerini Güncelle"
      handleClose={() => closeModal()}
    >
      <AppFile
        name="logo"
        error={errors.logo}
        rules={{ required: false }}
        register={register}
        selectedImage={getValues('logo')}
        label="Logo"
        onFileSaved={url => {
          setValue('logo', url);
        }}
      />
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full items-center flex flex-col"></div>
        <AppInput
          label="İşletme İsmi"
          isRequiredSymbolActive={true}
          name="storeName"
          control={control}
          error={errors.storeName}
          rules={{ required: true }}
          register={register}
          placeholder="İşletme İsmi"
        />
        <AppSelect
          options={storeTypes}
          label="İşletme Türü"
          name="storeType"
          error={errors.storeType}
          isRequiredSymbolActive={true}
          rules={{ required: true }}
          control={control}
          placeholder="İşletme türü"
        />
        <AppSelect
          options={citiesTr}
          label="İşletme İl"
          name="city"
          error={errors.city}
          isRequiredSymbolActive={true}
          rules={{ required: true }}
          control={control}
          placeholder="İşletme hangi ilde ?"
        />
        <AppSelect
          isDisabled={districts.length === 0}
          options={districts}
          label="İşletme İlçe"
          name="district"
          error={errors.district}
          isRequiredSymbolActive={true}
          rules={{ required: true }}
          control={control}
          placeholder="Önce il seçin"
        />
        <AppLocationSelect
          control={control}
          name="position"
          error={errors.position}
          rules={{ required: true }}
          register={register}
        />
        <div className="mb-5">
          <div className="flex w-full flex-row justify-between">
            <div>
              <span
                className={`w-full inline-flex mb-2 text-sm font-medium text-gray-900`}
              >
                İşletme Tema Rengi
              </span>
              <div className="flex flex-row mb-2 items-center">
                <span className={`w-full flex  text-sm font-medium `}>
                  {color.hex}
                </span>
                <span
                  style={{ backgroundColor: color.hex }}
                  className="w-16 h-4 flex border border-gray-200"
                />
              </div>
            </div>
            {!isColorPaletteOpen && (
              <AppButton
                onClick={() => setIsColorPaletteOpen(true)}
                type="button"
                className="!h-8"
                isFullWidth={false}
              >
                Renk Değiştir
              </AppButton>
            )}
          </div>
          {isColorPaletteOpen && (
            <>
              <ColorPicker
                hideInput={['rgb', 'hsv']}
                color={color}
                onChange={color => setColor(color)}
              />
              <div className="flex flex-row justify-center mt-2">
                <AppButton
                  className="bg-red-100 text-red-500 hover:bg-red-200 focus:ring-2 focus:ring-red-500 mr-2 !h-8"
                  onClick={() => {
                    setColor(formattedInitialColor);
                    setIsColorPaletteOpen(false);
                  }}
                >
                  İptal Et
                </AppButton>
                <AppButton
                  onClick={onThemeChanged}
                  type="button"
                  className="!h-8 "
                >
                  Onayla
                </AppButton>
              </div>
            </>
          )}
        </div>

        <AppInput
          label="İnstagram"
          name="instagram"
          control={control}
          register={register}
          placeholder="İnstagram Linki"
        />
        <AppInput
          label="Tiktok"
          name="tiktok"
          control={control}
          register={register}
          placeholder="Tiktok Linki"
        />
        <AppInput
          label="Youtube"
          name="youtube"
          control={control}
          register={register}
          placeholder="Youtube Linki"
        />
        <AppInput
          label="Twitter/X"
          name="twitter"
          control={control}
          register={register}
          placeholder="Twitter/X Linki"
        />
        <AppInput
          label="Facebook"
          name="facebook"
          control={control}
          register={register}
          placeholder="Facebook Linki"
        />
        <AppInput
          label="Wi-Fi İsmi"
          name="wifiName"
          control={control}
          register={register}
          placeholder="Wi-Fi İsmi"
        />
        <AppInput
          label="Wi-Fi Şifresi"
          name="wifiPassword"
          control={control}
          register={register}
          placeholder="Wi-Fi Şifresi"
        />
        <AppSwitch
          control={control}
          name="isMenuActive"
          label="Menü Aktif mi ?"
        />
        <AppButton type="submit">{t('_general.save')}</AppButton>
      </form>
    </AppModal>
  );
};

export default StoreInfoModal;
