import { calculateWorkerPoint } from './workers';

export const arrangeStoreDetails = store => {
  const visibleSurvey = store.allSurveys.find(
    survey => survey._id === store.visibleSurveyId
  );
  store.visibleSurvey = visibleSurvey;

  for (let i = 0; i < store.storeWorkers.length; i++) {
    const worker = store.storeWorkers[i];
    if (typeof worker.ratePoint !== 'string') {
      worker.ratePoint = calculateWorkerPoint(
        worker.ratePoint,
        worker.rateCount
      );
    }
  }

  return store;
};
