import React, { useEffect, useState } from 'react';
import { DoneAll, HighlightOff } from '@mui/icons-material';
import { AppButton } from 'components';
import { useNavigate, useParams } from 'react-router-dom';
import api from 'services/api';

const ConfirmRegister = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const params = useParams();
  const { id, token } = params;

  useEffect(() => {
    const confirmCustomer = async () => {
      setIsLoading(true);
      try {
        await api.auth.confirmRegister(id, token);
        setIsSuccess(true);
      } catch (err) {
        setIsSuccess(false);
      }
      setIsLoading(false);
    };
    confirmCustomer();
  }, []);

  return (
    <main className="p-10 border max-w-[520px] flex flex-col items-center justify-center border-neutral-100 bg-neutral-50 rounded-md">
      {isLoading ? (
        <span className="text-gray-500 font-medium tracking-wide">
          Kaydınız onaylanıyor lütfen bekleyiniz...
        </span>
      ) : (
        <>
          {isSuccess ? (
            <>
              <span className="flex items-center">
                <h1 className="text-green-500 font-semibold text-2xl">
                  Onaylandı
                </h1>
                <DoneAll fontSize="medium" className="ml-4 text-green-500" />
              </span>
              <p className="mt-2 text-gray-500 text-sm">
                Kaydınız onaylanmıştır. Repidas giriş ekranından giriş
                yapabilirsiniz.
              </p>
            </>
          ) : (
            <>
              <span className="flex items-center flex-wrap">
                <h1 className="text-red-500 font-semibold text-2xl">
                  Onaylanamadı
                </h1>
                <HighlightOff fontSize="medium" className="ml-2 text-red-500" />
              </span>
              <p className="mt-2 text-gray-500 text-sm">
                Kaydınız onaylanamadı. Size gönderdiğimiz linkin süresi dolmuş
                veya hesabınız daha önce onaylanmış olabilir. Eğer
                onaylanmamışsa sisteme giriş yaparak yeni bir onaylama link
                alabilirsiniz.
              </p>
            </>
          )}
          <AppButton
            className="mt-4 bg-white text-gray-900 border border-emerald-900 hover:bg-gray-200 focus:ring-2 focus:!ring-black"
            type="submit"
            onClick={() => navigate('/auth/login')}
          >
            Giriş yap
          </AppButton>
        </>
      )}
    </main>
  );
};

export default ConfirmRegister;
