import { AppButton, AppFile, AppInput, AppModal, AppSwitch } from 'components';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import api from 'services/api';

const AddWorkerModal = ({ store, onClose, isOpen }) => {
  const [isLoading, setIsLoading] = useState(false);

  //form values
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
    setValue
  } = useForm({
    defaultValues: {
      isActive: true,
      photo: null
    }
  });

  const onSubmit = async data => {
    setIsLoading(true);
    if (data?.photo) {
      const res = await api.file.upload(data?.photo);
      if (res) {
        data.photo = res?.url;
      }
    }
    try {
      const response = await api.store.addStoreWorker(store._id, data);
      onClose(response.data.store);
    } catch (err) {
      console.log(err);
    }

    setIsLoading(false);
  };

  const closeModal = data => {
    reset();
    onClose(data);
  };

  return (
    <AppModal
      open={isOpen}
      title="Çalışan Ekle"
      handleClose={() => closeModal()}
    >
      <AppFile
        name="photo"
        error={errors.photo}
        rules={{ required: false }}
        register={register}
        selectedImage={getValues('photo')}
        label="Fotoğraf"
        onFileSaved={url => {
          setValue('photo', url);
        }}
      />
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full items-center flex flex-col"></div>
        <AppInput
          label="İsim"
          name="firstName"
          error={errors.firstName}
          isRequiredSymbolActive={true}
          rules={{ required: true }}
          register={register}
          autoComplete="off"
          placeholder="İsim"
        />
        <AppInput
          label="Soyisim"
          name="lastName"
          error={errors.lastName}
          rules={{ required: true }}
          isRequiredSymbolActive={true}
          register={register}
          autoComplete="off"
          placeholder="Soyisim"
        />
        <AppInput
          label="Meslek"
          name="title"
          error={errors.title}
          isRequiredSymbolActive={true}
          rules={{ required: true }}
          register={register}
          autoComplete="off"
          placeholder="Barmen,garson,resepsiyonist,müdür..."
        />
        <AppSwitch
          control={control}
          name="isActive"
          label="Aktif çalışan mı ?"
        />

        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          Ekle
        </AppButton>
      </form>
    </AppModal>
  );
};

export default AddWorkerModal;
