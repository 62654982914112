import React from 'react';
import { Outlet } from 'react-router-dom';
const img = require('../assets/images/repidas.png');

const AuthLayout = () => {
  return (
    <div className="lineBackground flex-col pt-6">
      <img src={img} className="xl:mb-6 mb-6 w-48 xl:w-80 h-full" />
      <Outlet />
    </div>
  );
};

export default AuthLayout;
