import React, { useEffect, useState } from 'react';
import {
  AppButton,
  AppModal,
  AppText,
  SurveyTemplateDetailModal
} from 'components';
import api from 'services/api';

const SurveyTemplatesModal = ({ store, isOpen, onClose }) => {
  const [allSurveys, setAllSurveys] = useState([]);
  const [isSurveyTemplateDetailModalOpen, setIsSurveyTemplateDetailModalOpen] =
    useState(false);
  const [detailSurveyParam, setDetailSurveyParam] = useState();

  useEffect(() => {
    const getTemplates = async () => {
      const response = await api.store.getAllSurveyTemplates();
      setAllSurveys(response.data.allSurveyTemplates);
    };
    getTemplates();
  }, []);

  return (
    <AppModal
      open={isOpen}
      title="Örnek Anketler"
      handleClose={() => onClose(false)}
    >
      {allSurveys.map((survey, index) => (
        <div
          key={survey._id}
          className={`py-5 px-3 border  justify-between border-dashed rounded-md border-slate-300 flex flex-row items-center ${
            index > 0 && 'mt-4'
          }`}
        >
          <AppText className="text-gray-800 " text={survey.surveyName} />

          <div className="flex flex-row">
            <AppButton
              onClick={() => {
                setDetailSurveyParam(survey);
                setIsSurveyTemplateDetailModalOpen(true);
              }}
              className="ml-3 !px-3 text-xs"
              isFullWidth={false}
            >
              Anket Detayı
            </AppButton>
          </div>
        </div>
      ))}
      {isSurveyTemplateDetailModalOpen && (
        <SurveyTemplateDetailModal
          store={store}
          survey={detailSurveyParam}
          onClose={() => setIsSurveyTemplateDetailModalOpen(false)}
          isOpen={isSurveyTemplateDetailModalOpen}
        />
      )}
    </AppModal>
  );
};

export default SurveyTemplatesModal;
