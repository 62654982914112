import {
  AppButton,
  AppInput,
  AppModal,
  AppSelect,
  AppSwitch,
  AppText
} from 'components';
import { questionTypes } from 'constants/survey';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import api from 'services/api';
import { changeQuestionType } from 'utils/survey';

const SurveyTemplateDetailModal = ({ store, survey, onClose, isOpen }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSurveyAdded, setIsSurveyAdded] = useState(false);

  //form values
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue
  } = useForm({
    defaultValues: {
      surveyName: survey.surveyName,
      questions: survey.questions
    }
  });

  useEffect(() => {
    const init = () => {
      setIsLoading(true);

      const isSurveyAddedIndex = store.allSurveys.findIndex(
        item => item._id === survey._id
      );
      if (isSurveyAddedIndex > -1) {
        setIsSurveyAdded(true);
      }
      const defaultQuestions = getValues('questions');
      setValue('questions', changeQuestionType(defaultQuestions));
      setIsLoading(false);
    };
    init();
  }, []);

  const { fields } = useFieldArray({
    control,
    name: 'questions'
  });

  const onSubmit = async submitData => {
    setIsLoading(true);
    for (let i = 0; i < submitData.questions.length; i++) {
      const surveyQuestion = submitData.questions[i];
      surveyQuestion.questionType = surveyQuestion.questionType.value;
    }

    const response = await api.store.addSurvey(store._id, {
      _id: survey._id,
      ...submitData
    });
    if (response) {
      const responseStore = response.data.store;
      const responseAllSurveys = responseStore.allSurveys;
      store.allSurveys = responseAllSurveys;
      setIsLoading(false);
      onClose();
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <AppModal
          open={isOpen}
          title="Örnek Anket Detayı"
          handleClose={onClose}
        >
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <AppInput
              label="Anket İsmi"
              name="surveyName"
              disabled={true}
              error={errors.surveyName}
              rules={{ required: true }}
              register={register}
              placeholder="Anket İsmi"
            />

            <ul>
              {fields.map((item, index) => {
                return (
                  <div key={item.id} className={`flex flex-col`}>
                    <div
                      className={`flex text-sm flex-row items-center justify-between border-slate-300 border-b pb-1 mb-3`}
                    >
                      <span className="text-slate-900 font-medium">
                        {`${index + 1}. Soru`}
                      </span>
                    </div>
                    <li className="flex flex-col w-full">
                      <AppInput
                        label={`Soru`}
                        name={`questions.${index}.question`}
                        error={
                          errors?.questions &&
                          errors?.questions[index]?.question
                        }
                        disabled={true}
                        register={register}
                        rules={{ required: true }}
                        placeholder={`Soru`}
                        autoComplete="off"
                      />
                      <AppSelect
                        options={questionTypes}
                        label={`Soru Modeli`}
                        isDisabled={true}
                        name={`questions.${index}.questionType`}
                        error={
                          errors?.questions &&
                          errors?.questions[index]?.questionType
                        }
                        rules={{ required: true }}
                        control={control}
                        placeholder={`Soru Modeli`}
                      />
                      {getValues(`questions.${index}.questionType`).value ===
                        'multipleChoices' && (
                        <>
                          <div className="w-full flex mb-2 justify-between items-center">
                            <AppText
                              className="block text-sm font-medium text-gray-900"
                              text="Seçenekler"
                            />
                          </div>
                          {getValues(`questions.${index}.multipleChoices`).map(
                            (choice, i) => (
                              <div key={i} className="flex w-full items-center">
                                <AppText
                                  text={`${i + 1}.`}
                                  className="mr-2 text-xs text-gray-900 mb-5"
                                />
                                <AppInput
                                  label={`${i + 1}. Seçenek`}
                                  isLabelActive={false}
                                  disabled={true}
                                  name={`questions.${index}.multipleChoices[${i}].choice`}
                                  error={
                                    errors?.questions &&
                                    errors?.questions[index]?.multipleChoices[i]
                                      ?.choice
                                  }
                                  rules={{ required: true }}
                                  register={register}
                                  placeholder={`Seçenek`}
                                  autoComplete="off"
                                />
                              </div>
                            )
                          )}
                        </>
                      )}
                      <AppSwitch
                        disabled={true}
                        control={control}
                        name={`questions.${index}.isRequired`}
                        label="Cevap Zorunlu mu ?"
                      />
                    </li>
                  </div>
                );
              })}
            </ul>
            <AppButton
              type="submit"
              className={`${
                isSurveyAdded &&
                'opacity-50 hover:!bg-blue-100 !cursor-not-allowed'
              }`}
              disabled={isSurveyAdded}
              isLoading={isLoading}
            >
              {isSurveyAdded ? 'Anketlerime Eklediniz' : 'Anketlerime Ekle'}
            </AppButton>
          </form>
        </AppModal>
      )}
    </>
  );
};

export default SurveyTemplateDetailModal;
