import { Close } from '@mui/icons-material';
import { Modal } from '@mui/material';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { AppButton } from 'components';
import React, { useEffect, useState } from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from 'use-places-autocomplete';

const libraries = ['places'];

const GoogleLocationModal = ({ onClose, isOpen, value, setFormValue }) => {
  const [defaultLocation, setDefaultLocation] = useState(
    value || {
      lat: 41.03947254515902,
      lng: 28.994254832786883
    }
  );
  const [selected, setSelected] = useState(value || null);

  const { isLoaded } = useLoadScript({
    googleMapsClientId: process.env.REACT_APP_GOOGLE_MAPS_ID,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
    language: 'TR',
    region: 'TR'
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => {
      const coords = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      setDefaultLocation(coords);
    });
  }, []);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        className="justify-center min-h-screen items-center flex "
      >
        <div className="xl:w-1/2 w-full relative bg-white">
          <GoogleMap
            zoom={15}
            options={{
              fullscreenControl: false,
              mapTypeControl: false,
              clickableIcons: false,
              disableDefaultUI: true
            }}
            center={selected || defaultLocation}
            onClick={item => {
              const coords = { lat: item.latLng.lat(), lng: item.latLng.lng() };
              setSelected(coords);
            }}
            mapContainerStyle={{
              width: '100%',
              minHeight: '100vh',
              position: 'relative'
            }}
          >
            <div className="absolute w-full p-4 bg-white flex justify-between items-center">
              <div>
                <span className="text-gray-900 font-semibold text-lg">
                  Konum Seç
                </span>
                <div className="text-gray-500 font-medium text-xs mt-1">
                  Bir adres girin veya haritada konumunuzu tıklayarak seçin.
                </div>
              </div>
              <Close
                fontSize="small"
                className="cursor-pointer"
                onClick={onClose}
              />
            </div>
            {selected && (
              <>
                <Marker position={selected} />
                <div className="w-full flex justify-center absolute bottom-12">
                  <AppButton
                    onClick={() => {
                      setFormValue(selected);
                      onClose();
                    }}
                    className="!py-4 !w-[95%]  !bg-yellow-400 !text-gray-950 focus:!ring-yellow-500 hover:!bg-yellow-300"
                  >
                    Kaydet
                  </AppButton>
                </div>
              </>
            )}
          </GoogleMap>
          <PlacesAutocomplete setSelected={setSelected} />
        </div>
      </Modal>
    </>
  );
};

const PlacesAutocomplete = ({ setSelected }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions
  } = usePlacesAutocomplete();

  const handleSelect = async address => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);

    setSelected({ lat, lng });
  };

  return (
    <div className="absolute top-[98px] flex flex-col items-center w-full">
      <input
        value={value}
        className={`w-1/3 outline-none border font-medium rounded-lg  bg-gray-100 text-gray-900 border-gray-300 text-sm  block p-2 ${
          data.length > 0 && 'border-b-0 rounded-b-none'
        }`}
        onChange={e => setValue(e.target.value)}
        disabled={!ready}
        placeholder="Adres gir"
      />
      <div
        className={`w-1/3 rounded-lg border ${
          data.length <= 0 ? 'border-0' : 'rounded-t-none'
        }`}
      >
        {status === 'OK' &&
          data.map(({ place_id, description }) => (
            <div
              className="bg-gray-100 text-gray-900 text-xs font-medium p-2 cursor-pointer hover:bg-blue-100 border-b"
              onClick={() => handleSelect(description)}
              key={place_id}
            >
              {description}
            </div>
          ))}
      </div>
    </div>
  );
};
export default GoogleLocationModal;
