import React from 'react';
import { appMenuItems } from 'constants/appMenu';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import {
  MeetingRoomOutlined,
  SettingsOutlined,
  Verified
} from '@mui/icons-material';
import { useAuth } from 'context/AuthContext';
const logo = require('assets/images/repidas.png');

const AppSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const getUrl = searchParams.get('name')
    ? `${location.pathname}?name=${searchParams.get('name')}`
    : location.pathname;
  const { user, setUser } = useAuth();

  const activeMenu = (path, children) => {
    if (getUrl === path) {
      return true;
    } else if (children) {
      const menuItem = children.find(item => item.path === getUrl);
      return menuItem?.path === getUrl;
    }
    return false;
  };

  const signOut = () => {
    localStorage.removeItem('user');
    setUser(null);
    navigate('/auth/login', { replace: true });
  };

  return (
    <div className="w-full flex xl:flex-col flex-row xl:justify-between xl:h-[90vh] xl:rounded-xl xl:bg-[#F1F1F4] xl:p-6 py-4 xl:shadow-xl ">
      <span className="font-medium flex items-center justify-between w-full flex-row text-xl text-neutral-800 text-center">
        <img src={logo} className="h-7" />
        <span className="xl:hidden focus:text-red-500" onClick={signOut}>
          <MeetingRoomOutlined fontSize="small" />
        </span>
      </span>
      <nav className="xl:flex hidden flex-col w-full">
        {appMenuItems.map((item, index) => (
          <Link
            to={item.path}
            key={index}
            className={`flex items-center hover:font-medium hover:bg-gray-200 hover:text-blue-500 px-6 py-4 rounded-full mt-2 transition-all ${
              activeMenu(item.path, item?.children)
                ? 'text-blue-500 font-medium'
                : 'text-gray-600'
            }`}
          >
            <item.Icon fontSize="small" className={`${item.icon} mr-3 `} />
            <span>{item.name}</span>
          </Link>
        ))}
      </nav>

      <div className="xl:flex hidden flex-row justify-between w-full items-center text-sm font-semibold relative">
        <span className="flex flex-row items-center">
          {user?.fullName}
          {user?.isVerified && <Verified className="ml-2 text-blue-500" />}
        </span>
        <div className="group cursor-pointer">
          <div
            className={
              'absolute invisible bottom-11 w-full left-0 ml-auto mr-auto bg-white rounded-xl group-hover:visible ease-in transition-all shadow-md'
            }
          >
            <div className="flex flex-row w-full items-center text-sm px-4 py-6 font-semibold pb-4 border-b">
              <span className="flex flex-col text-gray-900 ">
                <span>
                  {user?.fullName}
                  {user?.isVerified && (
                    <Verified className="ml-2 text-blue-500" />
                  )}
                </span>
                <span className="text-xs text-gray-500 font-light mt-px">
                  {user?.email}
                </span>
              </span>
            </div>

            <div className="px-4 py-2">
              <a
                onClick={signOut}
                className={`cursor-pointer flex items-center text-sm hover:bg-stone-100 hover:text-red-500 px-3 py-2 rounded-md`}
              >
                Çıkış Yap
              </a>
            </div>
          </div>
          <SettingsOutlined />
        </div>
      </div>

      <nav className="xl:hidden z-50 border-t fixed bottom-0 left-0 w-full justify-between flex flex-row  text-zinc-500 bg-[#FAFAFA] p-4">
        {appMenuItems.map((item, index) => (
          <Link
            to={item.path}
            key={index}
            className={`flex flex-col items-center p-2 rounded-md ${
              activeMenu(item.path, item?.children)
                ? 'bg-[#F5F5F5] text-border-800 border font-medium'
                : 'bg-transparent'
            }`}
          >
            <item.Icon fontSize="small" />
            <span className="text-xs mt-1">{item.name}</span>
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default AppSidebar;
