import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Info, LocationOn } from '@mui/icons-material';
import GoogleLocationModal from 'components/Modals/GoogleLocationModal/GoogleLocationModal.component';

const AppLocationSelect = ({
  name,
  control,
  error,
  register,
  rules,
  ...props
}) => {
  const [isGoogleModalOpen, setIsGoogleModalOpen] = useState(false);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        const setValue = changedValue => {
          onChange(changedValue);
        };

        return (
          <div className="mb-5">
            <div className="flex  w-full flex-col text-sm font-medium text-gray-900">
              <label
                htmlFor={name}
                className={`w-full  block mb-2 text-sm font-medium ${
                  error ? 'text-red-600' : 'text-gray-900'
                }`}
              >
                İşletme Konumu
                <span className="align-top text-red-600 font-bold ml-1">*</span>
              </label>
              <div
                onClick={() => setIsGoogleModalOpen(true)}
                className={`flex  items-center  w-full overflow-hidden cursor-pointer outline-none  border text-sm rounded-lg  p-2.5  ${
                  error
                    ? 'border-red-500 focus:border-red-500'
                    : 'border-gray-200 focus:border-gray-700'
                } ${value ? 'text-gray-900' : 'text-gray-400'}`}
                {...register(name, rules)}
                {...props}
              >
                <LocationOn fontSize="small" className="mr-1 text-amber-500" />
                {value ? value?.lat + ',' + value?.lng : 'Bir konum belirleyin'}
              </div>
            </div>

            <span className="text-gray-500 font-medium text-xs mt-2 flex items-center">
              <Info fontSize="small" className="mr-1 text-sm" />
              Konumunuzun doğruluğu kullanıcıların ankete katılabilmesi ve
              oylama yapabilmesi için önemlidir.Konumunuzun doğru olduğundan
              emin olun!
            </span>
            {isGoogleModalOpen && (
              <GoogleLocationModal
                setFormValue={setValue}
                value={value}
                onClose={() => setIsGoogleModalOpen(false)}
                isOpen={isGoogleModalOpen}
              />
            )}
          </div>
        );
      }}
    />
  );
};

export default AppLocationSelect;
