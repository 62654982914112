import { AppButton, ScreenLoading } from 'components';
import { useAuth } from 'context/AuthContext';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { calculateQrStatistics } from 'utils/statistics';

const Home = () => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const statisticsValues = [
    {
      label: 'Toplam Anket Cevabı',
      property: 'surveyAnswersCount',
      bgColor: 'bg-amber-500',
      textColor: 'text-amber-500'
    },
    {
      label: 'Toplam Çalışan Oylama',
      property: 'workersAnswersCount',
      bgColor: 'bg-emerald-500',
      textColor: 'text-emerald-500'
    },
    {
      label: 'Toplam Qr Okutma',
      property: 'totalQrScan',
      bgColor: 'bg-sky-500',
      textColor: 'text-sky-500'
    },
    {
      label: 'Aylık Qr Okutma (Son 30 Gün)',
      property: 'monthlyQrScan',
      bgColor: 'bg-sky-500',
      textColor: 'text-sky-500'
    },
    {
      label: 'Haftalık Qr Okutma (Son 7 Gün)',
      property: 'weeklyQrScan',
      bgColor: 'bg-sky-500',
      textColor: 'text-sky-500'
    },
    {
      label: 'Günlük Qr Okutma (Son 24 Saat)',
      property: 'dailyQrScan',
      bgColor: 'bg-sky-500',
      textColor: 'text-sky-500'
    }
  ];

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      await api.store.myStores().then(async res => {
        const resStores = res.data.store;

        for (let i = 0; i < resStores.length; i++) {
          let store = resStores[i];
          const statisticsResponse = await api.global.get(
            'statistics',
            `qrScanned/${store._id}`
          );
          const statistics = calculateQrStatistics(
            statisticsResponse.data.statistics
          );
          store.totalQrScan = statistics.total;
          store.monthlyQrScan = statistics.monthly;
          store.weeklyQrScan = statistics.weekly;
          store.dailyQrScan = statistics.daily;
        }
        setData(res.data.store);
      });

      setIsLoading(false);
    };
    getData();
    return () => {
      setIsLoading(true);
    };
  }, []);

  return (
    <div>
      {isLoading ? (
        <ScreenLoading />
      ) : (
        <>
          <h2 className="text-lg font-semibold text-gray-700 ">
            İşletme İstatistikleri
          </h2>
          <div className="mt-4 grid grid-cols-1 xl:grid-cols-3 gap-8">
            {data.length > 0 ? (
              data.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col flex-shrink border border-gray-200 rounded-md shadow-md bg-white py-6 px-8"
                >
                  <div className="flex flex-row justify-between items-center pb-2 border-b border-gray-100">
                    <span className="text-xl font-medium text-gray-800 tracking-tight">
                      {item.storeName}
                    </span>
                    {item.logo && (
                      <img
                        src={item.logo}
                        className="h-8 w-auto object-contain"
                      />
                    )}
                  </div>

                  {statisticsValues.map((value, i) => (
                    <div
                      key={i}
                      className="flex flex-row justify-between mt-4 border-b border-gray-100 pb-1"
                    >
                      <span className="flex flex-row items-center">
                        <span
                          className={`w-1 h-1 rounded-full ${value.bgColor}`}
                        />
                        <span className="text-gray-900 font-medium text-sm ml-2">
                          {value.label}
                        </span>
                      </span>
                      <span
                        className={`${value.textColor} tracking-tight font-medium text-sm`}
                      >
                        {item[value.property]}
                      </span>
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <div className="flex text-gray-500 text-center justify-center items-center text-sm flex-col flex-shrink border border-gray-200 rounded-md shadow-md bg-white py-6 px-8">
                İstatistik için bir işletme ekleyin.
                <AppButton
                  onClick={() =>
                    navigate('/store', { state: { isAddModalOpen: true } })
                  }
                  isFullWidth={false}
                  className="shadow-lg !text-white hover:!bg-opacity-90 hover:!bg-[#FFC907]  focus:!ring-[#FFC907]  !bg-[#FFC907] mt-2"
                >
                  Hemen bir işletme ekle!
                </AppButton>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
