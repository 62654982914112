export default {
  _formErrorMessages: {
    required: '{{label}} alanı zorunludur.',
    minLength: '{{label}} alanını eksiksiz girmelisin.',
    pattern: '{{label}} alanına lütfen geçerli bir değer giriniz.',
    wrongData: '{{label}} alanını yanlış girdiniz.',
    passwordNotEqual: 'Şifreler eşleşmiyor.',
    emailExists:
      'Bu e-posta adresine sahip bir hesap zaten mevcut. Lütfen farklı bir mail adresi deneyin.',
    notExists: 'Bu bilgilere ait bir hesap bulunamadı.'
  },
  _auth: {
    login: 'Giriş Yap',
    register: 'Kayıt Ol',
    loginSubtitle: 'Hesabınıza güvenli bir şekilde giriş yapabilirsiniz.',
    forgotPassword: 'Şifremi Unuttum ?',
    resetPassword: 'Şifremi Sıfırla',
    logout: 'Çıkış Yap',
    email: 'E-Posta',
    password: 'Şifre',
    enterEmail: 'E-Posta Adresinizi Giriniz',
    enterPassword: 'Şifrenizi Giriniz',
    loginDescription:
      'Lütfen aşağıdaki butona tıklayarak Google ile giriş yapınız. Bu işlem yalnızca onaylanmış kullanıcılar için geçerlidir.',
    loginWithGoogle: 'Google ile Giriş Yap',
    loginAccessDenied:
      'Giriş yapmak için yetkiniz yok. Lütfen geçerli e-posta adresinizle giriş yapınız.'
  },
  _general: {
    save: 'Kaydet',
    close: 'Kapat',
    continue: 'Devam Et',
    delete: 'Sil',
    photo: 'Fotoğraf'
  },
  _alerts: {
    successTitle: 'Başarılı!',
    successSubtitle: 'Değişikler başarıyla kaydedildi.',
    errorTitle: 'Başarısız!',
    errorSubtitle: 'Değişikler malesef kaydedilemedi.'
  },
  _customer: {
    firstName: 'Ad',
    lastName: 'Soyad',
    email: 'E-posta',
    password: 'Şifre',
    isRegister: 'Kayıt Durumu',
    packageType: 'Paket Türü',
    createdAt: 'Kayıt Tarihi',
    updatedAt: 'Son Güncelleme Tarihi'
  },
  _store: {
    surveyAnswersCount: 'Toplam Anket Cevap Sayısı',
    workersAnswersCount: 'Toplam Çalışan Oylama Sayısı',
    createdAt: 'İşletme Kayıt Tarihi',
    customerFullName: 'Müşteri Adı',
    storeName: 'İşletme Adı',
    updatedAt: 'Son Güncelleme Tarihi'
  },
  _survey: {
    question: 'Soru',
    answer: 'Cevap'
  },
  _timeAgoFunction: {
    second: 'birkaç saniye önce',
    seconds: '{{label}} saniye önce',
    minute: '{{label}} dakika önce',
    minutes: '{{label}} dakika önce',
    hour: '{{label}} saat önce',
    hours: '{{label}} saat önce',
    day: '{{label}} gün önce',
    days: '{{label}} gün önce',
    week: '{{label}} hafta önce',
    weeks: '{{label}} hafta önce',
    month: '{{label}} ay önce',
    months: '{{label}} ay önce',
    year: '{{label}} yıl önce',
    years: '{{label}} yıl önce'
  },
  _storeStatisticTitles: {
    totalSurveyAnswers: 'Toplam Anket Cevabı',
    totalWorkerAnswers: 'Toplam Çalışan Cevabı',
    totalQrScanned: 'Toplam Qr Okutma'
  }
};
