import React from 'react';
import { Switch } from '@mui/material';
import { Controller } from 'react-hook-form';

const AppSwitch = ({
  label,
  name,
  control,
  isLabelActive = true,
  disabled = false
}) => {
  return (
    <div
      className={`${
        isLabelActive &&
        'w-full mb-5 flex justify-between items-center  relative bg-white  p-2.5 border rounded-md'
      }`}
    >
      {isLabelActive && (
        <label
          htmlFor={name}
          className="w-full block text-sm font-medium text-gray-900"
        >
          {label}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <Switch
            size="small"
            disabled={disabled}
            onChange={onChange}
            checked={value}
            value={value}
            id={name}
          />
        )}
      />
    </div>
  );
};

export default AppSwitch;
