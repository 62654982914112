import api from 'services/api';
import { asterisks } from './asteriks';
import { levels } from 'constants/levels';

export const listAllMenuProducts = menu => {
  const list = [];

  for (let i = 0; i < menu.length; i++) {
    const category = menu[i];

    for (let index = 0; index < category.categoryProductList.length; index++) {
      const product = category.categoryProductList[index];

      const productForList = {
        label: product?.tr?.name,
        value: product?._id
      };

      list.push(productForList);
    }
  }

  return list;
};

export const myStoresAsOption = stores => {
  const storeList = [];
  for (let i = 0; i < stores.length; i++) {
    const store = stores[i];

    storeList.push({ label: store?.storeName, value: store?._id });
  }

  return storeList;
};

export const setSubmitDataForCampaign = submitData => {
  if (submitData.price.priceType.value === 'discount') {
    submitData.price = {
      priceType: submitData.price.priceType.value,
      discount: submitData.price.discount
    };
    if (!submitData.price?.discount.includes(',')) {
      submitData.price.discount = `${submitData.price?.discount},00`;
    } else {
      const decimalSeparatorIndex = submitData.price?.discount.indexOf(',');
      const decimals = submitData.price?.discount.slice(
        decimalSeparatorIndex + 1,
        submitData.price?.discount.length
      );
      if (decimals.length === 1) {
        submitData.price.discount = `${submitData.price?.discount}0`;
      }
    }
  } else {
    submitData.price = {
      priceType: submitData.price.priceType.value,
      productId: submitData.price.product.value,
      productStoreId: submitData.price.productStoreId.value,
      productCount: Number(submitData.price.productCount)
    };
  }

  const mailAdresses = [];

  for (let i = 0; i < submitData.enabledMailAdresses.length; i++) {
    const mail = submitData.enabledMailAdresses[i].address;
    mailAdresses.push(mail);
  }
  submitData.enabledMailAdresses = mailAdresses;

  const storeIds = [];

  for (let i = 0; i < submitData.enabledStores.length; i++) {
    const store = submitData.enabledStores[i];

    if (store?.isEnableForCampaign) {
      const id = store._id;
      storeIds.push(id);
    }
  }
  submitData.requiredSurveyCount = Number(submitData.requiredSurveyCount);
  submitData.enabledStores = storeIds;

  return submitData;
};

export const getAttendanceTableData = async (data, stores) => {
  for (let index = 0; index < data.length; index++) {
    const attendance = data[index];

    try {
      const response = await api.global.get('customer/user', attendance.userId);

      const resUser = response.data.user;

      attendance.userFirstName =
        resUser.firstName[0] + asterisks(resUser.firstName);
      attendance.userLevel = levels(resUser).find(
        level => level.selected
      ).title;

      const attendanceStore = stores.find(
        item => item._id === attendance.storeId
      );
      attendance.storeName = attendanceStore.storeName;

      attendance.surveyName = attendance.survey.name;
      (attendance.created = attendance.createdAt),
        (attendance.isUsed = attendance.isActive);
    } catch (error) {
      console.log(error, 'user not found error');
    }
  }

  return data;
};

export const getAttendanceStats = (
  data,
  campaign,
  stores,
  rewardedAttendances
) => {
  const stats = [
    {
      allAttendanceCount: data.length || 0,
      isActiveCount: 0,
      finishedCount: 0,
      userCount: 0,
      rewardCount: rewardedAttendances.length,
      storeName: 'Tüm İstatistikler'
    }
  ];
  const users = [];

  for (let i = 0; i < campaign.enabledStores.length; i++) {
    const storeId = campaign.enabledStores[i];
    const store = stores.find(item => item._id.toString() === storeId);

    const storeRewardedCount = rewardedAttendances.filter(
      item => item.storeId === storeId
    );

    const statsObj = {
      storeId,
      storeName: store.storeName,
      logo: store.logo,
      allAttendanceCount: 0,
      isActiveCount: 0,
      finishedCount: 0,
      rewardCount: storeRewardedCount.length
    };

    stats.push(statsObj);
  }

  for (let index = 0; index < data.length; index++) {
    const attendance = data[index];
    const statIndex = stats.findIndex(
      item => item.storeId === attendance.storeId
    );

    const userIndex = users.findIndex(item => item === attendance.userId);

    if (statIndex > -1) {
      stats[statIndex].allAttendanceCount++;

      if (userIndex === -1) {
        users.push(attendance.userId);
      }
      if (attendance.isActive) {
        stats[0].isActiveCount++;
        stats[statIndex].isActiveCount++;
      } else {
        stats[0].finishedCount++;
        stats[statIndex].finishedCount++;
      }
    }
  }

  stats[0].userCount = users.length;

  return stats;
};
